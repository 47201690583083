import { SubscriptionAction, SubscriptionActionType } from './actions';
import {
    PaymentMethodResponse,
    RedirectAfterPayment,
    ShopTypeName,
    SubscriptionPlanResponse,
    SubscriptionResponse,
} from 'types';
import { getLocalStorageItem, LocalStorageKey } from '../../core/storage/storage';

export enum SubscriptionPaymentStatus {
    NONE = 'NONE',
    FORM = 'FORM',
    PENDING = 'PENDING',
    FAILURE = 'FAILURE',
}

export enum PaymentIntentType {
    CHANGE = 'change',
    RESUME_ONLINE = 'resume_online',
    RESUME_PRIORITY = 'resume_priority',
}

export interface PaymentIntent {
    intentType: PaymentIntentType;
    subscriptionPlanId: string;
}

export interface SubscriptionState {
    currentSubscription: SubscriptionResponse;
    paymentMethod: PaymentMethodResponse;
    subscriptionPlans: SubscriptionPlanResponse[];
    paymentStatus: SubscriptionPaymentStatus;
    paymentIntent: PaymentIntent;
    closedBanner: boolean;
    rateDialogType: ShopTypeName;
}

export interface SubscriptionSlice {
    subscription: SubscriptionState;
}
const isServer = typeof window === 'undefined';

const getPaymentStatus = () => {
    if (!isServer) {
        const urlParams = new URLSearchParams(window.location.search);
        const after = urlParams.get('after');
        return after === RedirectAfterPayment.PAYMENT_METHOD_CHANGED ||
            after === RedirectAfterPayment.SUBSCRIPTION_RESUMED_ONLINE_SHOP ||
            after === RedirectAfterPayment.SUBSCRIPTION_RESUMED_PRIORITY
            ? SubscriptionPaymentStatus.PENDING
            : SubscriptionPaymentStatus.NONE;
    }
    return null;
};

const getPaymentIntent = () => {
    let paymentIntent: PaymentIntent = {
        intentType: null,
        subscriptionPlanId: null,
    };
    if (!isServer) {
        const urlParams = new URLSearchParams(window.location.search);
        const after = urlParams.get('after');
        paymentIntent = {
            intentType: null,
            subscriptionPlanId: getLocalStorageItem<PaymentIntent>(LocalStorageKey.PAYMENT_INTENT)?.subscriptionPlanId,
        };

        switch (after) {
            case RedirectAfterPayment.PAYMENT_METHOD_CHANGED:
                paymentIntent.intentType = PaymentIntentType.CHANGE;
                break;
            case RedirectAfterPayment.SUBSCRIPTION_RESUMED_ONLINE_SHOP:
                paymentIntent.intentType = PaymentIntentType.RESUME_ONLINE;
                break;
            case RedirectAfterPayment.SUBSCRIPTION_RESUMED_PRIORITY:
                paymentIntent.intentType = PaymentIntentType.RESUME_PRIORITY;
                break;
            default:
                paymentIntent = getLocalStorageItem<PaymentIntent>(LocalStorageKey.PAYMENT_INTENT);
        }
    }
    return paymentIntent;
};

export function getSubscriptionStateFromLS() {
    return {
        paymentStatus: getPaymentStatus(),
        paymentIntent: getPaymentIntent(),
    };
}

const subscriptionInitialState: SubscriptionState = {
    currentSubscription: null,
    paymentMethod: null,
    subscriptionPlans: null,
    paymentStatus: getPaymentStatus(),
    paymentIntent: getPaymentIntent(),
    closedBanner: false,
    rateDialogType: null,
};

export const subscriptionReducer = (
    state = subscriptionInitialState,
    action: SubscriptionAction,
): SubscriptionState => {
    switch (action.type) {
        case SubscriptionActionType.FETCH_SUCCESS:
            return {
                ...state,
                currentSubscription: action.payload.subscription,
                paymentMethod: action.payload.paymentMethod,
                subscriptionPlans: action.payload.subscriptionPlans,
            };
        case SubscriptionActionType.CLEAR:
            return {
                ...state,
                currentSubscription: null,
                paymentMethod: null,
            };
        case SubscriptionActionType.CHANGE_PAYMENT_STATUS:
            return {
                ...state,
                paymentStatus: action.payload.paymentStatus,
            };
        case SubscriptionActionType.SET_INTENT:
            return {
                ...state,
                paymentIntent: {
                    intentType: action.payload.intentType,
                    subscriptionPlanId: action.payload.subscriptionPlanId,
                },
            };
        case SubscriptionActionType.CLOSE_BANNER:
            return {
                ...state,
                closedBanner: true,
            };
        case SubscriptionActionType.CHANGE_RATE_DIALOG:
            return {
                ...state,
                rateDialogType: action.payload.rate,
            };
        default:
            return state;
    }
};
