import { captureException } from '@sentry/nextjs';
import { SagaIterator } from 'redux-saga';
import { call, cancel, delay, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { actionTypes, change } from 'redux-form';

import { getLocalStorageItem, LocalStorageKey, setLocalStorageItem } from 'core/storage/storage';
import { redirectSaga } from 'core/sagas/sagas';

import {
    AuthActionType,
    AuthChangeOpenForOrdersAction,
    AuthLoginAsDifferentUserAction,
    AuthLoginRequestAction,
    AuthResetPasswordPasswordSubmitAction,
    AuthResetPasswordPhoneSubmitAction,
    AuthUpdateStoreIdAction,
    makeAuthLoginFailureAction,
    makeAuthLoginSuccessAction,
    makeResetPasswordUpdateStageAction,
    makeResetPasswordUpdateTimeLeftAction,
    makeSetMarketingDocsAction,
} from './actions';
import { initUserDataFromBackendSaga, uiBlockingSagaWrapper } from '../app/sagas';
import {
    formGetRequestSaga,
    formPostRequestSaga,
    formPutRequestSaga,
    getRequestSaga,
    postRequestSaga,
    putRequestSaga,
} from '../rest/sagas';
import { ResetPasswordStage } from './reducer';
import { authResetPasswordTimeLeftSelector, authRoleSelector, authStoreIdSelector } from './selectors';

import { Endpoint } from 'Endpoint';
import { RoutePath } from 'RoutePath';

import { UserRole } from 'types';
import { Form } from 'forms/types';
import { checkoutCartSelector } from 'state/checkout/selectors';
import { setJwt, setRole } from 'core/auth/auth';
import { makeSnackbarErrorAction } from '../snackbar/actions';

export function* authSaga(): SagaIterator {
    yield takeEvery(AuthActionType.LOGIN_REQUEST, uiBlockingSagaWrapper, loginRequestSaga);
    yield takeEvery(AuthActionType.RESET_PASSWORD_PHONE_SUBMIT, resetPasswordPhoneSubmitSaga);
    // yield takeEvery(AuthActionType.GET_MARKETING_DOCS, getMarketingDocs);  // На данный момент не используется, заменён на ссылку на архив в компоненте
    yield takeEvery(AuthActionType.LOGIN_AS_DIFFERENT_USER_REQUEST, loginAsDifferentUserSaga);
    yield takeEvery(AuthActionType.CHANGE_OPEN_FOR_ORDERS, changeOpenForOrdersSaga);
    yield takeEvery(AuthActionType.UPDATE_STORE_ID, updateStoreId);
}

export function* loginSaga(username: string, password: string, role: UserRole): SagaIterator {
    try {
        const body = { username, password };
        const headers = { 'X-Login-As': role };
        const response: AxiosResponse = yield call(
            formPostRequestSaga,
            Form.LOGIN_FORM,
            Endpoint.LOGIN,
            {},
            body,
            headers,
        );
        yield call(setUserData, response.headers.authorization, username, role);

        const cartId = getLocalStorageItem(LocalStorageKey.CART_ID);
        if (cartId) {
            const cart = yield select(checkoutCartSelector);
            yield call(putRequestSaga, Endpoint.CART_BY_ID, { cartId: cart.cartId }, cart);
        }
    } catch (e) {
        captureException(e);
        yield put(makeAuthLoginFailureAction());
    }
}

const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

function* getMarketingDoc(storeId: string, url: Endpoint): SagaIterator {
    const { data, headers } = yield call(getRequestSaga, url, { storeId });

    const name = filenameRegex.exec(headers['content-disposition'])[1];
    yield put(makeSetMarketingDocsAction([{ id: url, url: `data:application/pdf;base64,${data}`, name }]));
}

export function* getMarketingDocs(): SagaIterator {
    try {
        const storeId = yield select(authStoreIdSelector);

        yield call(getMarketingDoc, storeId, Endpoint.VENDOR_GET_MARKETING_DOCS);
        yield call(getMarketingDoc, storeId, Endpoint.VENDOR_GET_MARKETING_DOCS_2);
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('auth:errors.error'));
    }
}

export function* setUserData(jwt: string, username: string, role: UserRole): SagaIterator {
    setJwt(jwt);
    setRole(role);
    setLocalStorageItem(LocalStorageKey.PHONE, username);
    yield call(initUserDataFromBackendSaga, role);
    yield put(makeAuthLoginSuccessAction(username, role));
}

function* loginRequestSaga(action: AuthLoginRequestAction): SagaIterator {
    const { username, password, role } = action.payload;
    yield call(loginSaga, username, password, role);
}

function* resetPasswordFillOtpSaga(): SagaIterator {
    while (true) {
        const otpChangeAction = yield take(actionTypes.CHANGE);
        const { meta, payload } = otpChangeAction;
        if (meta.form === Form.PASSWORD_RESET_CODE_FORM && meta.field === 'otp') {
            if (payload.replace(/\s+/g, '').length === 6) {
                return payload;
            }
        }
    }
}

function* validateOtpSaga(phone: string): SagaIterator {
    let otp: string;
    while (true) {
        otp = yield call(resetPasswordFillOtpSaga);
        try {
            yield call(formGetRequestSaga, Form.PASSWORD_RESET_CODE_FORM, Endpoint.CHECK_RESET_PASSWORD_OTP, {
                otp,
                phone: phone.replace('+', '%2B'),
            });
            break;
        } catch (e) {
            captureException(e);
            yield put(change(Form.PASSWORD_RESET_CODE_FORM, 'otp', ''));
        }
    }
    return otp;
}

function* resetPasswordPhoneSubmitSaga(action: AuthResetPasswordPhoneSubmitAction): SagaIterator {
    const { username } = action.payload;
    try {
        yield call(formPostRequestSaga, Form.PASSWORD_RESET_PHONE_FORM, Endpoint.RESET_PASSWORD_OTP, {}, { username });
    } catch (e) {
        captureException(e);
        return;
    }
    yield put(makeResetPasswordUpdateStageAction(ResetPasswordStage.CODE));
    while (true) {
        const updateTimeLeftTask = yield fork(startTimerSaga, username);
        const otp = yield call(validateOtpSaga, username);
        yield put(makeResetPasswordUpdateStageAction(ResetPasswordStage.PASSWORD));
        yield cancel(updateTimeLeftTask);
        const passwordSubmitAction: AuthResetPasswordPasswordSubmitAction = yield take(
            AuthActionType.RESET_PASSWORD_PASSWORD_SUBMIT,
        );
        const { password } = passwordSubmitAction.payload;
        try {
            yield call(
                formPutRequestSaga,
                Form.PASSWORD_RESET_CODE_FORM,
                Endpoint.RESET_PASSWORD,
                {},
                { username, otp, password },
            );
            const role = yield select(authRoleSelector);
            if (role) {
                yield call(loginSaga, username, password, role);
                window.location.assign(RoutePath.FEED);
            } else {
                yield call(redirectSaga, RoutePath.LOGIN);
            }
            break;
        } catch (e) {
            captureException(e);
            yield put(makeResetPasswordUpdateStageAction(ResetPasswordStage.CODE));
        }
    }
}

function* startTimerSaga(username: string): SagaIterator {
    while (true) {
        let timeLeft: number = yield select(authResetPasswordTimeLeftSelector);
        while (true) {
            yield delay(1e3);
            if (timeLeft <= 1) {
                yield put(makeResetPasswordUpdateTimeLeftAction(0));
                break;
            } else {
                yield put(makeResetPasswordUpdateTimeLeftAction(--timeLeft));
            }
        }
        yield take(AuthActionType.RESET_PASSWORD_RESEND_CODE);
        yield call(formPostRequestSaga, Form.PASSWORD_RESET_CODE_FORM, Endpoint.RESET_PASSWORD_OTP, {}, { username });
        yield put(makeResetPasswordUpdateStageAction(ResetPasswordStage.CODE));
    }
}

function* loginAsDifferentUserSaga(action: AuthLoginAsDifferentUserAction): SagaIterator {
    try {
        const response = yield call(
            postRequestSaga,
            Endpoint.LOGIN_AS_DIFFERENT_USER,
            { userId: action.payload.userId },
            {},
        );
        setJwt(response.headers.authorization);
        setRole(UserRole.VENDOR);
        window.location.assign(RoutePath.VENDOR_DASHBOARD);
    } catch (e) {
        yield put(makeSnackbarErrorAction('auth:errors.login'));
    }
}

function* changeOpenForOrdersSaga(action: AuthChangeOpenForOrdersAction): SagaIterator {
    try {
        const storeId = yield select(authStoreIdSelector);
        yield call(
            postRequestSaga,
            Endpoint.OPEN_FOR_ORDERS,
            { storeId },
            {},
            {},
            { isOpen: action.payload.openForOrders },
        );
    } catch (e) {
        yield put(makeSnackbarErrorAction('auth:errors.error'));
    }
}

function* updateStoreId(action: AuthUpdateStoreIdAction): SagaIterator {
    const storeId = action.payload.storeId;
    setLocalStorageItem(LocalStorageKey.STORE_ID, storeId);
}
