import { captureException } from '@sentry/nextjs';
import { makeAppUIDisableAction, makeAppUIEnableAction } from '../app/actions';
import { AuthStatus } from '../auth/reducer';
import { setUserData } from '../auth/sagas';
import { authPhoneNumberSelector, authStatusSelector } from '../auth/selectors';
import {
    feedAddressChangedSelector,
    feedAddressCommentSelector,
    feedAddressSelector,
    feedCoordinatesSelector,
    feedStandardAddressDataSelector,
} from '../feed/selectors';
import { deleteRequestSaga, getRequestSaga, postRequestSaga, putRequestSaga } from '../rest/sagas';
import { storeSelector } from '../store/selectors';
import {
    CheckoutActionType,
    CheckoutAddToCartAction,
    CheckoutChangeProductQuantityAction,
    CheckoutChangePromoAction,
    CheckoutChoseDeliveryAction,
    CheckoutChosePaymentAction,
    CheckoutRemoveFromCartAction,
    CheckoutShowSuccessMessageAction,
    CheckoutSilentLoginSubmitAction,
    makeCheckoutChangeCartSuccessAction,
    makeCheckoutChangePaymentStatusAction,
    makeCheckoutClearAction,
    makeCheckoutHideSilentLoginDialogAction,
    makeCheckoutHideSuccessMessageAction,
    makeCheckoutSetStoreInfoAction,
    makeCheckoutShowAddressDialogAction,
    makeCheckoutShowSilentLoginDialogAction,
    makeCheckoutShowSuccessMessageAction,
} from './actions';
import { OnlinePaymentStatus } from './reducer';
import {
    checkoutCartIdSelector,
    checkoutCartPaymentTypeSelector,
    checkoutCartSelector,
    checkoutCartStorePaymentInfoSelector,
    checkoutPaymentStatusSelector,
    checkoutTotalPriceSelector,
} from './selectors';
import { Endpoint } from 'Endpoint';
import { appConfig } from 'config/app';
import { removeQueryParams } from 'core/sagas/sagas';
import {
    getLocalStorageItem,
    LocalStorageKey,
    removeLocalStorageItem,
    setLocalStorageItem,
} from 'core/storage/storage';
import { ReachedGoal, sendReachGoalMetrics } from 'core/ym/ym';
import { SagaIterator } from 'redux-saga';
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import {
    AddressDto,
    CartDto,
    CheckoutStatus,
    DeliveryType,
    PaymentStatus,
    PaymentType,
    StoreDeliveryDataDto,
    StoreDeliveryDto,
    StoreDto,
    StorePaymentDto,
} from 'types';
import { getJwt } from 'core/auth/auth';
import { makeSnackbarErrorAction } from '../snackbar/actions';
import { splitOnce } from '../../core/utils/utils';

export function* checkoutSaga(): SagaIterator {
    yield takeEvery(CheckoutActionType.ADD_TO_CART, checkoutAddToCartSaga);
    yield takeEvery(CheckoutActionType.REMOVE_FROM_CART, checkoutRemoveToCartSaga);
    yield takeEvery(CheckoutActionType.CHANGE_PRODUCT_QUANTITY, changeProductQuantitySaga);
    yield takeEvery(CheckoutActionType.CHOSE_DELIVERY, chooseDeliverySaga);
    yield takeEvery(CheckoutActionType.CHOSE_PAYMENT, choosePaymentSaga);
    yield takeEvery(CheckoutActionType.SUBMIT, checkoutSubmitSaga);
    yield takeEvery(CheckoutActionType.SILENT_LOGIN_SUBMIT, checkoutSilentLoginSubmitSaga);
    yield takeEvery(CheckoutActionType.ADDRESS_SUBMIT_SECOND_STEP, checkoutPurchaseSaga);
    yield takeEvery(CheckoutActionType.CLOSE_CHECKOUT_SECOND_STEP, checkoutCloseSecondStep);
    yield takeEvery(CheckoutActionType.SHOW_SUCCESS_MESSAGE, checkoutSuccessMessage);
    yield takeEvery(CheckoutActionType.UPDATE_CART_INFO, checkoutGetCart);
    yield takeEvery(CheckoutActionType.REMOVE_FULL_CART, checkoutRemoveFullCart);
    yield takeEvery(CheckoutActionType.CHANGE_PROMO, checkoutChangePromoSaga);

    yield call(waitForPaymentSaga);
}

function* checkoutPurchaseSaga(): SagaIterator {
    try {
        sendReachGoalMetrics(ReachedGoal.ORDER_REQUEST);

        const authStatus = yield select(authStatusSelector);
        const phone = yield select(authPhoneNumberSelector);

        //silent login
        if (authStatus === AuthStatus.GUEST || !phone || phone === 'fake') {
            yield put(makeCheckoutShowSilentLoginDialogAction());
        } else {
            yield put(makeAppUIDisableAction());

            const standardAddressData: AddressDto = yield select(feedStandardAddressDataSelector);
            const addressChanged = yield select(feedAddressChangedSelector);
            const cart: CartDto = yield select(checkoutCartSelector);

            if (addressChanged) {
                yield call(
                    postRequestSaga,
                    Endpoint.ADDRESS,
                    {},
                    {
                        ...standardAddressData,
                        defaultAddress: true,
                    },
                );
            }

            yield call(
                putRequestSaga,
                Endpoint.CART_BY_ID,
                { cartId: cart.cartId },
                { ...cart, status: CheckoutStatus.READY_FOR_ORDER },
            );
            const response = yield call(postRequestSaga, Endpoint.ORDERS, {}, {}, {}, { cartId: cart.cartId });
            const { orderId } = response.data;
            setLocalStorageItem(LocalStorageKey.CART_ORDER_ID, orderId);
            if (cart.paymentInfo.paymentType === PaymentType.OLPAY) {
                yield put(makeCheckoutChangePaymentStatusAction(OnlinePaymentStatus.FORM));
            } else {
                yield call(checkoutCleanupSaga);
                yield put(makeCheckoutShowSuccessMessageAction(appConfig.showSuccessOrderMessageTime, orderId));
            }
        }

        sendReachGoalMetrics(ReachedGoal.ORDER_SUCCESS);
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('orders:errors.checkout'));
    } finally {
        yield put(makeAppUIEnableAction());
    }
}

function* checkoutRemoveFullCart(): SagaIterator {
    try {
        const cartId = getLocalStorageItem(LocalStorageKey.CART_ID);
        yield call(deleteRequestSaga, Endpoint.CART_BY_ID, { cartId });
        yield call(checkoutCleanupSaga);
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('orders:errors.checkoutRemove'));
    }
}

function* checkoutCleanupSaga(): SagaIterator {
    removeLocalStorageItem(LocalStorageKey.CART_ORDER_ID);
    removeLocalStorageItem(LocalStorageKey.CART_ID);
    yield put(makeCheckoutClearAction());
}

function* checkoutGetCart() {
    try {
        const jwt = getJwt();
        const cartId = getLocalStorageItem(LocalStorageKey.CART_ID);
        let cart: CartDto;
        if (jwt) {
            const { data }: { data: CartDto } = yield call(getRequestSaga, Endpoint.CART, {});
            cart = data;
        } else if (cartId) {
            const { data }: { data: CartDto } = yield call(getRequestSaga, Endpoint.CART_BY_ID, { cartId });
            cart = data;
        }
        if (cart && cart.storeId) {
            yield put(makeCheckoutChangeCartSuccessAction(cart));
            const { data: store }: { data: StoreDto } = yield call(getRequestSaga, Endpoint.STORE, {
                storeId: cart.storeId,
            });
            const {
                data: { promos },
            } = yield call(getRequestSaga, Endpoint.VENDOR_PROMOS, { storeId: cart.storeId }, {}, { active: true });
            yield put(makeCheckoutSetStoreInfoAction({ ...store, promos }));
            setLocalStorageItem(LocalStorageKey.CART_ID, cart.cartId);
        }
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('orders:errors.checkout'));
    }
}

function* checkoutAddToCartSaga(action: CheckoutAddToCartAction): SagaIterator {
    try {
        yield put(makeCheckoutHideSuccessMessageAction());

        const cart: CartDto = yield select(checkoutCartSelector);

        const standardAddressData: AddressDto = yield select(feedStandardAddressDataSelector);

        const address: string = yield select(feedAddressSelector);
        const coordinates = yield select(feedCoordinatesSelector);
        const [city, street] = splitOnce(address, ', ');

        const { product, storeId, quantity, selectedOptions } = action.payload;

        if (cart?.storeId !== storeId) {
            const newStoreInfo: StoreDto = yield select(storeSelector);
            const {
                data: { promos },
            } = yield call(getRequestSaga, Endpoint.VENDOR_PROMOS, { storeId }, {}, { active: true });
            yield put(makeCheckoutSetStoreInfoAction({ ...newStoreInfo, promos }));

            const deliveryInfo = getDeliveryInfo(newStoreInfo);

            const defaultSelectedDeliveryType: DeliveryType =
                (deliveryInfo[DeliveryType.DELIVERY_SERVICE] && DeliveryType.DELIVERY_SERVICE) ||
                (deliveryInfo[DeliveryType.STORE_DELIVERY] && DeliveryType.STORE_DELIVERY) ||
                (deliveryInfo[DeliveryType.PICKUP] && DeliveryType.PICKUP);

            const paymentsInfo = getPaymentInfo(newStoreInfo?.storePayments, defaultSelectedDeliveryType);
            const defaultSelectedPaymentType = getDefaultPaymentType(paymentsInfo);

            const deliveryAddress = standardAddressData ?? {
                city: {
                    name: city,
                },
                street: {
                    name: street,
                },
                geoCoordinates: {
                    latitude: coordinates.lat,
                    longitude: coordinates.lon,
                },
            };

            const body = {
                storeId,
                status: CheckoutStatus.NEW,
                deliveryInfo: {
                    data: {
                        address: deliveryAddress,
                    },
                    deliveryType: defaultSelectedDeliveryType ? defaultSelectedDeliveryType : null,
                },
                cartProductDto: {
                    productId: product.productId,
                    quantity: quantity,
                    version: product.version,
                    orderedOptions: Object.keys(selectedOptions).reduce((acc, optionGroupId) => {
                        const optionGroup = product?.optionGroups.find(group => group.optionGroupId === optionGroupId);
                        if (optionGroup) {
                            return [
                                ...acc,
                                ...optionGroup.options
                                    .filter(option => selectedOptions[optionGroupId].includes(option.optionId))
                                    .map(option => ({ optionId: option.optionId, optionGroupId })),
                            ];
                        }
                        return acc;
                    }, []),
                },
                paymentInfo: {
                    paymentType: defaultSelectedPaymentType,
                },
            };

            const { data }: { data: CartDto } = yield call(postRequestSaga, Endpoint.CART, {}, body);
            setLocalStorageItem(LocalStorageKey.CART_ID, data.cartId);
            yield put(makeCheckoutChangeCartSuccessAction(data));
            sendReachGoalMetrics(ReachedGoal.ADD_TO_CART);
        } else {
            const body = {
                productId: product.productId,
                quantity: quantity,
                version: product.version,
                orderedOptions: Object.keys(selectedOptions).reduce((acc, optionGroupId) => {
                    const optionGroup = product?.optionGroups.find(group => group.optionGroupId === optionGroupId);
                    if (optionGroup) {
                        return [
                            ...acc,
                            ...optionGroup.options
                                .filter(option => selectedOptions[optionGroupId].includes(option.optionId))
                                .map(option => ({ optionId: option.optionId, optionGroupId })),
                        ];
                    }
                    return acc;
                }, []),
            };
            const { data } = yield call(postRequestSaga, Endpoint.CART_PRODUCT, { cartId: cart.cartId }, body);
            yield put(makeCheckoutChangeCartSuccessAction(data));
        }
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('orders:errors.checkout'));
    }
}

function* checkoutRemoveToCartSaga(action: CheckoutRemoveFromCartAction): SagaIterator {
    try {
        const { product } = action.payload;
        const cartId = yield select(checkoutCartIdSelector);
        const { data } = yield call(
            deleteRequestSaga,
            Endpoint.CART_PRODUCT_BY_ID,
            { cartId, productId: product.productId },
            product,
        );
        yield put(makeCheckoutChangeCartSuccessAction(data));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('orders:errors.checkout'));
    }
}

function* changeProductQuantitySaga(action: CheckoutChangeProductQuantityAction): SagaIterator {
    try {
        const { product, quantity } = action.payload;
        const cartId = yield select(checkoutCartIdSelector);
        const { data } = yield call(
            putRequestSaga,
            Endpoint.CART_PRODUCT_BY_ID,
            { cartId, productId: product.productId },
            { ...product, quantity },
        );
        yield put(makeCheckoutChangeCartSuccessAction(data));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('orders:errors.quantityProduct'));
    }
}

export function* changeAddressSaga(): SagaIterator {
    try {
        const cart: CartDto = yield select(checkoutCartSelector);

        const address: string = yield select(feedAddressSelector);
        const coordinates = yield select(feedCoordinatesSelector);
        const comment = yield select(feedAddressCommentSelector);

        const [city, street] = splitOnce(address, ', ');

        const body = {
            storeId: cart.storeId,
            status: cart.status,
            paymentInfo: cart.paymentInfo,
            deliveryInfo: {
                ...cart.deliveryInfo,
                data: {
                    address: {
                        city: {
                            name: city,
                        },
                        street: {
                            name: street,
                        },
                        geoCoordinates: {
                            latitude: coordinates?.lat,
                            longitude: coordinates?.lon,
                        },
                        comment,
                    },
                },
            },
        };

        const { data } = yield call(putRequestSaga, Endpoint.CART_BY_ID, { cartId: cart.cartId }, body);
        yield put(makeCheckoutChangeCartSuccessAction(data));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('orders:errors.address'));
    }
}

function* chooseDeliverySaga(action: CheckoutChoseDeliveryAction): SagaIterator {
    const { deliveryType } = action.payload;
    try {
        const cart: CartDto = yield select(checkoutCartSelector);

        console.log(cart);

        const storePaymentInfo: StorePaymentDto[] = yield select(checkoutCartStorePaymentInfoSelector);

        const paymentsFlag = getPaymentInfo(storePaymentInfo, deliveryType);

        let paymentType: PaymentType = yield select(checkoutCartPaymentTypeSelector);
        if (!(paymentType && paymentsFlag[paymentType])) {
            paymentType = getDefaultPaymentType(paymentsFlag);
        }

        const body = {
            ...cart,
            deliveryInfo: {
                ...cart.deliveryInfo,
                deliveryType,
            },
            paymentInfo: {
                paymentType,
            },
        };

        console.log({ body });

        const { data } = yield call(putRequestSaga, Endpoint.CART_BY_ID, { cartId: cart.cartId }, body);
        yield put(makeCheckoutChangeCartSuccessAction(data));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('orders:errors.delivery'));
    }
}

function* choosePaymentSaga(action: CheckoutChosePaymentAction): SagaIterator {
    const { paymentType } = action.payload;
    const cart: CartDto = yield select(checkoutCartSelector);
    const body = {
        ...cart,
        paymentInfo: {
            paymentType,
        },
    };

    const { data } = yield call(putRequestSaga, Endpoint.CART_BY_ID, { cartId: cart.cartId }, body);
    yield put(makeCheckoutChangeCartSuccessAction(data));
}

function* checkoutCloseSecondStep(): SagaIterator {
    const cart: CartDto = yield select(checkoutCartSelector);
    const body = {
        ...cart,
        status: CheckoutStatus.NEW,
    };

    const { data } = yield call(putRequestSaga, Endpoint.CART_BY_ID, { cartId: cart.cartId }, body);
    yield put(makeCheckoutChangeCartSuccessAction(data));
}

function* waitForPaymentSaga(): SagaIterator {
    const status = yield select(checkoutPaymentStatusSelector);
    if (status === OnlinePaymentStatus.PENDING) {
        while (true) {
            try {
                const orderId: string = getLocalStorageItem(LocalStorageKey.CART_ORDER_ID);    
                const path = window.location.href;
                const { data } = yield call(getRequestSaga, Endpoint.ONLINE_PAYMENT, { orderId }, { returnUrl: path });
                window.location.assign(data.confirmationRedirect);
            } catch (e) {
                captureException(e);
                yield put(makeCheckoutChangePaymentStatusAction(OnlinePaymentStatus.FAILURE));
                yield call(removeQueryParams);
                break;
            }
        }
    }
}

let shouldPause = false;

function* checkoutSubmitSaga(): SagaIterator {
    const totalPrice = yield select(checkoutTotalPriceSelector);
    if (totalPrice) {
        const authStatus = yield select(authStatusSelector);
        const address = yield select(feedAddressSelector);
        const coordinates = yield select(feedCoordinatesSelector);
        const phone = yield select(authPhoneNumberSelector);
        const { lat, lon } = coordinates;

        if (authStatus === AuthStatus.GUEST || !phone || phone === 'fake') {
            shouldPause = !address || !lat || !lon;
            yield put(makeCheckoutShowSilentLoginDialogAction());
        } else if (!address || !lat || !lon) {
            yield put(makeCheckoutShowAddressDialogAction());
        } else if (!shouldPause) {
            const cart: CartDto = yield select(checkoutCartSelector);
            const body = {
                ...cart,
                status: CheckoutStatus.ENTER_DETAILS,
            };

            const { data } = yield call(putRequestSaga, Endpoint.CART_BY_ID, { cartId: cart.cartId }, body);
            yield put(makeCheckoutChangeCartSuccessAction(data));
        }
    }
}

function* checkoutSilentLoginSubmitSaga(action: CheckoutSilentLoginSubmitAction): SagaIterator {
    const { jwt, username, role } = action.payload;
    yield call(setUserData, jwt, username, role);
    yield put(makeCheckoutHideSilentLoginDialogAction());
    const cartId = getLocalStorageItem(LocalStorageKey.CART_ID);
    if (cartId) {
        const cart = yield select(checkoutCartSelector);
        yield call(putRequestSaga, Endpoint.CART_BY_ID, { cartId: cart.cartId }, cart);
    }
    yield call(checkoutPurchaseSaga);
    shouldPause = false;
}

function* checkoutSuccessMessage(action: CheckoutShowSuccessMessageAction): SagaIterator {
    let timeLeft = action.payload?.delay;
    while (true) {
        yield delay(1e3);
        if (timeLeft <= 1) {
            yield put(makeCheckoutHideSuccessMessageAction());
            break;
        } else {
            --timeLeft;
        }
    }
}

function* checkoutChangePromoSaga(action: CheckoutChangePromoAction): SagaIterator {
    const { promoId } = action.payload;
    try {
        const cart: CartDto = yield select(checkoutCartSelector);
        const body = {
            ...cart,
            promoId,
        };

        const { data } = yield call(putRequestSaga, Endpoint.CART_BY_ID, { cartId: cart.cartId }, body);
        yield put(makeCheckoutChangeCartSuccessAction(data));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('orders:errors.checkout'));
    }
}

function getPaymentInfo(storePaymentInfo: StorePaymentDto[], deliveryType: DeliveryType): Record<PaymentType, boolean> {
    const paymentsFlag = {
        [PaymentType.OLPAY]: storePaymentInfo?.find(el => el.type === PaymentType.OLPAY)?.enabled,
        [PaymentType.CASH]: false,
        [PaymentType.CARD]: false,
    };
    const cashPaymentInfo = storePaymentInfo?.find(el => el.type === PaymentType.CASH);
    const cardPaymentInfo = storePaymentInfo?.find(el => el.type === PaymentType.CARD);
    if (deliveryType === DeliveryType.PICKUP) {
        paymentsFlag[PaymentType.CASH] = cashPaymentInfo?.data?.atStore && cashPaymentInfo?.enabled;
        paymentsFlag[PaymentType.CARD] = cardPaymentInfo?.data?.atStore && cardPaymentInfo?.enabled;
    } else {
        paymentsFlag[PaymentType.CASH] = cashPaymentInfo?.data?.atDelivery && cashPaymentInfo?.enabled;
        paymentsFlag[PaymentType.CARD] = cardPaymentInfo?.data?.atDelivery && cardPaymentInfo?.enabled;
    }
    return paymentsFlag;
}

function getDeliveryInfo(storeInfo: StoreDto) {
    const storeDelivery = storeInfo.storeDeliveries.find(
        (el: StoreDeliveryDto) => el.type === DeliveryType.STORE_DELIVERY || el.type === DeliveryType.DELIVERY_SERVICE,
    );
    const deliveryEnabled =
        storeDelivery?.enabled &&
        storeDelivery.data.some((val: StoreDeliveryDataDto) => val.maxDistance * 1000 > storeInfo.distance);
    return {
        [DeliveryType.STORE_DELIVERY]: storeDelivery?.type === DeliveryType.STORE_DELIVERY ? deliveryEnabled : false,
        [DeliveryType.DELIVERY_SERVICE]:
            storeDelivery?.type === DeliveryType.DELIVERY_SERVICE ? deliveryEnabled : false,
        [DeliveryType.PICKUP]: storeInfo.storeDeliveries.find((el: StoreDeliveryDto) => el.type === DeliveryType.PICKUP)
            ?.enabled,
    };
}

function getDefaultPaymentType(paymentsFlag: Record<PaymentType, boolean>): PaymentType {
    switch (true) {
        case paymentsFlag[PaymentType.CASH]:
            return PaymentType.CASH;
        case paymentsFlag[PaymentType.CARD]:
            return PaymentType.CARD;
        case paymentsFlag[PaymentType.OLPAY]:
            return PaymentType.OLPAY;
        default:
            return null;
    }
}
