import { SagaIterator } from 'redux-saga';
import { ActionPattern, call, cancel, getContext, fork, take, put } from 'redux-saga/effects';
import { actionTypes, change } from 'redux-form';
import { History } from 'history';

import { makeUrl } from '../utils/utils';

import { getRequestSaga } from 'state/rest/sagas';
import { makeStoreDetailsUpdateCoordinatesAction } from 'state/storeDetails/actions';

import { Endpoint } from 'Endpoint';
import { RoutePath } from 'RoutePath';

import { Form } from 'forms/types';
import { makeSnackbarErrorAction } from '../../state/snackbar/actions';

export function* activatedSaga(
    saga: any,
    activateAction: ActionPattern,
    deactivateAction: ActionPattern,
): SagaIterator {
    while (true) {
        try {
            yield take(activateAction);
            const task = yield fork(saga);
            yield take(deactivateAction);
            yield cancel(task);
        } catch (e) {
            yield put(makeSnackbarErrorAction('feed:errors.error'));
        }
    }
}

export function* redirectSaga(to: RoutePath, params: Record<string, string> = {}, forceReload = false): SagaIterator {
    const history: History = yield getContext('history');
    const path = makeUrl(to, params);
    if (history && history.push) {
        yield call(history.push, path);
    } else {
        window.location.replace(path);
    }
    if (forceReload) {
        window.location.reload();
    }
}

export function* removeQueryParams(): SagaIterator {
    const history: History = yield getContext('history');
    const { pathname } = window.location;
    if (history && history.replace) {
        history.replace(pathname);
    } else {
        window.location.replace(pathname);
    }
}

export function* watchCityChangeSaga(form: Form, initialCity: string = null): SagaIterator {
    let previousCity = initialCity;
    while (true) {
        const cityChangeAction = yield take(actionTypes.CHANGE);
        const { meta, payload: city } = cityChangeAction;
        if (meta.form === form && meta.field === 'city') {
            if (city !== previousCity) {
                previousCity = city;
                yield put(change(form, 'address', ''));
                try {
                    const { data } = yield call(getRequestSaga, Endpoint.CITY_COORDINATES, { city });
                    if (data.city) {
                        yield call(updateCityCoordinatesSaga, form, data);
                    }
                } catch (e) {
                    yield put(makeSnackbarErrorAction('feed:errors.address'));
                }
            }
        }
    }
}

export function* updateCityCoordinatesSaga(
    form: Form,
    payload: { latitude: number; longitude: number; city: string },
): SagaIterator {
    const { latitude, longitude, city } = payload;
    yield put(makeStoreDetailsUpdateCoordinatesAction(latitude, longitude));
    yield put(change(form, 'city', city));
}
