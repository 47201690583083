import { captureException } from '@sentry/nextjs';
import Head from 'next/head';
import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { CssBaseline, StylesProvider, ThemeProvider } from '@material-ui/core';
import dynamic from 'next/dynamic';
import { ParsedUrlQuery } from 'querystring';

import { NextPage } from 'next';
import cookies from 'next-cookies';

import { makeUrl, splitOnce } from 'core/utils/utils';
import { Endpoint } from 'Endpoint';
import { getRequest } from 'core/axios/axios';
import { FeedShops } from 'pages/Feed/components/InfiniteShopList/InfiniteShopList';

import { CookiesBanner } from 'components/CookiesBanner/CookiesBanner';
import { getDeliveryTextMobile } from 'components/DeliveryInfo/utils';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { DynamicCategories } from 'components/ShopCategoryFilter/ShopCategoryFilter';
import { ShopShortInfoProps } from 'components/ShopShortInfo/ShopShortInfo';

import { wrapper } from 'state';
import { makeTheme } from 'theme';
import { BannerCarouselItemDto } from 'types';

import i18n from '../i18n';

interface Props {
    coordinates?: {
        lat: number;
        lon: number;
    };
    preselectedShopInfo?: {
        lat: number;
        lon: number;
        storeId: string;
    };
    preselectedTag?: string;
    preselectedCategory?: string;
    shopCarouselItemsInitial?: ShopShortInfoProps[];
    infiniteShopListInitial?: ShopShortInfoProps[];
    shopListNextStartInitial?: string;
    shopListFirstTimeFetched?: boolean;
    bannerItems?: BannerCarouselItemDto[];
    categories?: DynamicCategories[];
    region?: string;
    isDataLoaded: boolean;
    language: string;
}

const theme = makeTheme();

const FeedFacade = dynamic<any>(() => import('pages/Feed/FeedFacade').then(mod => mod.FeedFacade), {
    ssr: true,
});

const Index: NextPage<Props> = ({
    shopCarouselItemsInitial,
    coordinates,
    infiniteShopListInitial,
    shopListNextStartInitial,
    shopListFirstTimeFetched,
    bannerItems,
    categories,
    preselectedShopInfo,
    preselectedTag,
    preselectedCategory,
    region,
    isDataLoaded,
    language,
}) => {
    const { t } = i18n.useTranslation();
    return (
        <>
            <Head>
                <title>{t('common:index.title', { region: region ? ` — ${region}` : '' })}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta
                    name="description"
                    content={t('common:index.description', { region: region ? ` — ${region}` : '' })}
                />
                <meta name="og:title" content={t('common:search.og.title')} />
                <meta
                    name="og:description"
                    content={t('common:search.og.description')}
                />
                <meta name="og:url" content="https://any.market" />
                <meta property="og:image" content="https://any.market/og3.jpg" />
            </Head>
            <StylesProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={LuxonUtils} locale="ru">
                        <CssBaseline />
                        <ErrorBoundary>
                            <FeedFacade
                                coordinates={coordinates}
                                shopCarouselItemsInitial={shopCarouselItemsInitial}
                                infiniteShopListInitial={infiniteShopListInitial}
                                shopListNextStartInitial={shopListNextStartInitial}
                                shopListFirstTimeFetched={shopListFirstTimeFetched}
                                bannerItems={bannerItems}
                                categories={categories}
                                preselectedShopInfo={preselectedShopInfo}
                                preselectedTag={preselectedTag}
                                preselectedCategory={preselectedCategory}
                                isDataLoaded={isDataLoaded}
                                region={region}
                                language={language}
                            />
                            <CookiesBanner />
                        </ErrorBoundary>
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            </StylesProvider>
        </>
    );
};

const getPreselectedShopInfo = (query: ParsedUrlQuery) => {
    let preselectedShopInfo: {
        lat: number;
        lon: number;
        storeId: string;
    } = null;
    let preselectedTag: string = null;
    let preselectedCategory: string = null;

    try {
        if (query.lat && query.lon && query.storeId) {
            preselectedShopInfo = {
                lat: Number(query?.lat),
                lon: Number(query?.lon),
                storeId: query?.storeId as string,
            };
        }
        if (query.tag) {
            preselectedTag = query.tag as string;
        }
        if (query.category) {
            preselectedCategory = query.category as string;
        }
    } catch (e) {
        captureException(e);
    }

    return {
        preselectedShopInfo,
        preselectedTag,
        preselectedCategory,
    };
};

const fetchCarouselItems = (latitude: number, longitude: number, tag: string, category: string) => {
    const shopCarouselUrl = makeUrl(
        Endpoint.FEED_NEW,
        {},
        {
            latitude,
            longitude,
            category,
            tag,
        },
    );

    return getRequest(shopCarouselUrl)
        .then((response: { data: FeedShops }) => parseFeedStoreData(response.data))
        .catch(console.log);
};

const fetchFeedItems = (latitude: number, longitude: number, tag: string, category: string): any => {
    const feedItemsUrl = makeUrl(
        Endpoint.FEED,
        {},
        {
            latitude,
            longitude,
            start: null,
            category,
            tag,
        },
    );

    return getRequest(feedItemsUrl)
        .then((response: { data: FeedShops }) => ({
            shops: parseFeedStoreData(response.data),
            nextStart: response.data.nextStart,
        }))
        .catch(console.log);
};

const fetchCategories = (latitude: number, longitude: number, tag: string) => {
    const categoriesUrl = makeUrl(Endpoint.FEED_CATEGORIES, {}, { latitude, longitude, tag });

    return getRequest(categoriesUrl)
        .then((response: { data: DynamicCategories[] }) => response.data)
        .catch(console.log);
};

const fetchBannerItems = (tag: string, region: string, language: string, countryCode: string) => {
    if (!region) {
        return Promise.resolve([]);
    }

    const bannerItemsUrl = makeUrl(Endpoint.FEED_CAROUSEL, {}, { tag, region: encodeURI(region), language });
    const headers = countryCode ? { 'X-Country-Code': countryCode } : {};
    return getRequest(bannerItemsUrl, headers)
        .then((response: { data: { carouselData: BannerCarouselItemDto[] } }) => {
            return response.data.carouselData;
        })
        .catch(console.log);
};

const getLocation = async (xForwardedFor: string) => {
    const headers = xForwardedFor ? { 'X-Forwarded-For': xForwardedFor } : {};
    return await getRequest(Endpoint.GEOLOCATION, headers);
}

const getRegion = (addressFromCookies: string) => {
    const addressCookie = Buffer.from(addressFromCookies || '', 'base64').toString();
    const { address } = (JSON.parse(addressCookie) as { address?: string }) || {};
    return splitOnce(address || '', ', ')[0];
};

export const getServerSideProps = wrapper.getServerSideProps(async ctx => {
    const prettyCookies = cookies(ctx);
    //@ts-ignore
    const coordinates = (prettyCookies.coordinates as { lat?: number; lon?: number }) || {};
    const language = prettyCookies['next-i18next'];
    const { lat, lon } = coordinates;

    const { query } = ctx;

    const { preselectedTag, preselectedCategory, preselectedShopInfo } = getPreselectedShopInfo(query);

    let shopCarouselItemsInitial: ShopShortInfoProps[] = [];
    let infiniteShopListInitial: ShopShortInfoProps[] = [];
    let shopListNextStartInitial: string = null;
    let shopListFirstTimeFetched: boolean = false;
    let bannerItems: BannerCarouselItemDto[] = [];
    let categories: DynamicCategories[] = [];

    const xForwardedFor = ctx.req.headers['x-forwarded-for'];
    const addressFromCookies = prettyCookies.address;
    const countryCodeFromCookies = prettyCookies.country;

    let location;
    if (!addressFromCookies || !countryCodeFromCookies) {
        location = await getLocation(Array.isArray(xForwardedFor) ? xForwardedFor[0] : xForwardedFor);
    }

    const countryCode = countryCodeFromCookies ?? location.headers['x-country-code'];

    const region = addressFromCookies ? getRegion(addressFromCookies) : location.data.city || '';

    const getDataPromises: Promise<any>[] = [fetchBannerItems(preselectedTag, region, language, countryCode)];

    if (lat && lon) {
        getDataPromises.push(fetchCategories(lat, lon, preselectedTag));
        const { shops, nextStart } = fetchFeedItems(lat, lon, preselectedTag, preselectedCategory);
        shopListFirstTimeFetched = true;
        getDataPromises.push(shops);
        getDataPromises.push(nextStart);
        getDataPromises.push(fetchCarouselItems(lat, lon, preselectedTag, preselectedCategory));
    }

    const allPromises = Promise.all(getDataPromises)
        .then(res => {
            bannerItems = res[0] || [];
            categories = res[1] || [];
            infiniteShopListInitial = res[2] || [];
            shopListNextStartInitial = res[3] || null;
            shopCarouselItemsInitial = res[4] || [];
        })
        .then(() => true);

    const timeoutPromise = new Promise<boolean>(resolve => {
        setTimeout(() => {
            resolve(false);
        }, Number(process.env.SERVER_TIMEOUT_MS || 1000));
    });

    const isDataLoaded = await Promise.race([allPromises, timeoutPromise]);

    return {
        props: {
            isDataLoaded,
            shopCarouselItemsInitial,
            coordinates,
            infiniteShopListInitial,
            shopListNextStartInitial,
            shopListFirstTimeFetched,
            bannerItems,
            categories,
            preselectedShopInfo,
            preselectedTag,
            preselectedCategory,
            region,
            language,
        },
    };
});

export const parseFeedStoreData = (data: FeedShops) => {
    return data.storeCards.reduce((acc: ShopShortInfoProps[], store) => {
        const deliveryText = getDeliveryTextMobile(store.storeDelivery);
        acc.push({
            storeId: store.storeId,
            imageId: store.image?.imageId || null,
            coverImageId: store.coverImage?.imageId || null,
            name: store.name,
            categories: store.tags.map(el => el.name).join(', '),
            storeCategory: store.storeCategory,
            distance: store.distance,
            geoCoordinates: store.geoCoordinates,
            customUrl: store.customUrl,
            deliveryText,
            sellingDescription: store.sellingDescription,
            slogan: store.slogan,
        });
        return acc;
    }, []);
};

const Component = wrapper.withRedux(Index);

export default i18n.withTranslation(['feed', 'orders'])(Component);
