import { getLocalStorageItem, LocalStorageKey } from 'core/storage/storage';

import { FeedAction, FeedActionType } from './actions';
import { AddressDto } from '../../types';

export interface FeedState {
    address: string;
    lat: number;
    lon: number;
    qc_geo?: number;
    standardAddressData: AddressDto;
    addressChanged: boolean;
    comment: string;
    hasGeolocationSucceeded: boolean;
    hoveredStoreId: string;
    selectedStoreId: string;
    isLocationOpen: boolean;
}

export interface FeedSlice {
    feed: FeedState;
}

export function getFeedStateFromLS(): Pick<FeedState, 'lat' | 'lon' | 'address' | 'comment' | 'standardAddressData'> {
    const coordinates: { lat: number; lon: number } = getLocalStorageItem(LocalStorageKey.COORDINATES);
    const addressInfo: { address: string; comment: string } = getLocalStorageItem(LocalStorageKey.ADDRESS);
    const standardAddressData: AddressDto = getLocalStorageItem(LocalStorageKey.STANDARD_ADDRESS_DATA);
    return {
        lat: coordinates?.lat ?? null,
        lon: coordinates?.lon ?? null,
        address: addressInfo?.address ?? null,
        comment: addressInfo?.comment ?? '',
        standardAddressData,
    };
}

const feedInitialState: FeedState = {
    ...getFeedStateFromLS(),
    addressChanged: false,
    hasGeolocationSucceeded: false,
    hoveredStoreId: null,
    selectedStoreId: null,
    isLocationOpen: false,
};

export const feedReducer = (state = feedInitialState, action: FeedAction): FeedState => {
    switch (action.type) {
        case FeedActionType.SET_ADDRESS:
            return {
                ...state,
                address: action.payload.address,
                comment: action.payload.comment,
            };
        case FeedActionType.SET_ADDRESS_COMMENT:
            return {
                ...state,
                comment: action.payload.comment,
            };
        case FeedActionType.SET_COORDINATES:
            return {
                ...state,
                lat: action.payload.lat,
                lon: action.payload.lon,
                qc_geo: action.payload.qc_geo,
            };
        case FeedActionType.SET_STANDARD_ADDRESS_DATA:
            return {
                ...state,
                standardAddressData: action.payload,
            };
        case FeedActionType.SET_ADDRESS_CHANGED:
            return {
                ...state,
                addressChanged: true,
            };
        case FeedActionType.OPEN_LOCATION:
            return {
                ...state,
                isLocationOpen: true,
            };
        case FeedActionType.CLOSE_LOCATION:
            return {
                ...state,
                isLocationOpen: false,
            };
        case FeedActionType.GEOLOCATION_SUCCESS:
            return {
                ...state,
                hasGeolocationSucceeded: true,
            };
        case FeedActionType.SET_HOVERED_STORE_ID:
            return {
                ...state,
                hoveredStoreId: action.payload.storeId,
            };
        case FeedActionType.SET_SELECTED_STORE_ID:
            return {
                ...state,
                selectedStoreId: action.payload.storeId,
            };
        default:
            return state;
    }
};
