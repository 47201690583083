import { PaymentMethodResponse, ShopTypeName, SubscriptionPlanResponse, SubscriptionResponse } from 'types';
import { PaymentIntent, PaymentIntentType, SubscriptionPaymentStatus } from './reducer';
import { RequestInvoiceFormData } from '../../forms/RequestInvoiceForm/RequestInvoiceForm';

export enum SubscriptionActionType {
    FETCH_SUCCESS = 'subscription/FETCH_SUCCESS',
    CLOSE_BANNER = 'subscription/CLOSE_BANNER',
    CLEAR = 'subscription/CLEAR',
    PAUSE = 'subscription/PAUSE',
    RESUME = 'subscription/RESUME',
    CHANGE_PAYMENT_STATUS = 'subscription/CHANGE_PAYMENT_STATUS',
    SET_INTENT = 'subscription/SET_INTENT',
    CHANGE = 'subscription/CHANGE',
    REQUEST = 'subscription/REQUEST',
    CHANGE_RATE_DIALOG = 'subscription/CHANGE_RATE_DIALOG',
}

export interface SubscriptionBaseAction {
    type: SubscriptionActionType;
}

export interface SubscriptionFetchSuccessAction extends SubscriptionBaseAction {
    type: SubscriptionActionType.FETCH_SUCCESS;
    payload: {
        subscription: SubscriptionResponse;
        paymentMethod: PaymentMethodResponse;
        subscriptionPlans: SubscriptionPlanResponse[];
    };
}

export interface SubscriptionCloseBannerAction extends SubscriptionBaseAction {
    type: SubscriptionActionType.CLOSE_BANNER;
}

export interface SubscriptionClearAction extends SubscriptionBaseAction {
    type: SubscriptionActionType.CLEAR;
}
export interface SubscriptionPauseAction extends SubscriptionBaseAction {
    type: SubscriptionActionType.PAUSE;
}

export interface SubscriptionResumeAction extends SubscriptionBaseAction {
    type: SubscriptionActionType.RESUME;
    payload: {
        rate: ShopTypeName;
    };
}

export interface SubscriptionRequestPriorityAction extends SubscriptionBaseAction {
    type: SubscriptionActionType.REQUEST;
    payload: {
        rate: ShopTypeName;
        data: RequestInvoiceFormData;
    };
}

export interface SubscriptionChangePaymentStatusAction extends SubscriptionBaseAction {
    type: SubscriptionActionType.CHANGE_PAYMENT_STATUS;
    payload: {
        paymentStatus: SubscriptionPaymentStatus;
    };
}

export interface SubscriptionSetIntentAction extends SubscriptionBaseAction {
    type: SubscriptionActionType.SET_INTENT;
    payload: PaymentIntent;
}

export interface SubscriptionChangeAction extends SubscriptionBaseAction {
    type: SubscriptionActionType.CHANGE;
}

export interface SubscriptionChangeRateDialogAction extends SubscriptionBaseAction {
    type: SubscriptionActionType.CHANGE_RATE_DIALOG;
    payload: {
        rate: ShopTypeName;
    };
}

export type SubscriptionAction =
    | SubscriptionFetchSuccessAction
    | SubscriptionClearAction
    | SubscriptionPauseAction
    | SubscriptionResumeAction
    | SubscriptionChangePaymentStatusAction
    | SubscriptionSetIntentAction
    | SubscriptionChangeAction
    | SubscriptionCloseBannerAction
    | SubscriptionRequestPriorityAction
    | SubscriptionChangeRateDialogAction;

export const makeSubscriptionFetchSuccessAction = (
    subscription: SubscriptionResponse,
    paymentMethod: PaymentMethodResponse,
    subscriptionPlans: SubscriptionPlanResponse[],
): SubscriptionFetchSuccessAction => ({
    type: SubscriptionActionType.FETCH_SUCCESS,
    payload: {
        subscription,
        paymentMethod,
        subscriptionPlans,
    },
});

export const makeSubscriptionCloseBannerAction = (): SubscriptionCloseBannerAction => ({
    type: SubscriptionActionType.CLOSE_BANNER,
});

export const makeSubscriptionClearAction = (): SubscriptionClearAction => ({
    type: SubscriptionActionType.CLEAR,
});

export const makeSubscriptionPauseAction = (): SubscriptionPauseAction => ({
    type: SubscriptionActionType.PAUSE,
});

export const makeSubscriptionRequestPriorityAction = (
    rate: ShopTypeName,
    data: RequestInvoiceFormData,
): SubscriptionRequestPriorityAction => ({
    type: SubscriptionActionType.REQUEST,
    payload: {
        rate,
        data,
    },
});

export const makeSubscriptionResumeAction = (rate: ShopTypeName): SubscriptionResumeAction => ({
    type: SubscriptionActionType.RESUME,
    payload: {
        rate,
    },
});

export const makeSubscriptionChangePaymentStatusAction = (
    paymentStatus: SubscriptionPaymentStatus,
): SubscriptionChangePaymentStatusAction => ({
    type: SubscriptionActionType.CHANGE_PAYMENT_STATUS,
    payload: {
        paymentStatus,
    },
});

export const makeSubscriptionSetIntentAction = (
    intentType: PaymentIntentType,
    subscriptionPlanId: string,
): SubscriptionSetIntentAction => ({
    type: SubscriptionActionType.SET_INTENT,
    payload: {
        intentType,
        subscriptionPlanId,
    },
});

export const makeSubscriptionChangeAction = (): SubscriptionChangeAction => ({
    type: SubscriptionActionType.CHANGE,
});

export const makeSubscriptionChangeRateDialogAction = (rate: ShopTypeName): SubscriptionChangeRateDialogAction => ({
    type: SubscriptionActionType.CHANGE_RATE_DIALOG,
    payload: {
        rate,
    },
});
