export enum PreviewActionType {
    NAVIGATE = 'preview/NAVIGATE',
}

export interface PreviewBaseAction {
    type: PreviewActionType;
}

export interface PreviewNavigateAction extends PreviewBaseAction {
    type: PreviewActionType.NAVIGATE;
}

export const makePreviewNavigateAction = (): PreviewNavigateAction => ({
    type: PreviewActionType.NAVIGATE,
});
