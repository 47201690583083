import { StoreDetailsSubmitRequest } from './types';

export enum StoreDetailsActionType {
    ACTIVATE = 'storeDetails/ACTIVATE',
    DEACTIVATE = 'storeDetails/DEACTIVATE',
    UPDATE_COORDINATES = 'storeDetails/UPDATE_COORDINATES',
    SUBMIT = 'storeDetails/SUBMIT',
}

export interface StoreDetailsBaseAction {
    type: StoreDetailsActionType;
}

export interface StoreDetailsUpdateCoordinatesAction extends StoreDetailsBaseAction {
    type: StoreDetailsActionType.UPDATE_COORDINATES;
    payload: {
        lat: number;
        lon: number;
    };
}

export interface StoreDetailsSubmitAction extends StoreDetailsBaseAction {
    type: StoreDetailsActionType.SUBMIT;
    payload: StoreDetailsSubmitRequest;
}

export type StoreDetailsAction = StoreDetailsUpdateCoordinatesAction | StoreDetailsSubmitAction;

export const makeStoreDetailsUpdateCoordinatesAction = (
    lat: number,
    lon: number,
): StoreDetailsUpdateCoordinatesAction => ({
    type: StoreDetailsActionType.UPDATE_COORDINATES,
    payload: {
        lat,
        lon,
    },
});

export const makeStoreDetailsSubmitAction = (payload: StoreDetailsSubmitRequest): StoreDetailsSubmitAction => ({
    type: StoreDetailsActionType.SUBMIT,
    payload,
});
