import { ErrorBoundary as SentryErrorBoundary } from '@sentry/nextjs';
import React, { Component } from 'react';

import { postRequest } from 'core/axios/axios';

import { Text, TextTypography } from 'components/Text/Text';

import { Endpoint } from 'Endpoint';
import { withTranslation } from 'next-i18next';

class ErrorBoundaryComponent extends Component<{}> {
    private handleError = (error: Error, componentStack: string) => {
        postRequest(Endpoint.LOG_ERROR, {
            location: window.location.href,
            stackTrace: error.message + '\n' + error.stack + '\n' + componentStack,
        }).then();
    };

    render() {
        const { t } = this.props;
        return (
            <SentryErrorBoundary
                fallback={
                    <div
                        style={{
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                        }}
                    >
                        <Text typography={TextTypography.BODY_ACCENT}>{t('common:initError.somethingWentWrong')}</Text>
                        <Text typography={TextTypography.BODY}>{t('common:initError.weKnowAboutProblem')}</Text>
                    </div>
                }
                onError={this.handleError}
            >
                {this.props.children}
            </SentryErrorBoundary>
        );
    }
}

export const ErrorBoundary = withTranslation()(ErrorBoundaryComponent);
