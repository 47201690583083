export enum Form {
    ADD_PRODUCT_FORM = 'ADD_PRODUCT_FORM',
    ADDRESS_FORM = 'ADDRESS_FORM',
    LOCATION_FORM = 'LOCATION_FORM',
    LOGIN_FORM = 'LOGIN_FORM',
    SIGNUP_FORM = 'SIGNUP_FORM',
    SIGNUP_CODE_FORM = 'SIGNUP_CODE_FORM',
    PASSWORD_RESET_PHONE_FORM = 'PASSWORD_RESET_PHONE_FORM',
    PASSWORD_RESET_CODE_FORM = 'PASSWORD_RESET_CODE_FORM',
    PASSWORD_RESET_PASSWORD_FORM = 'PASSWORD_RESET_PASSWORD_FORM',
    PHONE_CONFIRMATION_FORM = 'PHONE_CONFIRMATION_FORM',
    REGISTRATION_DELIVERY_FORM = 'REGISTRATION_DELIVERY_FORM',
    REGISTRATION_PICKUP_FORM = 'REGISTRATION_PICKUP_FORM',
    REGISTRATION_CASH_FORM = 'REGISTRATION_CASH_FORM',
    REGISTRATION_CARD_FORM = 'REGISTRATION_CARD_FORM',
    STORE_DETAILS_FORM = 'STORE_DETAILS_FORM',
    YANDEX_CHECKOUT_SETUP_FORM = 'YANDEX_CHECKOUT_SETUP_FORM',
    PROFILE_FORM = 'PROFILE_FORM',
    REQUEST_INVOICE_FORM = 'REQUEST_INVOICE_FORM',
    STORE_DETAILS_EDIT_FORM = 'STORE_DETAILS_EDIT_FORM',
    STORE_SETTING_EDIT_FORM = 'STORE_SETTING_EDIT_FORM',
    DELIVERY_DETAILS_FORM = 'DELIVERY_DETAILS_FORM',
    DELIVERY_WEIGHT_FORM = 'DELIVERY_WEIGHT_FORM',
    DELIVERY_PLAN_FORM = 'DELIVERY_PLAN_FORM',
    ONBOARDING_SELLER_FORM = 'ONBOARDING_SELLER_FORM',
    ONBOARDING_STORE_FORM = 'ONBOARDING_STORE_FORM',
    FINISH_ONBOARDING_FORM = 'FINISH_ONBOARDING_FORM',
    CURRENT_LOCATION_FORM = 'CURRENT_LOCATION_FORM',
    PROMO_FORM = 'PROMO_FORM',
    EXTEND_TRIAL_PERIOD_FORM = 'EXTEND_TRIAL_PERIOD_FORM',
    CREATE_LINK_FORM = 'CREATE_LINK_FORM',
    CHECKOUT_CART_ADDRESS_FORM = 'CHECKOUT_CART_ADDRESS_FORM',
    REPORT_ITEM_DETAILS_FORM = 'REPORT_ITEM_DETAILS_FORM',
    CHANGE_RATE_FORM = 'CHANGE_RATE_FORM',
    CLONE_STORE_FORM = 'CLONE_STORE_FORM',
}
