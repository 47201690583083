import { CheckoutAction, CheckoutActionType } from './actions';
import { CartDto, StoreDto } from 'types';

export enum OnlinePaymentStatus {
    NONE = 'NONE',
    FORM = 'FORM',
    PENDING = 'PENDING',
    FAILURE = 'FAILURE',
}

export interface CheckoutState {
    cart: CartDto;
    storeInfo: StoreDto;
    paymentStatus: OnlinePaymentStatus;
    showAddressDialog: boolean;
    showSilentLoginDialog: boolean;
    showSuccessMessage: string | null;
    showCheckout: boolean;
}

export interface CheckoutSlice {
    checkout: CheckoutState;
}

const isServer = typeof window === 'undefined';

const getPaymentStatus = () => {
    if (!isServer) {
        const urlParams = new URLSearchParams(window.location.search);
        const complete = urlParams.get('complete');
        return complete ? OnlinePaymentStatus.PENDING : OnlinePaymentStatus.NONE;
    }
    return null;
};

const checkoutInitialState = (): CheckoutState => ({
    cart: null,
    storeInfo: null,
    paymentStatus: getPaymentStatus(),
    showAddressDialog: false,
    showSilentLoginDialog: false,
    showSuccessMessage: null,
    showCheckout: false,
});

export const checkoutReducer = (state = checkoutInitialState(), action: CheckoutAction): CheckoutState => {
    switch (action.type) {
        case CheckoutActionType.CLEAR:
            return { ...checkoutInitialState(), showSuccessMessage: state.showSuccessMessage };
        case CheckoutActionType.CHANGE_CART_SUCCESS:
            const { selectedPromo, ...rest } = action.payload;
            return {
                ...state,
                cart: action.payload
                    ? {
                          ...rest,
                          selectedPromo,
                          promoId: selectedPromo?.promoApplied ? selectedPromo.promo.promoId : null,
                      }
                    : null,
            };
        case CheckoutActionType.SET_STORE_INFO:
            return {
                ...state,
                storeInfo: action.payload.storeInfo,
            };
        case CheckoutActionType.CHOSE_PAYMENT:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    paymentInfo: {
                        paymentType: action.payload.paymentType,
                    },
                },
            };
        case CheckoutActionType.CHOSE_DELIVERY:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    deliveryInfo: {
                        ...state.cart.deliveryInfo,
                        deliveryType: action.payload.deliveryType,
                    },
                },
            };
        case CheckoutActionType.CHANGE_PAYMENT_STATUS:
            return {
                ...state,
                paymentStatus: action.payload.status,
            };
        case CheckoutActionType.SHOW_SILENT_LOGIN_DIALOG:
            return {
                ...state,
                showSilentLoginDialog: true,
            };
        case CheckoutActionType.HIDE_SILENT_LOGIN_DIALOG:
            return {
                ...state,
                showSilentLoginDialog: false,
            };
        case CheckoutActionType.SHOW_ADDRESS_DIALOG:
            return {
                ...state,
                showAddressDialog: true,
            };
        case CheckoutActionType.HIDE_ADDRESS_DIALOG:
            return {
                ...state,
                showAddressDialog: false,
            };
        case CheckoutActionType.SHOW_SUCCESS_MESSAGE:
            return {
                ...state,
                showSuccessMessage: action.payload.orderId,
            };
        case CheckoutActionType.HIDE_SUCCESS_MESSAGE:
            return {
                ...state,
                showSuccessMessage: null,
            };
        case CheckoutActionType.OPEN_CART:
            return {
                ...state,
                showCheckout: true,
            };
        case CheckoutActionType.CLOSE_CART:
            return {
                ...state,
                showCheckout: false,
            };
        default:
            return state;
    }
};
