import { FeedSlice } from './reducer';

export const feedCoordinatesSelector = (state: FeedSlice) => {
    return {
        lat: state.feed.lat,
        lon: state.feed.lon,
        qc_geo: state.feed.qc_geo,
    };
};

export const feedStandardAddressDataSelector = (state: FeedSlice) => {
    return state.feed.standardAddressData;
};

export const feedAddressSelector = (state: FeedSlice) => {
    return state.feed.address;
};

export const feedIsLocationOpenSelector = (state: FeedSlice) => {
    return state.feed.isLocationOpen;
};

export const feedAddressCommentSelector = (state: FeedSlice) => {
    return state.feed.comment;
};

export const feedAddressChangedSelector = (state: FeedSlice) => {
    return state.feed.addressChanged;
};

export const feedHasGeolocationSucceededSelector = (state: FeedSlice) => {
    return state.feed.hasGeolocationSucceeded;
};

export const feedHoveredStoreIdSelector = (state: FeedSlice) => {
    return state.feed.hoveredStoreId;
};

export const feedSelectedStoreIdSelector = (state: FeedSlice) => {
    return state.feed.selectedStoreId;
};
