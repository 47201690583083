import { AddressDto, CroppedArea, StoreCategory } from '../../types';

export enum HeaderItemType {
    COMPLETE,
    ACTIVE,
    DISABLED,
}

export enum OnboardingScreen {
    SELLER = 1,
    SELLER_PHONE_VERIFICATION,
    STORE,
    STORE_PHONE_VERIFICATION,
    CATALOG,
    SUCCESS,
}

export interface SellerFormData {
    phone: string;
    name: string;
    email: string;
}

export interface StoreFormData {
    coverImage: File;
    name: string;
    address: string;
    coordinates: { lat: number; lon: number };
    storeCategory: StoreCategory;
    phone: string;
    customUrl: string;
    imageCrop: CroppedArea;
    waPhone: boolean;
    standardAddress: AddressDto;
    domain: string;
}
