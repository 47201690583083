import { StoreDetailsAction, StoreDetailsActionType } from './actions';

export interface StoreDetailsState {
    cityCoordinates: {
        lat: number;
        lon: number;
    };
}

export interface StoreDetailsSlice {
    storeDetails: StoreDetailsState;
}

const initialStoreDetailsState: StoreDetailsState = {
    cityCoordinates: {
        lat: null,
        lon: null,
    },
};

export const storeDetailsReducer = (
    state = initialStoreDetailsState,
    action: StoreDetailsAction,
): StoreDetailsState => {
    switch (action.type) {
        case StoreDetailsActionType.UPDATE_COORDINATES:
            return {
                ...state,
                cityCoordinates: {
                    lat: action.payload.lat,
                    lon: action.payload.lon,
                },
            };
        default:
            return state;
    }
};
