import { AuthSlice } from './reducer';

import { UserRole } from 'types';

export const authStatusSelector = (state: AuthSlice) => {
    return state.auth.status;
};

export const authIsoCodeSelector = (state: AuthSlice) => {
    return state.auth.isoCode;
};

export const authRoleSelector = (state: AuthSlice) => {
    return state.auth.role;
};

export const authResetPasswordStageSelector = (state: AuthSlice) => {
    return state.auth.reset.stage;
};

export const authStoreIdSelector = (state: AuthSlice) => {
    return state.auth.storeId;
};

export const authStoreCategorySelector = (state: AuthSlice) => {
    return state.auth.storeCategory;
};

export const authStoreCurrentStateSelector = (state: AuthSlice) => {
    return state.auth.storeCurrentState;
};

export const authStoreUrlSelector = (state: AuthSlice) => {
    return state.auth.storeUrl;
};

export const authStoreOpenForOrdersSelector = (state: AuthSlice) => {
    return state.auth.openForOrders;
};

export const authPhoneNumberSelector = (state: AuthSlice) => {
    return state.auth.phone;
};

export const authResetPasswordTimeLeftSelector = (state: AuthSlice) => {
    return state.auth.reset.timeLeft;
};

export const authResetPasswordPhoneSelector = (state: AuthSlice) => {
    return state.auth.reset.phone;
};

export const authMarketingDocsSelector = (state: AuthSlice) => {
    return Object.values(state.auth.marketingDocs);
};

export const authCanBuySelector = (state: AuthSlice) => {
    return state.auth.role !== UserRole.VENDOR;
};

export const authAdminRoleSelector = (state: AuthSlice) => {
    return state.auth.availableRoles.includes(UserRole.ADMINISTRATOR)
        ? UserRole.ADMINISTRATOR
        : state.auth.availableRoles.includes(UserRole.OPERATION_ADMIN)
        ? UserRole.OPERATION_ADMIN
        : null;
};

export const isAuthTemporaryPassSelector = (state: AuthSlice) => {
    return state.auth.temporaryPass;
};

export const isAuthUserFakeStatusSelector = (state: AuthSlice) => {
    return state.auth.fake;
};
