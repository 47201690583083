import { getLocalStorageItem, LocalStorageKey } from 'core/storage/storage';

import { appConfig } from 'config/app';

import { AuthAction, AuthActionType } from './actions';

import { StoreCategory, StoreCurrentState, SupportedCountryCodes, UserRole } from 'types';
import { getRole } from 'core/auth/auth';

export enum AuthStatus {
    LOGGED_IN = 'LOGGED_IN',
    GUEST = 'GUEST',
}

export enum ResetPasswordStage {
    PHONE,
    CODE,
    PASSWORD,
}

export interface AuthState {
    role: UserRole;
    status: AuthStatus;
    phone: string;
    reset: {
        phone: string;
        stage: ResetPasswordStage;
        timeLeft: number;
    };
    storeId: string;
    storeCategory: StoreCategory;
    storeCurrentState: StoreCurrentState;
    openForOrders: boolean;
    storeUrl: string;
    isoCode: SupportedCountryCodes;
    marketingDocs: Record<string, { url: string; name: string }>;
    availableRoles: UserRole[];
    temporaryPass: boolean;
    fake: boolean;
}

export interface AuthSlice {
    auth: AuthState;
}

export function getAuthStateFromLS(): Pick<AuthState, 'role' | 'status' | 'phone' | 'storeId'> {
    const role = getRole();
    return {
        role,
        status: role ? AuthStatus.LOGGED_IN : AuthStatus.GUEST,
        phone: getLocalStorageItem(LocalStorageKey.PHONE),
        storeId: getLocalStorageItem(LocalStorageKey.STORE_ID),
    };
}

const initialState: AuthState = {
    ...getAuthStateFromLS(),
    reset: {
        phone: null,
        stage: ResetPasswordStage.PHONE,
        timeLeft: appConfig.otpTimeout,
    },
    storeCategory: null,
    storeCurrentState: null,
    openForOrders: null,
    storeUrl: null,
    isoCode: null,
    marketingDocs: {},
    availableRoles: [],
    temporaryPass: false,
    fake: false,
};

export const authReducer = (state = initialState, action: AuthAction): AuthState => {
    switch (action.type) {
        case AuthActionType.LOGIN_SUCCESS:
            return {
                ...state,
                status: AuthStatus.LOGGED_IN,
                phone: action.payload.phone,
                role: action.payload.role,
            };
        case AuthActionType.UPDATE_STORE_ID:
            return {
                ...state,
                storeId: action.payload.storeId,
                storeCategory: action.payload.category,
                storeCurrentState: action.payload.currentState,
                storeUrl: action.payload.storeUrl,
                openForOrders: action.payload.openForOrders,
                isoCode: action.payload.isoCode,
            };
        case AuthActionType.LOGIN_FAILURE:
            return {
                ...state,
                status: AuthStatus.GUEST,
                storeId: null,
                role: null,
                phone: null,
                storeCategory: null,
                storeCurrentState: null,
                openForOrders: null,
                isoCode: null,
            };
        case AuthActionType.RESET_PASSWORD_PHONE_SUBMIT:
            return {
                ...state,
                reset: {
                    ...state.reset,
                    phone: action.payload.username,
                },
            };
        case AuthActionType.RESET_PASSWORD_UPDATE_STAGE:
            return {
                ...state,
                reset: {
                    ...state.reset,
                    stage: action.payload.stage,
                    timeLeft: appConfig.otpTimeout,
                },
            };
        case AuthActionType.RESET_PASSWORD_UPDATE_TIME_LEFT:
            return {
                ...state,
                reset: {
                    ...state.reset,
                    timeLeft: action.payload.timeLeft,
                },
            };
        case AuthActionType.SET_FULL_STORE:
            return {
                ...state,
                storeCurrentState: StoreCurrentState.FULL,
            };
        case AuthActionType.SET_MARKETING_DOCS:
            return {
                ...state,
                marketingDocs: {
                    ...state.marketingDocs,
                    ...action.payload.docs.reduce((acc, doc) => ({ ...acc, [doc.id]: doc }), {}),
                },
            };
        case AuthActionType.SET_ROLES:
            return {
                ...state,
                availableRoles: action.payload.roles,
                temporaryPass: action.payload.temporaryPass,
            };
        case AuthActionType.UPDATE_FROM_LOCAL_STORAGE:
            return {
                ...state,
                ...getAuthStateFromLS(),
            };
        case AuthActionType.CHANGE_OPEN_FOR_ORDERS:
            return {
                ...state,
                openForOrders: action.payload.openForOrders,
            };
        case AuthActionType.SET_FAKE_STATUS:
            return {
                ...state,
                fake: action.payload.fake,
            };
        case AuthActionType.RESET_STORE_INFO:
            return {
                ...state,
                storeId: null,
                storeCategory: null,
                storeCurrentState: null,
                isoCode: null,
            };
        default:
            return state;
    }
};
