import { captureException } from '@sentry/nextjs';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { makeFormDataBuilder } from 'core/axios/axios';
import { activatedSaga, redirectSaga } from 'core/sagas/sagas';

import {
    makeCatalogFetchCategoriesSuccessAction,
    makeCatalogFetchProductsSuccessAction,
    CatalogActionType,
    CatalogSubmitProductAction,
    CatalogDeleteProductAction,
    makeCatalogFetchProductSuccessAction,
    makeCatalogSubmitProductSuccessAction,
    CatalogChangeCategoryProductsOrderAction,
    makeCatalogChangeCategoryProductsOrderSuccessAction,
    CatalogChangeCategoriesOrderAction,
} from './actions';
import { makeAppUIDisableAction, makeAppUIEnableAction } from '../app/actions';
import {
    deleteRequestSaga,
    formPostMultipartRequestSaga,
    formPostRequestSaga,
    formPutRequestSaga,
    getRequestSaga,
    handleFormErrorSaga,
    postRequestSaga,
} from '../rest/sagas';
import { authStoreIdSelector } from '../auth/selectors';

import { Endpoint } from 'Endpoint';
import { RoutePath } from 'RoutePath';

import { ImageType } from 'types';
import { Form } from 'forms/types';
import { makeSnackbarErrorAction } from '../snackbar/actions';
import { GalleryItem } from 'components/Gallery/types';

export function* catalogSaga(): SagaIterator {
    yield takeEvery(CatalogActionType.SUBMIT_PRODUCT, registrationSubmitCatalogProductSaga);
    yield takeEvery('catalog/FETCH_PRODUCT', function* (action: any) {
        try {
            const storeId = yield select(authStoreIdSelector);
            const { categoryId, productId } = action.payload;
            const { data } = yield call(getRequestSaga, Endpoint.PRODUCT, { storeId, categoryId, productId });
            const product = {
                ...data,
                isSpecialOffer: data.promo?.promo,
            };
            yield put(makeCatalogFetchProductSuccessAction(product));
        } catch (e) {
            captureException(e);
            yield put(makeSnackbarErrorAction('orders:errors.product'));
        }
    });
    yield takeEvery(CatalogActionType.DELETE_PRODUCT, function* (action: CatalogDeleteProductAction) {
        try {
            const storeId = yield select(authStoreIdSelector);
            const { categoryId, productId, isVendor } = action.payload;
            yield call(deleteRequestSaga, Endpoint.PRODUCTS, { storeId, categoryId }, [productId]);
            yield call(catalogFetchDataSaga, storeId);
            yield call(redirectSaga, isVendor ? RoutePath.VENDOR_CATALOG : RoutePath.REGISTRATION_CATALOG);
        } catch (e) {
            captureException(e);
            yield put(makeSnackbarErrorAction('orders:errors.deleted'));
        }
    });
    yield takeEvery(CatalogActionType.CHANGE_CATEGORY_PRODUCTS_ORDER, changeCategoryProductsOrderSaga);
    yield takeEvery(CatalogActionType.CHANGE_CATEGORIES_ORDER, changeCategoriesOrderSaga);
    yield takeEvery(CatalogActionType.FETCH_PRODUCTS_REQUEST, fetchProductsRequestSaga);
    yield call(activatedSaga, catalogActivatedSaga, CatalogActionType.ACTIVATE, CatalogActionType.DEACTIVATE);
}

function* catalogFetchDataSaga(storeId: string): SagaIterator {
    try {
        const { data: categories } = yield call(getRequestSaga, Endpoint.REGISTRATION_CATALOG_CATEGORIES, { storeId });
        const { data: products } = yield call(getRequestSaga, Endpoint.STORE_PRODUCTS, { storeId });
        yield put(makeCatalogFetchCategoriesSuccessAction(categories));
        yield put(makeCatalogFetchProductsSuccessAction(products));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('orders:errors.catalog'));
    }
}

function* catalogActivatedSaga(): SagaIterator {
    const storeId = yield select(authStoreIdSelector);
    yield call(catalogFetchDataSaga, storeId);
}

function* getCategoryId(name: string): SagaIterator {
    const storeId = yield select(authStoreIdSelector);
    const { data: categories } = yield call(getRequestSaga, Endpoint.REGISTRATION_CATALOG_CATEGORIES, { storeId });
    yield put(makeCatalogFetchCategoriesSuccessAction(categories));
    const categoryName = name ? name : 'common:other';
    const existingCategory = categories.find((c: any) => c.name === categoryName);
    if (existingCategory) {
        return existingCategory.categoryId;
    }
    const body = {
        name: categoryName,
    };
    const { data: categoryId } = yield call(
        postRequestSaga,
        Endpoint.REGISTRATION_CATALOG_CATEGORIES,
        { storeId },
        body,
    );
    return categoryId;
}

function* registrationSubmitCatalogProductSaga(action: CatalogSubmitProductAction): SagaIterator {
    try {
        console.log(action.payload);
        const { productId, product, isVendorRoute } = action.payload;
        yield put(makeAppUIDisableAction());
        const { category, coverImageCrop, ...rest } = product;
        const categoryId = yield call(getCategoryId, category);
        const storeId = yield select(authStoreIdSelector);
        let images = [];
        let defaultImage = true;
        for (let galleryItem of (product.images as GalleryItem[])) {
            if (galleryItem.file) {
                const formData = makeFormDataBuilder()
                    .addFile('file', galleryItem.file)
                    .addData('imageData', { defaultImage, type: ImageType.PRODUCT })
                    .addData('resizeData', galleryItem.croppedArea)
                    .build();
                const { data: imageId } = yield call(
                    formPostMultipartRequestSaga,
                    Form.ADD_PRODUCT_FORM,
                    Endpoint.IMAGE,
                    {},
                    formData,
                );
                images.push({
                        defaultImage,
                        imageId,
                        type: ImageType.PRODUCT,
                    })
                if (defaultImage) {
                    defaultImage = false;
                }
            } else {
                images.push(galleryItem);
                if (images.find(image => image.defaultImage)) {
                    defaultImage = false;
                }
            }
        }

        const body = {
            version: 0,
            name: rest.name,
            description: rest.description,
            price: rest.price,
            unitType: rest.unit,
            tags: [] as null[],
            category: {
                categoryId,
            },
            optionGroups: rest.optionGroups.map(optionGroup => ({
                ...optionGroup,
                options: optionGroup.options.filter(option => !!option.name),
            })),
            unitQuantity: rest.unitQuantity,
            outOfStock: rest.outOfStock,
            promo: {
                promo: rest.isSpecialOffer,
            },
            hidden: rest.hidden,
            vendorCode: rest.vendorCode,
            images,
        };
        if (productId) {
            yield call(
                formPutRequestSaga,
                Form.ADD_PRODUCT_FORM,
                Endpoint.PRODUCT,
                { storeId, categoryId, productId },
                body,
            );
        } else {
            yield call(
                formPostRequestSaga,
                Form.ADD_PRODUCT_FORM,
                Endpoint.REGISTRATION_CATALOG_PRODUCT,
                { storeId, categoryId },
                body,
            );
        }
        yield call(redirectSaga, isVendorRoute ? RoutePath.VENDOR_CATALOG : RoutePath.REGISTRATION_CATALOG);
        const { data: categories } = yield call(getRequestSaga, Endpoint.REGISTRATION_CATALOG_CATEGORIES, { storeId });
        const { data: products } = yield call(getRequestSaga, Endpoint.STORE_PRODUCTS, { storeId });
        yield put(makeCatalogFetchCategoriesSuccessAction(categories));
        yield put(makeCatalogFetchProductsSuccessAction(products));
        yield put(makeCatalogSubmitProductSuccessAction());
    } catch (e) {
        captureException(e);
        yield call(handleFormErrorSaga, Form.ADD_PRODUCT_FORM, e);
    } finally {
        yield put(makeAppUIEnableAction());
    }
}

function* changeCategoryProductsOrderSaga(action: CatalogChangeCategoryProductsOrderAction): SagaIterator {
    try {
        const storeId = yield select(authStoreIdSelector);
        const { data: category } = yield call(
            postRequestSaga,
            Endpoint.CHANGE_CATEGORY_PRODUCTS_ORDER,
            { storeId },
            action.payload,
        );
        yield put(makeCatalogChangeCategoryProductsOrderSuccessAction(category));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('orders:errors.category'));
    }
}

function* changeCategoriesOrderSaga(action: CatalogChangeCategoriesOrderAction): SagaIterator {
    try {
        const storeId = yield select(authStoreIdSelector);
        const { data: categories } = yield call(
            postRequestSaga,
            Endpoint.CHANGE_CATEGORIES_ORDER,
            { storeId },
            action.payload,
        );
        yield put(makeCatalogFetchCategoriesSuccessAction(categories));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('orders:errors.order'));
    }
}

function* fetchProductsRequestSaga(): SagaIterator {
    try {
        const storeId = yield select(authStoreIdSelector);
        const { data: products } = yield call(getRequestSaga, Endpoint.STORE_PRODUCTS, { storeId });
        yield put(makeCatalogFetchProductsSuccessAction(products));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('orders:errors.fetchProducts'));
    }
}
