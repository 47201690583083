import { ReportItemDetails, StoreEntry } from '../../pages/AdminReport/Report';

export enum AdminActionType {
    GET_STORE_ENTRIES = 'admin/GET_STORE_ENTRIES',
    GET_STORE_ENTRIES_SUCCESS = 'admin/GET_STORE_ENTRIES_SUCCESS',
    GET_STORE_DETAILS = 'admin/GET_STORE_DETAILS',
    GET_STORE_DETAILS_SUCCESS = 'admin/GET_STORE_DETAILS_SUCCESS',
}

export interface GetReportAction {
    type: AdminActionType.GET_STORE_ENTRIES;
}

export interface GetReportItemDetailsAction {
    type: AdminActionType.GET_STORE_DETAILS;
    storeEntry: StoreEntry;
}

export interface GetReportSuccessAction {
    type: AdminActionType.GET_STORE_ENTRIES_SUCCESS;
    stores: StoreEntry[];
}

export interface GetReportItemDetailsSuccessAction {
    type: AdminActionType.GET_STORE_DETAILS_SUCCESS;
    selectedStore: ReportItemDetails;
}

export type ReportAction =
    | GetReportAction
    | GetReportSuccessAction
    | GetReportItemDetailsAction
    | GetReportItemDetailsSuccessAction;

export const makeGetReportAction = (): ReportAction => ({
    type: AdminActionType.GET_STORE_ENTRIES,
});

export const makeGetReportItemDetailsAction = (storeEntry: StoreEntry): ReportAction => ({
    type: AdminActionType.GET_STORE_DETAILS,
    storeEntry: storeEntry,
});

export const makeGetReportSuccessAction = (stores: StoreEntry[]): ReportAction => ({
    type: AdminActionType.GET_STORE_ENTRIES_SUCCESS,
    stores: stores,
});

export const makeGetReportItemDetailsSuccessAction = (storeDetails: ReportItemDetails): ReportAction => ({
    type: AdminActionType.GET_STORE_DETAILS_SUCCESS,
    selectedStore: storeDetails,
});
