import { ResetPasswordStage } from './reducer';

import { StoreCategory, StoreCurrentState, SupportedCountryCodes, UserRole } from 'types';

export enum AuthActionType {
    LOGIN_REQUEST = 'auth/LOGIN_REQUEST',
    LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS',
    LOGIN_FAILURE = 'auth/LOGIN_FAILURE',
    UPDATE_STORE_ID = 'auth/UPDATE_STORE_ID',
    UPDATE_FROM_LOCAL_STORAGE = 'auth/UPDATE_FROM_LOCAL_STORAGE',
    RESET_PASSWORD_PHONE_SUBMIT = 'auth/RESET_PASSWORD_PHONE_SUBMIT',
    RESET_PASSWORD_UPDATE_STAGE = 'auth/RESET_PASSWORD_UPDATE_STAGE',
    RESET_PASSWORD_OTP_SUBMIT = 'auth/RESET_PASSWORD_OTP_SUBMIT',
    RESET_PASSWORD_PASSWORD_SUBMIT = 'auth/RESET_PASSWORD_PASSWORD_SUBMIT',
    RESET_PASSWORD_UPDATE_TIME_LEFT = 'auth/RESET_PASSWORD_UPDATE_TIME_LEFT',
    RESET_PASSWORD_RESEND_CODE = 'auth/RESET_PASSWORD_RESEND_CODE',
    RESET_STORE_INFO = 'auth/RESET_STORE_INFO',
    SET_FULL_STORE = 'auth/SET_FULL_STORE',
    SET_MARKETING_DOCS = 'auth/SET_MARKETING_DOCS',
    GET_MARKETING_DOCS = 'auth/GET_MARKETING_DOCS',
    SET_ROLES = 'auth/SET_ROLES',
    SET_FAKE_STATUS = 'auth/SET_FAKE_STATUS',
    LOGIN_AS_DIFFERENT_USER_REQUEST = 'auth/LOGIN_AS_DIFFERENT_USER_REQUEST',
    CHANGE_OPEN_FOR_ORDERS = 'auth/CHANGE_OPEN_FOR_ORDERS',
}

export interface BaseAuthAction {
    type: AuthActionType;
}

export interface AuthLoginRequestAction extends BaseAuthAction {
    type: AuthActionType.LOGIN_REQUEST;
    payload: {
        role: UserRole;
        username: string;
        password: string;
    };
}

export interface SetMarketingDocsAction extends BaseAuthAction {
    type: AuthActionType.SET_MARKETING_DOCS;
    payload: {
        docs: { id: string; url: string; name: string }[];
    };
}

export interface GetMarketingDocsAction extends BaseAuthAction {
    type: AuthActionType.GET_MARKETING_DOCS;
}

export interface AuthUpdateFromLocalStorage extends BaseAuthAction {
    type: AuthActionType.UPDATE_FROM_LOCAL_STORAGE;
}

export interface AuthLoginSuccessAction extends BaseAuthAction {
    type: AuthActionType.LOGIN_SUCCESS;
    payload: {
        phone: string;
        role: UserRole;
    };
}

export interface AuthLoginFailureAction extends BaseAuthAction {
    type: AuthActionType.LOGIN_FAILURE;
}

export interface AuthResetPasswordPhoneSubmitAction extends BaseAuthAction {
    type: AuthActionType.RESET_PASSWORD_PHONE_SUBMIT;
    payload: {
        username: string;
    };
}

export interface AuthResetPasswordUpdateStageAction extends BaseAuthAction {
    type: AuthActionType.RESET_PASSWORD_UPDATE_STAGE;
    payload: {
        stage: ResetPasswordStage;
    };
}

export interface AuthResetPasswordOTPSubmitAction extends BaseAuthAction {
    type: AuthActionType.RESET_PASSWORD_OTP_SUBMIT;
    payload: {
        otp: string;
    };
}

export interface AuthResetPasswordPasswordSubmitAction extends BaseAuthAction {
    type: AuthActionType.RESET_PASSWORD_PASSWORD_SUBMIT;
    payload: {
        password: string;
    };
}

export interface AuthResetPasswordUpdateTimeLeftAction extends BaseAuthAction {
    type: AuthActionType.RESET_PASSWORD_UPDATE_TIME_LEFT;
    payload: {
        timeLeft: number;
    };
}

export interface AuthResetPasswordResendCodeAction extends BaseAuthAction {
    type: AuthActionType.RESET_PASSWORD_RESEND_CODE;
}

export interface AuthResetStoreInfoAction extends BaseAuthAction {
    type: AuthActionType.RESET_STORE_INFO;
}

export interface AuthUpdateStoreIdAction extends BaseAuthAction {
    type: AuthActionType.UPDATE_STORE_ID;
    payload: {
        storeId: string;
        currentState?: StoreCurrentState;
        category?: StoreCategory;
        storeUrl?: string;
        openForOrders?: boolean;
        isoCode?: SupportedCountryCodes;
    };
}

export interface AuthSetFullStoreAction extends BaseAuthAction {
    type: AuthActionType.SET_FULL_STORE;
}

export interface AuthSetRolesAction extends BaseAuthAction {
    type: AuthActionType.SET_ROLES;
    payload: {
        roles: UserRole[];
        temporaryPass: boolean;
    };
}

export interface AuthLoginAsDifferentUserAction extends BaseAuthAction {
    type: AuthActionType.LOGIN_AS_DIFFERENT_USER_REQUEST;
    payload: {
        userId: string;
    };
}

export interface AuthChangeOpenForOrdersAction extends BaseAuthAction {
    type: AuthActionType.CHANGE_OPEN_FOR_ORDERS;
    payload: {
        openForOrders: boolean;
    };
}

export interface AuthSetFakeStatusAction extends BaseAuthAction {
    type: AuthActionType.SET_FAKE_STATUS;
    payload: {
        fake: boolean;
    };
}

export type AuthAction =
    | AuthLoginRequestAction
    | AuthLoginSuccessAction
    | AuthLoginFailureAction
    | AuthResetPasswordPhoneSubmitAction
    | AuthResetPasswordUpdateStageAction
    | AuthResetPasswordOTPSubmitAction
    | AuthResetPasswordPasswordSubmitAction
    | AuthResetPasswordUpdateTimeLeftAction
    | AuthResetPasswordResendCodeAction
    | AuthResetStoreInfoAction
    | AuthUpdateStoreIdAction
    | SetMarketingDocsAction
    | AuthSetFullStoreAction
    | AuthSetRolesAction
    | AuthSetFakeStatusAction
    | AuthLoginAsDifferentUserAction
    | AuthUpdateFromLocalStorage
    | AuthChangeOpenForOrdersAction;

export const makeAuthLoginRequestAction = (
    username: string,
    password: string,
    role: UserRole,
): AuthLoginRequestAction => ({
    type: AuthActionType.LOGIN_REQUEST,
    payload: {
        role,
        username,
        password,
    },
});

export const makeAuthLoginSuccessAction = (phone: string, role: UserRole): AuthLoginSuccessAction => ({
    type: AuthActionType.LOGIN_SUCCESS,
    payload: {
        phone,
        role,
    },
});

export const makeSetMarketingDocsAction = (
    docs: { id: string; url: string; name: string }[],
): SetMarketingDocsAction => ({
    type: AuthActionType.SET_MARKETING_DOCS,
    payload: {
        docs,
    },
});

export const getMarketingDocsAction = (): GetMarketingDocsAction => ({
    type: AuthActionType.GET_MARKETING_DOCS,
});

export const getAuthUpdateFromLocalStorageAction = (): AuthUpdateFromLocalStorage => ({
    type: AuthActionType.UPDATE_FROM_LOCAL_STORAGE,
});

export const makeAuthLoginFailureAction = (): AuthLoginFailureAction => ({
    type: AuthActionType.LOGIN_FAILURE,
});

export const makeResetPasswordPhoneSubmitAction = (username: string): AuthResetPasswordPhoneSubmitAction => ({
    type: AuthActionType.RESET_PASSWORD_PHONE_SUBMIT,
    payload: {
        username,
    },
});

export const makeResetPasswordUpdateStageAction = (stage: ResetPasswordStage): AuthResetPasswordUpdateStageAction => ({
    type: AuthActionType.RESET_PASSWORD_UPDATE_STAGE,
    payload: {
        stage,
    },
});

export const makeResetPasswordOTPSubmitAction = (otp: string): AuthResetPasswordOTPSubmitAction => ({
    type: AuthActionType.RESET_PASSWORD_OTP_SUBMIT,
    payload: {
        otp,
    },
});

export const makeResetPasswordPasswordSubmitAction = (password: string): AuthResetPasswordPasswordSubmitAction => ({
    type: AuthActionType.RESET_PASSWORD_PASSWORD_SUBMIT,
    payload: {
        password,
    },
});

export const makeResetPasswordUpdateTimeLeftAction = (timeLeft: number): AuthResetPasswordUpdateTimeLeftAction => ({
    type: AuthActionType.RESET_PASSWORD_UPDATE_TIME_LEFT,
    payload: {
        timeLeft,
    },
});

export const makeResetPasswordResendCodeAction = (): AuthResetPasswordResendCodeAction => ({
    type: AuthActionType.RESET_PASSWORD_RESEND_CODE,
});

export const makeResetStoreInfoAction = (): AuthResetStoreInfoAction => ({
    type: AuthActionType.RESET_STORE_INFO,
});

export const makeAuthUpdateStoreIdAction = (
    storeId: string,
    category?: StoreCategory,
    currentState?: StoreCurrentState,
    storeUrl?: string,
    openForOrders?: boolean,
    isoCode?: SupportedCountryCodes,
): AuthUpdateStoreIdAction => ({
    type: AuthActionType.UPDATE_STORE_ID,
    payload: {
        storeId,
        currentState,
        category,
        storeUrl,
        openForOrders,
        isoCode,
    },
});

export const makeAuthSetFullStoreAction = (): AuthSetFullStoreAction => ({
    type: AuthActionType.SET_FULL_STORE,
});

export const makeAuthSetRolesAction = (roles: UserRole[], temporaryPass: boolean): AuthSetRolesAction => ({
    type: AuthActionType.SET_ROLES,
    payload: {
        roles,
        temporaryPass,
    },
});

export const makeAuthLoginAsDifferentUserAction = (userId: string): AuthLoginAsDifferentUserAction => ({
    type: AuthActionType.LOGIN_AS_DIFFERENT_USER_REQUEST,
    payload: {
        userId,
    },
});

export const makeAuthChangeOpenForOrdersAction = (openForOrders: boolean): AuthChangeOpenForOrdersAction => ({
    type: AuthActionType.CHANGE_OPEN_FOR_ORDERS,
    payload: {
        openForOrders,
    },
});

export const makeAuthSetFakeStatusAction = (fake: boolean): AuthSetFakeStatusAction => ({
    type: AuthActionType.SET_FAKE_STATUS,
    payload: {
        fake,
    },
});
