import { captureException } from '@sentry/nextjs';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';

import {
    AdminActionType,
    GetReportItemDetailsAction,
    makeGetReportItemDetailsSuccessAction,
    makeGetReportSuccessAction,
} from './actions';
import { getRequestSaga } from '../rest/sagas';

import { Endpoint } from 'Endpoint';

export function* adminSaga(): SagaIterator {
    yield takeEvery(AdminActionType.GET_STORE_ENTRIES, getReportSaga);
    yield takeEvery(AdminActionType.GET_STORE_DETAILS, getReportItemDetailsSaga);
}

function* getReportSaga(): SagaIterator {
    const { data: stores } = yield call(getRequestSaga, Endpoint.ADMIN_REPORT);
    yield put(makeGetReportSuccessAction(stores.stores));
}

function* getReportItemDetailsSaga(action: GetReportItemDetailsAction): SagaIterator {
    try {
        if (!action.storeEntry) {
            return;
        }
        const storeId = action.storeEntry.storeid;
        const { data: yaCheckoutApp } = yield call(getRequestSaga, Endpoint.REGISTRATION_YANDEX_CHECKOUT, { storeId });
        const { data: store } = yield call(getRequestSaga, Endpoint.STORE, { storeId });
        const { data: subscription } = yield call(getRequestSaga, Endpoint.VENDOR_SUBSCRIBE, {}, {}, { storeId });
        const { data: metrics } = yield call(getRequestSaga, Endpoint.STORE_METRICS, {}, {}, { storeId });

        yield put(
            makeGetReportItemDetailsSuccessAction({
                ...store,
                storeId,
                subscription,
                storeName: action.storeEntry.storename,
                yaCheckoutApp: yaCheckoutApp,
                metrics,
            }),
        );
    } catch (e) {
        captureException(e);
    }
}
