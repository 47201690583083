import { VendorMenuAction, VendorMenuActionType } from './actions';

export interface VendorMenuState {
    showMenu: boolean;
}

export interface VendorMenuSlice {
    vendorMenu: VendorMenuState;
}

const initialVendorMenuState: VendorMenuState = {
    showMenu: false,
};

export const vendorMenuReducer = (state = initialVendorMenuState, action: VendorMenuAction): VendorMenuState => {
    switch (action.type) {
        case VendorMenuActionType.SHOW:
            return {
                ...state,
                showMenu: true,
            };
        case VendorMenuActionType.HIDE:
            return {
                ...state,
                showMenu: false,
            };
        default:
            return state;
    }
};
