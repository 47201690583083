import { captureException } from '@sentry/nextjs';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { LocalStorageKey, removeLocalStorageItem, setLocalStorageItem } from 'core/storage/storage';

import {
    makeAuthLoginSuccessAction,
    makeAuthSetFakeStatusAction,
    makeAuthSetRolesAction,
    makeAuthUpdateStoreIdAction,
    makeResetStoreInfoAction,
} from 'state/auth/actions';
import { makeAppUIDisableAction, makeAppUIEnableAction } from 'state/app/actions';
import {
    formPostRequestSaga,
    formPutRequestSaga,
    getRequestSaga,
    handleFormErrorSaga,
    postRequestSaga,
    putRequestSaga,
} from 'state/rest/sagas';
import { storeDetailsSubmitImageSaga } from 'state/storeDetails/sagas';
import { authPhoneNumberSelector, authStoreIdSelector, isAuthUserFakeStatusSelector } from 'state/auth/selectors';
import { subscriptionFetchSaga } from 'state/subscription/sagas';

import { OnboardingScreen, StoreFormData } from 'pages/Onboarding/types';

import { Endpoint } from 'Endpoint';

import { Form } from 'forms/types';
import { ImageDto, ImageType, NotificationType, StoreDto, UserRole, VendorContactType } from 'types';

import {
    makeOnboardingFetchStoreSuccessAction,
    makeOnboardingSetScreenAction,
    makeOnboardingSubmitRequestAction,
    OnboardingActionType,
    OnboardingSaveStoreFormDataAction,
    OnboardingSetPasswordAction,
    OnboardingSubmitSellerFormRequestAction,
} from './actions';
import { onboardingSellerFormDataSelector, onboardingStoreFormDataSelector } from './selectors';
import { whoamiSaga } from '../app/sagas';
import { setJwt, setRole } from 'core/auth/auth';
import { setUserData } from 'state/auth/sagas';
import { updateSyncErrors } from 'redux-form';
import { GalleryItem } from 'components/Gallery/types';
import { makeSnackbarErrorAction } from '../snackbar/actions';

export function* onboardingSaga(): SagaIterator<void> {
    yield takeEvery(OnboardingActionType.SUBMIT_SELLER_FORM_REQUEST, onboardingSubmitSellerSaga);
    yield takeEvery(OnboardingActionType.FETCH_STORE, onboardingFetchStoreSaga);
    yield takeEvery(OnboardingActionType.SAVE_STORE_FORM_DATA, onboardingSaveStoreFormDataSaga);
    yield takeEvery(OnboardingActionType.SUBMIT_STORE_FORM_REQUEST, onboardingSubmitStoreSaga);
    yield takeEvery(OnboardingActionType.FINISH, onboardingFinishSaga);
    yield takeEvery(OnboardingActionType.SET_PASSWORD, onboardingSetPasswordSaga);
    yield takeEvery(OnboardingActionType.SKIP_PASSWORD, onboardingSkipPasswordSaga);
    yield takeEvery(OnboardingActionType.INIT_FAKE_STORE, onboardingInitFakeStoreSaga);
}

function* onboardingSubmitSellerSaga(action: OnboardingSubmitSellerFormRequestAction): SagaIterator<void> {
    const { phoneNumber, jwt, userAlreadyExists } = action.payload;

    setJwt(jwt);
    setRole(UserRole.VENDOR);
    setLocalStorageItem(LocalStorageKey.PHONE, phoneNumber);

    yield put(makeAuthLoginSuccessAction(phoneNumber, UserRole.VENDOR));
    yield put(makeAuthSetRolesAction([UserRole.VENDOR], true));
    if (!userAlreadyExists) {
        const { name, email } = yield select(onboardingSellerFormDataSelector);
        yield call(
            formPutRequestSaga,
            Form.PROFILE_FORM,
            Endpoint.PROFILE,
            {},
            {
                name,
                email,
            },
        );

        yield call(updateOnboardingStep, OnboardingScreen.STORE);
    } else {
        window.location.reload();
    }
}

function* onboardingFetchStoreSaga(): SagaIterator<void> {
    const storeId = yield select(authStoreIdSelector);
    const isFakeUser = yield select(isAuthUserFakeStatusSelector);
    try {
        if (storeId && !isFakeUser) {
            const response: AxiosResponse<StoreDto> = yield call(getRequestSaga, Endpoint.STORE, {
                storeId,
            });
            const store = response.data;
            const formData: StoreFormData = {
                coverImage: store.coverImage?.imageId as any,
                name: store.name,
                address: store.address.formattedAddress,
                coordinates: {
                    lat: store.address.geoCoordinates.latitude,
                    lon: store.address.geoCoordinates.longitude,
                },
                storeCategory: store.storeCategory,
                phone: store.contacts.find(c => c.type === VendorContactType.PHONE)?.value ?? '',
                customUrl: store.customUrl,
                imageCrop: null,
                waPhone: !!store.contacts.find(c => c.type === VendorContactType.WA),
                standardAddress: store.address,
            };
            yield put(makeOnboardingFetchStoreSuccessAction(formData));
        }
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('onboarding:errors.user'));
    }
}

function* onboardingSaveStoreFormDataSaga(action: OnboardingSaveStoreFormDataAction): SagaIterator<void> {
    const { skipPhoneVerification } = action.meta;
    if (skipPhoneVerification) {
        yield put(makeOnboardingSubmitRequestAction());
    }
}

function* onboardingSubmitStoreSaga(): SagaIterator<void> {
    try {
        const storeFormData = yield select(onboardingStoreFormDataSelector);

        yield put(makeAppUIDisableAction());

        if (storeFormData.gallery) {
            for (let galleryItem of storeFormData?.gallery) {
                if (galleryItem.file) {
                    galleryItem.imageId = yield call(
                        storeDetailsSubmitImageSaga,
                        galleryItem.file,
                        galleryItem.croppedArea,
                    );
                }
            }
        }

        let coverImageDto: ImageDto;
        if (storeFormData.coverImage) {
            // @ts-ignore
            const imageId = yield call(
                storeDetailsSubmitImageSaga,
                storeFormData.coverImage,
                storeFormData.imageCrop,
                ImageType.STORE_COVER,
            );
            coverImageDto = {
                imageId,
                type: ImageType.STORE_COVER,
                defaultImage: false,
            };
        }
        const body = {
            address: {
                ...storeFormData.standardAddress,
                geoCoordinates: {
                    latitude: storeFormData.coordinates.lat,
                    longitude: storeFormData.coordinates.lon,
                },
            },
            contacts: [
                {
                    defaultContact: true,
                    type: VendorContactType.PHONE,
                    value: storeFormData.phone,
                },
                {
                    type: VendorContactType.WA,
                    value: storeFormData.waPhone ? storeFormData.phone : null,
                },
            ].filter(item => item.value),
            coverImage: coverImageDto,
            name: storeFormData.name,
            storeCategory: storeFormData.storeCategory,
            customUrl: storeFormData.customUrl,
            domain: storeFormData.domain,
            carouselData: ((storeFormData?.gallery || []) as GalleryItem[]).map((galleryItem, index) => ({
                images: {
                    360: galleryItem.imageId,
                    480: galleryItem.imageId,
                    640: galleryItem.imageId,
                    768: galleryItem.imageId,
                    960: galleryItem.imageId,
                    1280: galleryItem.imageId,
                    1920: galleryItem.imageId,
                },
                url: null,
                description: galleryItem.label,
                order: index + 1,
                styles: {
                    color: galleryItem.color,
                    textAlign: galleryItem.horizontalAlignment,
                    justifyContent: galleryItem.verticalAlignment,
                },
            })),
        };

        const notificationSettings = [
            {
                enabled: storeFormData?.smsNotification || false,
                notificationMethod: NotificationType.PHONE,
                type: 'NEW_ORDER',
            },
            {
                enabled: storeFormData?.emailNotification || false,
                notificationMethod: NotificationType.EMAIL,
                type: 'NEW_ORDER',
            },
        ];

        const { data: storeId } = yield call(
            formPostRequestSaga,
            Form.STORE_DETAILS_FORM,
            Endpoint.REGISTRATION_STORE_DETAILS,
            {},
            body,
        );
        yield call(
            postRequestSaga,
            Endpoint.STORE_NOTIFICATION_SETTINGS,
            { storeId: storeId },
            { storeId: storeId, notificationSettings },
        );
        yield put(
            makeAuthUpdateStoreIdAction(
                storeId,
                storeFormData.storeCategory,
                null,
                storeFormData.customUrl,
                true,
                storeFormData.standardAddress.isoCode,
            ),
        );
        yield call(updateOnboardingStep, OnboardingScreen.CATALOG);
    } catch (e) {
        captureException(e);
        const form = Form.ONBOARDING_STORE_FORM;
        yield call(handleFormErrorSaga, form, e);
        if (e.isAxiosError && e.response) {
            const { data, status } = e.response;
            if (status === 400 && data && data.errors) {
                // processing errors
                //show first error
                const controlNameWithError = Object.keys(data.errors)[0];
                if (controlNameWithError) {
                    yield put(
                        updateSyncErrors(
                            form,
                            {},
                            `${getOnboardingFieldNameFromError(controlNameWithError)} ${
                                data.errors[controlNameWithError][0].message
                            }`,
                        ),
                    );
                }
            }
        }
    } finally {
        yield put(makeAppUIEnableAction());
    }
}

function* onboardingFinishSaga(): SagaIterator<void> {
    try {
        yield call(whoamiSaga);
        const isFakeRegistration = yield select(isAuthUserFakeStatusSelector);
        if (isFakeRegistration) {
            const storeId = yield select(authStoreIdSelector);
            yield call(postRequestSaga, Endpoint.QUALIFY_STORE_AS_SIMPLE, { storeId });
        }
        yield call(updateOnboardingStep, OnboardingScreen.SUCCESS);
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('onboarding:errors.user'));
    }
}

export function* updateOnboardingStep(onboardingScreen: OnboardingScreen): SagaIterator<void> {
    try {
        yield put(makeOnboardingSetScreenAction(onboardingScreen));
        const onboardingStep = getOnboardingStepFromScreen(onboardingScreen);
        yield call(postRequestSaga, Endpoint.ONBOARDING_STEP, {}, { onboardingStep });
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('onboarding:errors.page'));
    }
}

function* onboardingSetPasswordSaga(action: OnboardingSetPasswordAction): SagaIterator<void> {
    const { password } = action.payload;
    try {
        yield put(makeAppUIDisableAction());
        const username = yield select(authPhoneNumberSelector);
        yield call(putRequestSaga, Endpoint.RESET_PASSWORD, {}, { username, password });
        yield call(onboardingCompleteSaga);
        yield call(updateOnboardingStep, null);
        yield put(makeAppUIEnableAction());
    } catch (error) {
        captureException(error);
        yield put(makeSnackbarErrorAction('onboarding:errors.password'));
    }
}

function* onboardingSkipPasswordSaga(): SagaIterator<void> {
    try {
        yield put(makeAppUIDisableAction());
        yield call(updateOnboardingStep, null);
        yield call(onboardingCompleteSaga);
        yield put(makeAppUIEnableAction());
    } catch (error) {
        captureException(error);
        yield put(makeSnackbarErrorAction('onboarding:errors.password'));
    }
}

function* onboardingCompleteSaga(): SagaIterator<void> {
    try {
        const storeId = yield select(authStoreIdSelector);
        const isFakeRegistration = yield select(isAuthUserFakeStatusSelector);
        if (!isFakeRegistration) {
            yield call(postRequestSaga, Endpoint.VENDOR_SUBSCRIPTION_TRIAL, { storeId });
            yield call(subscriptionFetchSaga);
        }
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('onboarding:errors.user'));
    }
}

function* onboardingInitFakeStoreSaga(): SagaIterator {
    try {
        yield put(makeAuthSetFakeStatusAction(true));
        yield put(makeResetStoreInfoAction());
        removeLocalStorageItem(LocalStorageKey.STORE_ID);
        const response: AxiosResponse = yield call(postRequestSaga, Endpoint.VENDOR_FAKE_USER, {}, {}, {});
        const role = UserRole.VENDOR;
        const username = 'fake';
        yield call(setUserData, response.headers.authorization, username, role);
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('onboarding:errors.user'));
    }
}

export const getOnboardingStepFromScreen = (onboardingScreen: OnboardingScreen): number => {
    switch (onboardingScreen) {
        case OnboardingScreen.SELLER:
        case OnboardingScreen.SELLER_PHONE_VERIFICATION:
            return 1;
        case OnboardingScreen.STORE:
        case OnboardingScreen.STORE_PHONE_VERIFICATION:
            return 2;
        case OnboardingScreen.CATALOG:
            return 3;
        case OnboardingScreen.SUCCESS:
            return 4;
        default:
            return 0;
    }
};

export const getOnboardingScreenFromStep = (onboardingStep: number): OnboardingScreen | null => {
    switch (onboardingStep) {
        case 0:
            return null;
        case 1:
            return OnboardingScreen.SELLER;
        case 2:
            return OnboardingScreen.STORE;
        case 3:
            return OnboardingScreen.CATALOG;
        case 4:
            return OnboardingScreen.SUCCESS;
        default:
            return OnboardingScreen.SELLER;
    }
};

export const getOnboardingFieldNameFromError = (controlNameWithError: string): string => {
    switch (controlNameWithError) {
        case 'address': {
            return 'Адрес -';
        }
        default: {
            return '';
        }
    }
};
