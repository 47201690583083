import { SnackbarMessageType } from './reducer';

export enum SnackbarActionType {
    CREATE = 'snackbar/CREATE',
    CLEAR = 'snackbar/CLEAR',
    OPEN = 'snackbar/OPEN',
    ERROR = 'snackbar/ERROR',
}

export interface SnackbarBaseAction {
    type: SnackbarActionType;
}

export interface SnackbarCreateAction extends SnackbarBaseAction {
    type: SnackbarActionType.CREATE;
    payload: {
        messageType: SnackbarMessageType;
        params: Record<string, any>;
    };
}

export interface SnackbarClearAction extends SnackbarBaseAction {
    type: SnackbarActionType.CLEAR;
}

export interface SnackbarOpenAction extends SnackbarBaseAction {
    type: SnackbarActionType.OPEN;
}

export interface SnackbarErrorAction extends SnackbarBaseAction {
    type: SnackbarActionType.ERROR;
    payload: {
        message: string;
    };
}

export type SnackbarAction = SnackbarCreateAction | SnackbarClearAction | SnackbarOpenAction | SnackbarErrorAction;

export const makeSnackbarCreateAction = (messageType: SnackbarMessageType, params: Record<string, any>) => ({
    type: SnackbarActionType.CREATE,
    payload: {
        messageType,
        params,
    },
});

export const makeSnackbarClearAction = () => ({
    type: SnackbarActionType.CLEAR,
});

export const makeSnackbarOpenAction = () => ({
    type: SnackbarActionType.OPEN,
});

export const makeSnackbarErrorAction = (message: string) => ({
    type: SnackbarActionType.ERROR,
    payload: {
        message,
    },
});
