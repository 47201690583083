import { captureException } from '@sentry/nextjs';
import { SagaIterator } from 'redux-saga';
import { call, put, retry, select, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import browserCookies from 'browser-cookies';

import { appConfig } from 'config/app';

import { getServerGeolocation, reverseGeocode } from 'core/maps/maps';
import { getLocalStorageItem, hasLocalStorageItem, LocalStorageKey, setLocalStorageItem } from 'core/storage/storage';

import {
    AppActionType,
    AppInitSellingAction,
    makeAppInitFailureAction,
    makeAppInitSellingFailureAction,
    makeAppInitSuccessAction,
    makeAppUIDisableAction,
    makeAppUIEnableAction,
} from './actions';
import { makeAuthSetFakeStatusAction, makeAuthSetRolesAction, makeAuthUpdateStoreIdAction } from 'state/auth/actions';
import {
    makeFeedSetAddressAction,
    makeFeedSetCoordinatesAction,
    makeFeedSetStandardAddressDataAction,
} from 'state/feed/actions';
import { AuthStatus } from 'state/auth/reducer';
import { getRequestSaga, postRequestSaga } from 'state/rest/sagas';
import { authRoleSelector, authStatusSelector } from 'state/auth/selectors';
import { makeRegistrationSetStageAction } from 'state/registration/actions';

import { Endpoint } from 'Endpoint';

import { StoreCurrentState, StoreDto, UserRole } from 'types';
import { subscriptionFetchSaga } from 'state/subscription/sagas';
import { makeOnboardingSetScreenAction } from '../onboarding/actions';
import { getOnboardingScreenFromStep, updateOnboardingStep } from '../onboarding/sagas';
import { makeCheckoutUpdateCartInfo } from 'state/checkout/actions';
import { setJwt, setRole } from 'core/auth/auth';
import { RoutePath } from 'RoutePath';
import { makeSnackbarErrorAction } from '../snackbar/actions';

const isServer = typeof window === 'undefined';

export function* uiBlockingSagaWrapper(saga: any, ...args: any[]): SagaIterator {
    try {
        yield put(makeAppUIDisableAction());
        return yield call(saga, ...args);
    } finally {
        yield put(makeAppUIEnableAction());
    }
}

export function* appSaga(): SagaIterator {
    const authStatus = yield select(authStatusSelector);
    const role = yield select(authRoleSelector);
    try {
        yield put(makeCheckoutUpdateCartInfo());
        yield takeEvery(AppActionType.INIT_SELLING, initSelling);
        if (authStatus === AuthStatus.LOGGED_IN) {
            yield call(initUserDataFromBackendSaga, role);
        } else if (!isServer) {
            yield takeEvery(AppActionType.INIT_APP, initGeolocation);
            yield call(initGeolocation);
        }
        yield put(makeAppInitSuccessAction());
    } catch (e) {
        captureException(e);
        yield put(makeAppInitFailureAction());
    }
}

export function* initUserDataFromBackendSaga(role: UserRole): SagaIterator {
    const phone = getLocalStorageItem(LocalStorageKey.PHONE);
    const address: { address: string; comment: string } = getLocalStorageItem(LocalStorageKey.ADDRESS);
    if (role === UserRole.VENDOR) {
        yield call(initOnboardingStep);
    }
    yield call(initStoreId);
    if (phone !== 'fake' && !address?.address) {
        yield call(initAddress);
    }
    yield call(whoamiSaga);
}

export function* initStoreId(): SagaIterator {
    const storeIdResponse: AxiosResponse = yield retry(3, 5e3, getRequestSaga, Endpoint.STORE_ID);
    const storeId = storeIdResponse.data.store;
    if (storeId) {
        const { data }: { data: StoreDto } = yield call(getRequestSaga, Endpoint.STORE, { storeId });
        yield call(subscriptionFetchSaga);
        yield put(
            makeAuthUpdateStoreIdAction(
                storeIdResponse.data.store,
                data.storeCategory,
                data.currentState,
                data.customUrl,
                data.openForOrders,
                data.address.isoCode,
            ),
        );
    }
}

function* initAddress(): SagaIterator {
    const { data } = yield retry(3, 5e3, getRequestSaga, Endpoint.ADDRESS);
    const defaultAddress = data.find((item: any) => item.defaultAddress);
    if (defaultAddress) {
        const { city, street, geoCoordinates, comment } = defaultAddress;
        if (city && street) {
            const address = city.name + ', ' + street.name;
            yield put(makeFeedSetAddressAction(address, comment, true));
            yield put(makeFeedSetStandardAddressDataAction(defaultAddress));
            const { latitude, longitude } = geoCoordinates;
            yield put(makeFeedSetCoordinatesAction(latitude, longitude));
        }
    } else {
        yield call(initGeolocation);
    }
}

function* updateAddress(lat: number, lon: number, city: string): SagaIterator {
    yield put(makeFeedSetCoordinatesAction(lat, lon));
    try {
        const { cityName, streetAddress } = yield call(reverseGeocode, lat, lon);
        yield put(makeFeedSetAddressAction(cityName + ', ' + streetAddress));
    } catch (e) {
        captureException(e);
        yield put(makeFeedSetAddressAction(city));
        // yield put(makeSnackbarErrorAction('feed:errors.address'));
    }
}

function* initGeolocation(): SagaIterator {
    if (
        hasLocalStorageItem(LocalStorageKey.ADDRESS) &&
        hasLocalStorageItem(LocalStorageKey.STANDARD_ADDRESS_DATA) &&
        browserCookies.get('country')
    ) {
        return;
    }

    try {
        const coords = yield call(getServerGeolocation);
        if (coords && coords.city) {
            yield call(updateAddress, coords.lat, coords.lon, coords.city);
        } else {
            yield call(
                updateAddress,
                appConfig.defaultCoordinates.lat,
                appConfig.defaultCoordinates.lon,
                appConfig.defaultCityName,
            );
        }
    } catch (e) {
        captureException(e);
        yield call(
            updateAddress,
            appConfig.defaultCoordinates.lat,
            appConfig.defaultCoordinates.lon,
            appConfig.defaultCityName,
        );
    }
    // https://3steps.atlassian.net/browse/ANY-887
    // yield spawn(function* () {
    //     try {
    //         const { coords } = yield call(getBrowserGeolocation);
    //         yield call(updateAddress, coords.latitude, coords.longitude);
    //         yield put(makeFeedGeolocationSuccessAction());
    //     } catch (e) {}
    // });
}

function* initSelling(action: AppInitSellingAction): SagaIterator {
    try {
        const { payload } = action;
        const { storeId, code } = payload;

        const { data }: { data: StoreDto } = yield call(getRequestSaga, Endpoint.STORE, {
            storeId,
        });
        if (data?.vendorId) {
            const response: AxiosResponse = yield call(
                postRequestSaga,
                Endpoint.VENDOR_SELLING,
                {},
                {},
                {},
                { from: data?.vendorId, code },
            );
            setJwt(response.headers.authorization);
            const { data: user } = yield call(getRequestSaga, Endpoint.USER_WHOAMI);
            setRole(UserRole.VENDOR);
            setLocalStorageItem(LocalStorageKey.PHONE, user.username);
            yield call(initUserDataFromBackendSaga, UserRole.VENDOR);
            if (data.currentState === StoreCurrentState.SELLING) {
                yield call(postRequestSaga, Endpoint.VENDOR_SUBSCRIPTION_TRIAL, { storeId });
            }
            yield call(updateOnboardingStep, 0);
            window.location.assign(RoutePath.ORDERS_NEW);
        }
    } catch (e) {
        captureException(e);
        yield put(makeAppInitSellingFailureAction(e?.response?.data?.errors?.generic[0]?.message || ''));
    }
}

function* initOnboardingStep(): SagaIterator {
    try {
        const stepResponse: AxiosResponse = yield retry(3, 5e3, getRequestSaga, Endpoint.ONBOARDING_STEP);
        const checklistJson = stepResponse?.data?.checklistJson && JSON.parse(atob(stepResponse.data.checklistJson));
        yield put(makeRegistrationSetStageAction(stepResponse.data.onboardingStep, checklistJson));

        const onboardingScreen = getOnboardingScreenFromStep(stepResponse.data.onboardingStep);
        yield put(makeOnboardingSetScreenAction(onboardingScreen));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('feed:errors.error'));
    }
}

export function* whoamiSaga(): SagaIterator {
    const response = yield call(getRequestSaga, Endpoint.USER_WHOAMI);
    yield put(makeAuthSetRolesAction(response.data.roles, response.data.temporaryPass));
    yield put(makeAuthSetFakeStatusAction(response.data.fake));
}
