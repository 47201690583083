import { createMuiTheme } from '@material-ui/core';

declare module '@material-ui/core/styles/createPalette' {
    interface PaletteNav {
        primary: string;
        secondary: string;
    }

    interface PaletteBrand {
        mainHover: string;
        main: string;
        light: string;
        accent: string;
        lightest: string;
    }

    interface PaletteFixed {
        primary: string;
        secondary: string;
        light: string;
        lighter: string;
        lightest: string;
    }

    interface PaletteStatus {
        mainHover: string;
        main: string;
        accent: string;
    }

    // noinspection JSUnusedGlobalSymbols
    interface Palette {
        nav: PaletteNav;
        brandPrimary: PaletteBrand;
        brandSecondary: PaletteBrand;
        fixedPrimary: PaletteFixed;
        fixedWhite: PaletteFixed;
        fixedSuccess: PaletteBrand;
        fixedWarning: PaletteStatus;
        fixedCritical: PaletteStatus;
        fixedDanger: PaletteStatus;
        fixedPromo: PaletteStatus;
        fixedPromoGreen: PaletteStatus;
        fixedArrowGreen: PaletteStatus;
        fixedActive: PaletteStatus;
        commercialOffer: PaletteStatus;
    }

    // noinspection JSUnusedGlobalSymbols
    interface PaletteOptions {
        nav: PaletteNav;
        brandPrimary: PaletteBrand;
        brandSecondary: PaletteBrand;
        fixedPrimary: PaletteFixed;
        fixedWhite: PaletteFixed;
        fixedSuccess: PaletteBrand;
        fixedWarning: PaletteStatus;
        fixedCritical: PaletteStatus;
        fixedDanger: PaletteStatus;
        fixedPromo: PaletteStatus;
        fixedPromoGreen: PaletteStatus;
        fixedArrowGreen: PaletteStatus;
        fixedActive: PaletteStatus;
        commercialOffer: PaletteStatus;
    }
}

const palette = {
    primary: {
        main: '#28024a',
    },
    nav: {
        primary: '#28024a',
        secondary: '#694a85',
    },
    brandPrimary: {
        mainHover: 'rgba(15, 24, 37, 0.85)',
        main: '#0f1825',
        light: 'rgba(15, 24, 37, 0.3)',
        accent: 'rgba(15, 24, 37, 0.1)',
        lightest: 'rgba(15, 24, 37, 0.05)',
    },
    brandSecondary: {
        mainHover: 'rgba(64, 139, 234, 0.85)',
        main: '#2697ff',
        light: 'rgba(64, 139, 234, 0.3)',
        accent: 'rgba(64, 139, 234, 0.15)',
        lightest: 'rgba(64, 139, 234, 0.05)',
    },
    fixedPrimary: {
        primary: '#243143',
        secondary: 'rgba(36, 49, 67, 0.8)',
        light: 'rgba(36, 49, 67, 0.6)',
        lighter: 'rgba(36, 49, 67, 0.4)',
        lightest: 'rgba(36, 49, 67, 0.2)',
    },
    fixedWhite: {
        primary: '#fff',
        secondary: 'rgba(255, 255, 255, 0.8)',
        light: 'rgba(255, 255, 255, 0.6)',
        lighter: 'rgba(255, 255, 255, 0.4)',
        lightest: 'rgba(255, 255, 255, 0.2)',
    },
    fixedSuccess: {
        mainHover: 'rgb(95, 165, 52)',
        main: 'rgba(117, 195, 69, 1)',
        light: 'rgba(117, 195, 69, 0.3)',
        accent: 'rgba(117, 195, 69, 0.15)',
        lightest: 'rgba(117, 195, 69, 0.05)',
    },
    fixedWarning: {
        main: '#ffa310',
        accent: 'rgba(255, 163, 16, 0.15)',
    },
    fixedCritical: {
        main: '#ff7200',
        accent: 'rgba(255, 114, 0, 0.15)',
    },
    fixedDanger: {
        main: '#ea1103',
        accent: 'rgba(234, 17, 3, 0.15)',
    },
    fixedPromo: {
        main: '#a649fd',
        accent: 'rgba(166, 73, 253, 0.15)',
    },
    fixedPromoGreen: {
        main: '#D7F8C3',
        accent: 'rgba(215, 248, 195, 0.15)',
    },
    fixedArrowGreen: {
        mainHover: 'rgba(36, 49, 67, 0.4)',
        main: '#75C445',
        accent: 'rgba(215, 248, 195, 0.15)',
    },
    fixedActive: {
        main: 'rgba(38, 151, 255, 0.1)',
        accent: 'rgba(38, 151, 255, 0.1)',
    },
    commercialOffer: {
        main: 'rgba(255, 113, 113, 1)',
        accent: 'rgba(255, 113, 113, 0.2)',
    },
};

export const makeTheme = () =>
    createMuiTheme({
        palette,
        typography: {
            fontFamily: 'Inter, sans-serif',
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 640,
                md: 960,
                lg: 1280,
                xl: 1920,
            },
        },
        overrides: {
            MuiInputBase: {
                root: {
                    backgroundColor: palette.brandPrimary.lightest,
                    borderTopLeftRadius: 6,
                    borderTopRightRadius: 6,
                    borderBottomLeftRadius: 6,
                    borderBottomRightRadius: 6,
                    paddingLeft: 6,
                },
            },
            MuiFilledInput: {
                root: {
                    backgroundColor: palette.brandPrimary.lightest,
                    borderTopLeftRadius: 6,
                    borderTopRightRadius: 6,
                    borderBottomLeftRadius: 6,
                    borderBottomRightRadius: 6,
                },
            },
            MuiInputLabel: {
                root: {
                    '&$filled$marginDense': {
                        transform: 'translate(12px, 15px)',
                    },
                },
            },
        },
    });
