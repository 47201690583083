import { OrderStatus } from 'types';

export interface ChangedOrder {
    orderId: string;
    orderStatus: OrderStatus;
    totalPrice: number;
}

export const findChangedOrder = <T extends ChangedOrder>(previousOrders: T[], orders: T[]): T => {
    const previousOrderStatuses: Record<string, OrderStatus> = previousOrders.reduce(
        (acc, order) => ({
            ...acc,
            [order.orderId]: order.orderStatus,
        }),
        {},
    );
    for (const order of orders) {
        const previousStatus = previousOrderStatuses[order.orderId];
        if (order.orderStatus !== previousStatus) {
            return order;
        }
    }
    return null;
};
