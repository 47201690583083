import { ProfileFormData } from 'forms/ProfileForm/ProfileForm';
import { OrderListResponse, OrderResponse } from '../order/types';
import { OrderChangeStatusIntent, StoreNotificationDto } from 'types';
import { UserMenuSelectedItem } from './reducer';
import { StoreDetailsSubmitRequest } from '../storeDetails/types';
import { AddressListItemData } from './types';
import { AddressFormData } from 'forms/AddressForm/AddressForm';

export enum UserMenuActionType {
    SHOW = 'userMenu/SHOW',
    HIDE = 'userMenu/HIDE',
    SUBMIT_PROFILE = 'userMenu/SUBMIT_PROFILE',
    FETCH_PROFILE = 'userMenu/FETCH_PROFILE',
    FETCH_PROFILE_SUCCESS = 'userMenu/FETCH_PROFILE_SUCCESS',
    FETCH_ORDERS = 'userMenu/FETCH_ORDERS',
    FETCH_ORDERS_SUCCESS = 'userMenu/FETCH_ORDERS_SUCCESS',
    FETCH_ADDRESSES = 'userMenu/FETCH_ADDRESSES',
    FETCH_ADDRESSES_SUCCESS = 'userMenu/FETCH_ADDRESSES_SUCCESS',
    FETCH_ADDRESS = 'userMenu/FETCH_ADDRESS',
    FETCH_ADDRESS_SUCCESS = 'userMenu/FETCH_ADDRESS_SUCCESS',
    SUBMIT_ADDRESS = 'userMenu/SUBMIT_ADDRESS',
    DELETE_ADDRESS = 'userMenu/DELETE_ADDRESS',
    FETCH_ORDER = 'userMenu/FETCH_ORDER',
    FETCH_ORDER_SUCCESS = 'userMenu/FETCH_ORDER_SUCCESS',
    FETCH_STORE_INFO = 'userMenu/FETCH_STORE_INFO',
    FETCH_STORE_INFO_SUCCESS = 'userMenu/FETCH_STORE_INFO_SUCCESS',
    FETCH_STORE_NOTIFICATION_SETTINGS_SUCCESS = 'userMenu/FETCH_STORE_NOTIFICATION_SETTINGS_SUCCESS',
    CLEAR_STORE_INFO = 'userMenu/CLEAR_STORE_INFO',
    UPDATE_STORE_INFO = 'userMenu/UPDATE_STORE_INFO',
    ORDERS_ACTIVATE = 'userMenu/ORDERS_ACTIVATE',
    ORDERS_DEACTIVATE = 'userMenu/ORDERS_DEACTIVATE',
    ORDER_ACTIVATE = 'userMenu/ORDER_ACTIVATE',
    ORDER_DEACTIVATE = 'userMenu/ORDER_DEACTIVATE',
    CHANGE_STATUS = 'userMenu/CHANGE_STATUS',
    NAVIGATE = 'userMenu/NAVIGATE',
}

export interface UserMenuBaseAction {
    type: UserMenuActionType;
}

export interface UserMenuShowAction extends UserMenuBaseAction {
    type: UserMenuActionType.SHOW;
}

export interface UserMenuHideAction extends UserMenuBaseAction {
    type: UserMenuActionType.HIDE;
}

export interface UserMenuProfileSubmitAction extends UserMenuBaseAction {
    type: UserMenuActionType.SUBMIT_PROFILE;
    payload: ProfileFormData;
}

export interface UserMenuProfileFetchAction extends UserMenuBaseAction {
    type: UserMenuActionType.FETCH_PROFILE;
}

export interface UserMenuProfileFetchSuccessAction extends UserMenuBaseAction {
    type: UserMenuActionType.FETCH_PROFILE_SUCCESS;
    payload: ProfileFormData;
}

export interface UserMenuOrdersFetchAction extends UserMenuBaseAction {
    type: UserMenuActionType.FETCH_ORDERS;
}

export interface UserMenuOrdersFetchSuccessAction extends UserMenuBaseAction {
    type: UserMenuActionType.FETCH_ORDERS_SUCCESS;
    payload: {
        orders: OrderListResponse;
    };
}

export interface UserMenuAddressesFetchAction extends UserMenuBaseAction {
    type: UserMenuActionType.FETCH_ADDRESSES;
}

export interface UserMenuAddressesFetchSuccessAction extends UserMenuBaseAction {
    type: UserMenuActionType.FETCH_ADDRESSES_SUCCESS;
    payload: {
        addresses: AddressListItemData[];
    };
}

export interface UserMenuAddressSubmitAction extends UserMenuBaseAction {
    type: UserMenuActionType.SUBMIT_ADDRESS;
    payload: AddressFormData;
}

export interface UserMenuAddressDeleteAction extends UserMenuBaseAction {
    type: UserMenuActionType.DELETE_ADDRESS;
}

export interface UserMenuAddressFetchAction extends UserMenuBaseAction {
    type: UserMenuActionType.FETCH_ADDRESS;
}

export interface UserMenuAddressFetchSuccessAction extends UserMenuBaseAction {
    type: UserMenuActionType.FETCH_ADDRESS_SUCCESS;
    payload: {
        address: AddressFormData;
    };
}

export interface UserMenuOrderFetchAction extends UserMenuBaseAction {
    type: UserMenuActionType.FETCH_ORDER;
    payload: {
        orderId: string;
    };
}

export interface UserMenuOrderFetchSuccessAction extends UserMenuBaseAction {
    type: UserMenuActionType.FETCH_ORDER_SUCCESS;
    payload: {
        order: OrderResponse;
    };
}

export interface UserMenuStoreInfoFetchAction extends UserMenuBaseAction {
    type: UserMenuActionType.FETCH_STORE_INFO;
}

export interface UserMenuStoreInfoFetchSuccessAction extends UserMenuBaseAction {
    type: UserMenuActionType.FETCH_STORE_INFO_SUCCESS;
    payload: {
        storeInfo: StoreDetailsSubmitRequest;
    };
}

export interface UserMenuStoreNotificationSettingsFetchSuccessAction extends UserMenuBaseAction {
    type: UserMenuActionType.FETCH_STORE_NOTIFICATION_SETTINGS_SUCCESS;
    payload: {
        storeNotifications: StoreNotificationDto[];
    };
}

export interface UserMenuStoreInfoUpdateAction extends UserMenuBaseAction {
    type: UserMenuActionType.UPDATE_STORE_INFO;
    payload: {
        storeInfo: StoreDetailsSubmitRequest;
    };
}

export interface UserMenuOrderChangeStatusAction extends UserMenuBaseAction {
    type: UserMenuActionType.CHANGE_STATUS;
    payload: {
        orderId: string;
        intent: OrderChangeStatusIntent;
    };
}

export interface UserMenuNavigateAction extends UserMenuBaseAction {
    type: UserMenuActionType.NAVIGATE;
    payload: {
        selectedItem: UserMenuSelectedItem;
        params: Record<string, string>;
    };
}

export interface UserMenuStoreInfoClearAction extends UserMenuBaseAction {
    type: UserMenuActionType.CLEAR_STORE_INFO;
}

export type UserMenuAction =
    | UserMenuShowAction
    | UserMenuHideAction
    | UserMenuProfileSubmitAction
    | UserMenuProfileFetchAction
    | UserMenuProfileFetchSuccessAction
    | UserMenuOrdersFetchAction
    | UserMenuOrdersFetchSuccessAction
    | UserMenuOrderFetchAction
    | UserMenuOrderFetchSuccessAction
    | UserMenuOrderChangeStatusAction
    | UserMenuStoreInfoFetchAction
    | UserMenuStoreInfoFetchSuccessAction
    | UserMenuStoreNotificationSettingsFetchSuccessAction
    | UserMenuStoreInfoUpdateAction
    | UserMenuNavigateAction
    | UserMenuAddressesFetchAction
    | UserMenuAddressesFetchSuccessAction
    | UserMenuAddressFetchAction
    | UserMenuAddressFetchSuccessAction
    | UserMenuAddressSubmitAction
    | UserMenuAddressDeleteAction
    | UserMenuStoreInfoClearAction;

export const makeUserMenuShowAction = (): UserMenuShowAction => ({
    type: UserMenuActionType.SHOW,
});

export const makeUserMenuHideAction = (): UserMenuHideAction => ({
    type: UserMenuActionType.HIDE,
});

export const makeUserMenuProfileSubmitAction = (payload: ProfileFormData): UserMenuProfileSubmitAction => ({
    type: UserMenuActionType.SUBMIT_PROFILE,
    payload,
});

export const makeUserMenuProfileFetchAction = (): UserMenuProfileFetchAction => ({
    type: UserMenuActionType.FETCH_PROFILE,
});

export const makeUserMenuProfileFetchSuccessAction = (payload: ProfileFormData): UserMenuProfileFetchSuccessAction => ({
    type: UserMenuActionType.FETCH_PROFILE_SUCCESS,
    payload,
});

export const makeUserMenuOrdersFetchAction = (): UserMenuOrdersFetchAction => ({
    type: UserMenuActionType.FETCH_ORDERS,
});

export const makeUserMenuOrdersFetchSuccessAction = (orders: OrderListResponse): UserMenuOrdersFetchSuccessAction => ({
    type: UserMenuActionType.FETCH_ORDERS_SUCCESS,
    payload: {
        orders,
    },
});

export const makeUserMenuAddressesFetchAction = (): UserMenuAddressesFetchAction => ({
    type: UserMenuActionType.FETCH_ADDRESSES,
});

export const makeUserMenuAddressesFetchSuccessAction = (
    addresses: AddressListItemData[],
): UserMenuAddressesFetchSuccessAction => ({
    type: UserMenuActionType.FETCH_ADDRESSES_SUCCESS,
    payload: {
        addresses,
    },
});

export const makeUserMenuAddressSubmitAction = (payload: AddressFormData): UserMenuAddressSubmitAction => ({
    type: UserMenuActionType.SUBMIT_ADDRESS,
    payload,
});

export const makeUserMenuAddressFetchAction = (): UserMenuAddressFetchAction => ({
    type: UserMenuActionType.FETCH_ADDRESS,
});

export const makeUserMenuAddressFetchSuccessAction = (address: AddressFormData): UserMenuAddressFetchSuccessAction => ({
    type: UserMenuActionType.FETCH_ADDRESS_SUCCESS,
    payload: {
        address,
    },
});

export const makeUserMenuAddressDeleteAction = (): UserMenuAddressDeleteAction => ({
    type: UserMenuActionType.DELETE_ADDRESS,
});

export const makeUserMenuOrderFetchSuccessAction = (order: OrderResponse): UserMenuOrderFetchSuccessAction => ({
    type: UserMenuActionType.FETCH_ORDER_SUCCESS,
    payload: {
        order,
    },
});

export const makeUserMenuStoreInfoFetchAction = (): UserMenuStoreInfoFetchAction => ({
    type: UserMenuActionType.FETCH_STORE_INFO,
});

export const makeUserMenuStoreInfoClearAction = (): UserMenuStoreInfoClearAction => ({
    type: UserMenuActionType.CLEAR_STORE_INFO,
});

export const makeUserMenuStoreInfoFetchSuccessAction = (
    storeInfo: StoreDetailsSubmitRequest,
): UserMenuStoreInfoFetchSuccessAction => ({
    type: UserMenuActionType.FETCH_STORE_INFO_SUCCESS,
    payload: {
        storeInfo,
    },
});

export const makeUserMenuStoreNotificationSettingsFetchSuccessAction = (
    storeNotifications: StoreNotificationDto[],
): UserMenuStoreNotificationSettingsFetchSuccessAction => ({
    type: UserMenuActionType.FETCH_STORE_NOTIFICATION_SETTINGS_SUCCESS,
    payload: {
        storeNotifications,
    },
});

export const makeUserMenuStoreInfoUpdateAction = (
    storeInfo: StoreDetailsSubmitRequest,
): UserMenuStoreInfoUpdateAction => ({
    type: UserMenuActionType.UPDATE_STORE_INFO,
    payload: {
        storeInfo,
    },
});

export const makeUserMenuOrderChangeStatusAction = (
    orderId: string,
    intent: OrderChangeStatusIntent,
): UserMenuOrderChangeStatusAction => ({
    type: UserMenuActionType.CHANGE_STATUS,
    payload: {
        orderId,
        intent,
    },
});

export const makeUserMenuNavigateAction = (
    selectedItem: UserMenuSelectedItem,
    params?: Record<string, string>,
): UserMenuNavigateAction => ({
    type: UserMenuActionType.NAVIGATE,
    payload: {
        selectedItem,
        params,
    },
});
