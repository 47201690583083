import { captureException } from '@sentry/nextjs';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { redirectSaga } from 'core/sagas/sagas';

import { authStoreIdSelector } from '../auth/selectors';
import { PreviewActionType } from './actions';
import { getRequestSaga } from '../rest/sagas';

import { Endpoint } from '../../Endpoint';
import { RoutePath } from 'RoutePath';
import { makeSnackbarErrorAction } from '../snackbar/actions';

export function* previewSaga(): SagaIterator {
    yield takeEvery(PreviewActionType.NAVIGATE, previewNavigateSaga);
}

function* previewNavigateSaga(): SagaIterator {
    const storeId = yield select(authStoreIdSelector);
    try {
        const { data: store } = yield call(getRequestSaga, Endpoint.STORE, { storeId });
        const { customUrl } = store;
        if (customUrl) {
            yield call(redirectSaga, RoutePath.STORE, { storeSlug: customUrl });
        }
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('auth:errors.error'));
    }
}
