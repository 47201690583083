import { captureException } from '@sentry/nextjs';
import { SagaIterator } from 'redux-saga';
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';

import { removeQueryParams } from 'core/sagas/sagas';

import { makeAppUIDisableAction, makeAppUIEnableAction } from '../app/actions';
import {
    makeSubscriptionChangePaymentStatusAction,
    makeSubscriptionChangeRateDialogAction,
    makeSubscriptionFetchSuccessAction,
    makeSubscriptionSetIntentAction,
    SubscriptionActionType,
    SubscriptionRequestPriorityAction,
    SubscriptionResumeAction,
    SubscriptionSetIntentAction,
} from './actions';
import { getRequestSaga, postRequestSaga } from '../rest/sagas';
import { PaymentIntentType, SubscriptionPaymentStatus } from './reducer';
import { subscriptionIntentSelector, subscriptionPaymentStatusSelector } from './selectors';

import { Endpoint } from 'Endpoint';

import { PaymentMethodResponse, ShopTypeName, SubscriptionPlanResponse, SubscriptionResponse } from 'types';
import { initStoreId } from 'state/app/sagas';
import { makeSnackbarCreateAction, makeSnackbarErrorAction } from '../snackbar/actions';
import { SnackbarMessageType } from '../snackbar/reducer';
import { AxiosResponse } from 'axios';
import { LocalStorageKey, removeLocalStorageItem, setLocalStorageItem } from '../../core/storage/storage';
import { authIsoCodeSelector } from '../auth/selectors';

export function* subscriptionSaga(): SagaIterator {
    yield takeEvery(SubscriptionActionType.PAUSE, subscriptionPauseSaga);
    yield takeEvery(SubscriptionActionType.RESUME, subscriptionResumeSaga);
    yield takeEvery(SubscriptionActionType.CHANGE, subscriptionChangeSaga);
    yield takeEvery(SubscriptionActionType.REQUEST, subscriptionRequestPrioritySaga);
    yield takeEvery(SubscriptionActionType.SET_INTENT, subscriptionSetIntentSaga);
    yield call(waitForPaymentSaga);
}

export function* subscriptionFetchSaga(): SagaIterator {
    try {
        const subscriptionResponse = yield call(getRequestSaga, Endpoint.VENDOR_SUBSCRIBE);
        const subscription: SubscriptionResponse = subscriptionResponse.data;
        const paymentMethodResponse = yield call(getRequestSaga, Endpoint.VENDOR_PAYMENT_METHOD);
        const paymentMethod: PaymentMethodResponse = paymentMethodResponse.data;
        // Поменять получение countryCode, сейчас не работает :(
        const countryCode = yield select(authIsoCodeSelector);
        const subscriptionPlansResponse: AxiosResponse<SubscriptionPlanResponse[]> = yield call(
            getRequestSaga,
            Endpoint.VENDOR_SUBSCRIPTION_PLANS,
            {},
            {},
            { countryCode },
        );
        const subscriptionPlans: SubscriptionPlanResponse[] = subscriptionPlansResponse.data;
        yield put(makeSubscriptionFetchSuccessAction(subscription, paymentMethod, subscriptionPlans));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('common:errors.error'));
    }
}

function* subscriptionPauseSaga(): SagaIterator {
    try {
        yield put(makeAppUIDisableAction());
        yield call(postRequestSaga, Endpoint.VENDOR_SUBSCRIPTION_PAUSE);
        yield call(subscriptionFetchSaga);
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('common:errors.error'));
    } finally {
        yield put(makeAppUIEnableAction());
    }
}

function* subscriptionChangeSaga(): SagaIterator {
    yield put(makeSubscriptionSetIntentAction(PaymentIntentType.CHANGE, null));
    yield put(makeSubscriptionChangePaymentStatusAction(SubscriptionPaymentStatus.FORM));
}

function* subscriptionResumeSaga(action: SubscriptionResumeAction): SagaIterator {
    let subscriptionPlanId: string = null;
    try {
        yield put(makeAppUIDisableAction());
        const responseGetSubPlans: AxiosResponse<SubscriptionPlanResponse[]> = yield call(
            getRequestSaga,
            Endpoint.VENDOR_SUBSCRIPTION_PLANS,
        );

        subscriptionPlanId = responseGetSubPlans.data.find(el => el.name === action?.payload?.rate).subscriptionPlanId;

        const response = yield call(
            postRequestSaga,
            Endpoint.VENDOR_SUBSCRIPTION_RESUME,
            {},
            {},
            {},
            {
                subscriptionPlanId,
            },
        );

        if (response?.data?.activated) {
            yield call(subscriptionFetchSaga);
            yield put(makeSubscriptionChangeRateDialogAction(null));
        } else {
            yield put(
                makeSubscriptionSetIntentAction(
                    action?.payload?.rate === ShopTypeName.ONLINE_SHOP
                        ? PaymentIntentType.RESUME_ONLINE
                        : PaymentIntentType.RESUME_PRIORITY,
                    subscriptionPlanId,
                ),
            );
            yield put(makeSubscriptionChangePaymentStatusAction(SubscriptionPaymentStatus.FORM));
        }
        yield put(makeAppUIEnableAction());
    } catch {
        yield put(
            makeSubscriptionSetIntentAction(
                action?.payload?.rate === ShopTypeName.ONLINE_SHOP
                    ? PaymentIntentType.RESUME_ONLINE
                    : PaymentIntentType.RESUME_PRIORITY,
                subscriptionPlanId,
            ),
        );
        yield put(makeSubscriptionChangePaymentStatusAction(SubscriptionPaymentStatus.FORM));
        yield put(makeAppUIEnableAction());
    }
}

function* handleSubscriptionSuccessSaga(intentType: PaymentIntentType): SagaIterator {
    if (intentType === PaymentIntentType.RESUME_ONLINE || intentType === PaymentIntentType.RESUME_PRIORITY) {
        const nextSubscriptionPlan =
            intentType === PaymentIntentType.RESUME_ONLINE ? ShopTypeName.ONLINE_SHOP : ShopTypeName.PRIORITY;
        const responseGetSubPlans: AxiosResponse<SubscriptionPlanResponse[]> = yield call(
            getRequestSaga,
            Endpoint.VENDOR_SUBSCRIPTION_PLANS,
        );
        yield call(
            postRequestSaga,
            Endpoint.VENDOR_SUBSCRIPTION_RESUME,
            {},
            {},
            {},
            {
                subscriptionPlanId: responseGetSubPlans.data.find(el => el.name === nextSubscriptionPlan)
                    .subscriptionPlanId,
            },
        );
    }
    yield call(initStoreId);
    yield put(makeSubscriptionChangePaymentStatusAction(SubscriptionPaymentStatus.NONE));
}

function* subscriptionSetIntentSaga(params: SubscriptionSetIntentAction): SagaIterator {
    if (params?.payload) {
        setLocalStorageItem(LocalStorageKey.PAYMENT_INTENT, params.payload);
    } else {
        removeLocalStorageItem(LocalStorageKey.PAYMENT_INTENT);
    }
}

function* waitForPaymentSaga(): SagaIterator {
    const status = yield select(subscriptionPaymentStatusSelector);
    const intent = yield select(subscriptionIntentSelector);
    if (status === SubscriptionPaymentStatus.PENDING) {
        while (true) {
            try {
                const { data } = yield call(getRequestSaga, Endpoint.REGISTRATION_CHECK_PAYMENT);

                if (data.cancellation_details) {
                    const { party, reason } = data.cancellation_details;
                    if (party === 'merchant' && reason === 'canceled_by_merchant') {
                        yield call(handleSubscriptionSuccessSaga, intent.intentType);
                        break;
                    }
                }

                if (data.status === 'succeeded') {
                    yield call(handleSubscriptionSuccessSaga, intent.intentType);
                    break;
                } else if (data.status === 'waiting_for_capture' || data.status === 'pending') {
                    yield delay(2e3);
                } else {
                    yield put(makeSubscriptionChangePaymentStatusAction(SubscriptionPaymentStatus.FAILURE));
                    break;
                }
            } catch (e) {
                captureException(e);
                yield put(makeSubscriptionChangePaymentStatusAction(SubscriptionPaymentStatus.FAILURE));
                break;
            }
        }
        yield call(removeQueryParams);
    }
}
function* subscriptionRequestPrioritySaga(action: SubscriptionRequestPriorityAction): SagaIterator {
    try {
        yield put(makeAppUIDisableAction());
        const response: AxiosResponse<SubscriptionPlanResponse[]> = yield call(
            getRequestSaga,
            Endpoint.VENDOR_SUBSCRIPTION_PLANS,
        );
        yield call(postRequestSaga, Endpoint.REQUISITES, {}, action.payload.data);
        yield call(
            postRequestSaga,
            Endpoint.VENDOR_SUBSCRIPTION_REQUEST_PRIORITY,
            {},
            {},
            {},
            { subscriptionPlanId: response.data.find(el => el.name === action.payload.rate).subscriptionPlanId },
        );
        yield put(makeSnackbarCreateAction(SnackbarMessageType.SUBSCRIPTION_REQUEST, {}));
        yield put(makeAppUIEnableAction());
        yield put(makeSubscriptionChangeRateDialogAction(null));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('common:errors.error'));
        yield put(makeAppUIEnableAction());
        yield put(makeSubscriptionChangeRateDialogAction(null));
    }
}
