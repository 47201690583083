import { createSelector } from 'reselect';

import { OrderListFilterStatus } from './actions';
import { OrderSlice } from './reducer';

import { OrderStatus } from 'types';

const validOrderStatuses = Object.keys(OrderStatus);

const orderStatsSelector = (state: OrderSlice) => {
    return state.order.stats;
};

const orderNewListDataSelector = (state: OrderSlice) => {
    return state.order.lists[OrderListFilterStatus.NEW];
};

const orderActiveListDataSelector = (state: OrderSlice) => {
    return state.order.lists[OrderListFilterStatus.ACTIVE];
};

const orderClosedListDataSelector = (state: OrderSlice) => {
    return state.order.lists[OrderListFilterStatus.CLOSED];
};

export const orderNewCountSelector = createSelector(orderNewListDataSelector, orderStatsSelector, (data, stats) => {
    return (data && data.length) || stats.newOrders;
});

export const orderActiveCountSelector = createSelector(
    orderActiveListDataSelector,
    orderStatsSelector,
    (data, stats) => {
        return (data && data.length) || stats.activeOrders;
    },
);

export const orderClosedCountSelector = createSelector(
    orderClosedListDataSelector,
    orderStatsSelector,
    (data, stats) => {
        return (data && data.length) || stats.closedOrders;
    },
);

export const orderNewListSelector = createSelector(orderNewListDataSelector, data => {
    return (data || []).filter(item => validOrderStatuses.includes(item.orderStatus));
});

export const orderActiveListSelector = createSelector(orderActiveListDataSelector, data => {
    return (data || []).filter(item => validOrderStatuses.includes(item.orderStatus));
});

export const orderClosedListSelector = createSelector(orderClosedListDataSelector, data => {
    return (data || []).filter(item => validOrderStatuses.includes(item.orderStatus));
});

export const orderCurrentItemSelector = (state: OrderSlice) => {
    return state.order.currentItem;
};

export const orderErrorMessageSelector = (state: OrderSlice) => {
    return state.order.errorMessage;
};
