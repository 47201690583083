export enum Endpoint {
    LOGIN = '/api/login',
    STORE_ID = '/api/store',
    STORE = '/api/store/:storeId',
    STORE_WITH_COORDINATES = '/api/store/:storeId?latitude=:lat&longitude=:lon',
    STORE_BY_SLUG = '/api/store/custom/:slug',
    STORE_BY_SLUG_WITH_COORDINATES = '/api/store/custom/:slug?latitude=:lat&longitude=:lon',
    CHECK_RESET_PASSWORD_OTP = '/api/otp?otpType=RESET&otp=:otp&phoneNumber=:phone',
    RESET_PASSWORD_OTP = '/api/otp/reset',
    RESET_PASSWORD = '/api/users',
    SIGNUP_OTP = '/api/otp/registration?role=:role',
    SILENT_SIGNUP_OTP = '/api/otp/registration?role=:role&silent=1',
    SIGNUP_VENDOR = '/api/users/vendor',
    LOGIN_AS_DIFFERENT_USER = '/api/su?userId=:userId',

    FULLNESS_INFO = '/api/store/:storeId/widget/completeness',

    USER_WHOAMI = '/api/users/whoami',
    USER_INFO_BY_ID = '/api/users/:userId',

    ONBOARDING_STEP = '/api/vendor',

    GEOLOCATION = '/api/location/city',
    ADDRESS_AUTOCOMPLETE = '/api/address/autocomplete?input=:input&location=:location&sessiontoken=:token&radius=:radius&types=address',
    GET_ADDRESS = '/api/address/autocomplete/data?input=:input',
    VALIDATE_ADDRESS = '/api/address/geo',
    GET_STANDARD_ADDRESS = '/api/address/standard',
    CITY_AUTOCOMPLETE = '/api/address/autocomplete?input=:input&location=:location&sessiontoken=:token&radius=:radius&types=(cities)',
    CITY_COORDINATES = '/api/location/city/geo?city=:city',
    ADDRESS = '/api/users/addresses',
    ADDRESS_BY_ID = '/api/users/addresses/:addressId',

    IMAGE = '/api/images',
    IMAGE_BY_ID = '/api/images/:imageId',

    REGISTRATION_STORE_DETAILS = '/api/store',
    REGISTRATION_CATALOG_CATEGORIES = '/api/store/:storeId/categories',
    REGISTRATION_CATALOG_PRODUCT = '/api/store/:storeId/categories/:categoryId/products',
    REGISTRATION_SETTINGS = '/api/store/:storeId/options',
    REGISTRATION_YANDEX_CHECKOUT = '/api/store/:storeId/yacheckout/application',
    REGISTRATION_CHECK_PAYMENT = '/api/subscription/confirmation_payment',
    STORE_DELIVERY_SERVICE = '/api/store/:storeId/options/delivery_service',
    STORE_DELIVERY_PROVIDERS = '/api/store/:storeId/options/delivery_service/providers?token=:token',
    STORE_DELIVERY_PLANS = '/api/store/:storeId/options/delivery_service/tariffs?totalWeight=:weight&unitType=KG',
    STORE_DELIVERY_EXTERNAL = '/api/store/:storeId/orders/:orderId/delivery?tariffId=:tariffId&unitType=:unitType&totalWeight=:totalWeight',
    STORE_DELIVERY_EXTERNAL_SEND = '/api/store/:storeId/orders/:orderId/delivery/request',
    QUALIFY_STORE_AS_SIMPLE = '/api/store/:storeId/simple_store',
    STORE_CHANGE_MODERATED_STATUS = '/api/store/:storeId/moderation?result=:moderated',
    STORE_SWITCH_STORE_STATUS = '/api/store/:storeId/status',
    STORE_NOTIFICATION_SETTINGS = '/api/store/:storeId/notification_settings',
    STORE_SET_STICKERS_SENT = '/api/store/:storeId/stickers',
    STORE_CLONE = '/api/store/clone?storeId=:storeId',

    VENDOR_FAKE_USER = '/api/users/vendor/fake',
    FROM_FAKE_USER_TO_CURRENT_USER = '/api/users/vendor/reassignment',
    VENDOR_SELLING = '/api/users/vendor/selling',

    CLICK_FOR_BUTTON_PHONE_ACTIVATE = '/api/stores/:storeId/call/log',

    ORDERS_STATS = '/api/store/:storeId/orders/stats',
    ORDER_LIST_ALL = '/api/store/:storeId/orders',
    ORDER_LIST = '/api/store/:storeId/orders?status=:status',
    ORDER_ITEM = '/api/store/:storeId/orders/:orderId',
    ORDER_ITEM_STATUS = '/api/store/:storeId/orders/:orderId/status',

    OPEN_FOR_ORDERS = '/api/store/:storeId/open_for_orders',

    VENDOR_SUBSCRIBE = '/api/subscription',
    VENDOR_PAYMENT_METHOD = '/api/subscription/payment_method',
    VENDOR_SUBSCRIPTION_PAUSE = '/api/subscription/pause',
    VENDOR_SUBSCRIPTION_RESUME = '/api/subscription/resume',
    VENDOR_SUBSCRIPTION_REQUEST_PRIORITY = '/api/subscription/request',
    VENDOR_SUBSCRIPTION_TRIAL = '/api/subscription/trial?storeId=:storeId',
    VENDOR_SUBSCRIPTION_MANUAL = '/api/subscription/period',
    VENDOR_SUBSCRIPTION_PLANS = '/api/subscription/plans',
    VENDOR_GET_MARKETING_DOCS = '/api/store/:storeId/marketing/store_docs',
    VENDOR_GET_MARKETING_DOCS_2 = '/api/store/:storeId/marketing/store_docs/sticker_with_name',
    VENDOR_GET_ALL_MARKETING_DOCS = '/api/store/:storeId/marketing/zip',
    VENDOR_GET_STORE_LINK = '/api/store/:storeId/owner_link',
    VENDOR_GET_STORE_STATISTIC = '/api/metrics/selling',

    VENDOR_PROMOS = '/api/store/:storeId/promo',
    VENDOR_PROMO = '/api/store/:storeId/promo/:promoId',

    PHONE_OTP_SEND = '/api/otp/verification',
    PHONE_OTP_VERIFY = '/api/verification',

    METRICS = '/api/metrics/landing?source=:source&utm_source=:utm_source&correlationId=:correlationId',
    METRICS_WITHOUT_CORRELATION_ID = '/api/metrics/landing?source=:source&utm_source=:utm_source',

    PRODUCT = '/api/store/:storeId/categories/:categoryId/products/:productId',
    PRODUCTS = '/api/store/:storeId/categories/:categoryId/products',
    STORE_PRODUCTS = '/api/store/:storeId/products',
    STORE_PRODUCTS_PAGE = '/api/store/:storeId/products/page',
    CHANGE_CATEGORY_PRODUCTS_ORDER = '/api/store/:storeId/dnd/product',
    CHANGE_CATEGORIES_ORDER = '/api/store/:storeId/dnd/category',

    PRODUCT_DESIRED = '/api/store/:storeId/categories/:categoryId/products/:productId/desired',

    FEED = '/api/feed',
    FEED_NEW = '/api/feed/new',
    FEED_MAP = '/api/feed/map',
    FEED_CATEGORIES = '/api/feed/categories',

    ONLINE_PAYMENT = '/api/orders/:orderId/online_payment',

    PROFILE = '/api/users/profile',
    REQUISITES = '/api/users/requisites',
    ORDERS = '/api/orders',
    ORDER = '/api/orders/:orderId',
    ORDER_STATUS = '/api/orders/:orderId/status',

    CART = '/api/cart',
    CART_BY_ID = '/api/cart/:cartId',
    CART_PRODUCT = '/api/cart/:cartId/product',
    CART_PRODUCT_BY_ID = '/api/cart/:cartId/product/:productId',

    SEARCH = '/api/ft_search',
    SEARCH_GROUPED = '/api/ft_search/stores',

    LOG_ERROR = '/api/logging',

    FEED_CAROUSEL = '/api/carousel',

    ADMIN_REPORT = '/api/operations/client_report',
    STORE_METRICS = '/api/metrics/selling',
    STORE_METRICS_REPORT = '/api/store/:storeId/metrics_report',
}
