import { CartDto, DeliveryType, PaymentType, ProductDto, StoreDto, UserRole } from 'types';
import { OnlinePaymentStatus } from './reducer';
import { OrderResponseProduct } from 'state/order/types';

export enum CheckoutActionType {
    ADD_TO_CART = 'checkout/ADD_TO_CART',
    SET_STORE_INFO = 'checkout/SET_STORE_INFO',
    REMOVE_FROM_CART = 'checkout/REMOVE_FROM_CART',
    PURCHASE = 'checkout/PURCHASE',
    CHANGE_CART_SUCCESS = 'checkout/CHANGE_CART_SUCCESS',
    CHOSE_PAYMENT = 'checkout/CHOSE_PAYMENT',
    CHOSE_DELIVERY = 'checkout/CHOSE_DELIVERY',
    CHANGE_PRODUCT_QUANTITY = 'checkout/CHANGE_PRODUCT_QUANTITY',
    CLEAR = 'checkout/clear',
    CHANGE_PAYMENT_STATUS = 'checkout/CHANGE_PAYMENT_STATUS',
    SUBMIT = 'checkout/SUBMIT',
    SHOW_ADDRESS_DIALOG = 'checkout/SHOW_ADDRESS_DIALOG',
    HIDE_ADDRESS_DIALOG = 'checkout/HIDE_ADDRESS_DIALOG',
    SHOW_SILENT_LOGIN_DIALOG = 'checkout/SHOW_SILENT_LOGIN_DIALOG',
    HIDE_SILENT_LOGIN_DIALOG = 'checkout/HIDE_SILENT_LOGIN_DIALOG',
    SILENT_LOGIN_SUBMIT = 'checkout/SILENT_LOGIN_SUBMIT',
    OPEN_CHECKOUT_SECOND_STEP = 'checkout/OPEN_CHECKOUT_SECOND_STEP',
    CLOSE_CHECKOUT_SECOND_STEP = 'checkout/CLOSE_CHECKOUT_SECOND_STEP',
    ADDRESS_SUBMIT_SECOND_STEP = 'checkout/ADDRESS_SUBMIT_SECOND_STEP',
    SHOW_SUCCESS_MESSAGE = 'checkout/SHOW_SUCCESS_MESSAGE',
    HIDE_SUCCESS_MESSAGE = 'checkout/HIDE_SUCCESS_MESSAGE',
    UPDATE_CART_INFO = 'checkout/UPDATE_CART_INFO',
    REMOVE_FULL_CART = 'checkout/REMOVE_FULL_CART',
    CHANGE_PROMO = 'checkout/CHANGE_PROMO',
    OPEN_CART = 'checkout/OPEN_CART',
    CLOSE_CART = 'checkout/CLOSE_CART',
}

export interface CheckoutBaseAction {
    type: CheckoutActionType;
}

export interface CheckoutAddToCartAction extends CheckoutBaseAction {
    type: CheckoutActionType.ADD_TO_CART;
    payload: {
        storeId: string;
        product: ProductDto;
        quantity: number;
        selectedOptions: Record<string, string[]>;
    };
}

export interface CheckoutSetStoreInfoAction extends CheckoutBaseAction {
    type: CheckoutActionType.SET_STORE_INFO;
    payload: {
        storeInfo: StoreDto;
    };
}

export interface CheckoutRemoveFromCartAction extends CheckoutBaseAction {
    type: CheckoutActionType.REMOVE_FROM_CART;
    payload: {
        product: OrderResponseProduct;
    };
}

export interface CheckoutChangeCartSuccessAction extends CheckoutBaseAction {
    type: CheckoutActionType.CHANGE_CART_SUCCESS;
    payload: CartDto;
}

export interface CheckoutChangeProductQuantityAction extends CheckoutBaseAction {
    type: CheckoutActionType.CHANGE_PRODUCT_QUANTITY;
    payload: {
        product: OrderResponseProduct;
        quantity: number;
    };
}

export interface CheckoutChosePaymentAction extends CheckoutBaseAction {
    type: CheckoutActionType.CHOSE_PAYMENT;
    payload: {
        paymentType: PaymentType;
    };
}

export interface CheckoutChoseDeliveryAction extends CheckoutBaseAction {
    type: CheckoutActionType.CHOSE_DELIVERY;
    payload: {
        deliveryType: DeliveryType;
    };
}
export interface CheckoutPurchaseAction extends CheckoutBaseAction {
    type: CheckoutActionType.PURCHASE;
    payload: {
        phoneNumber: string;
    };
}

export interface CheckoutClearAction extends CheckoutBaseAction {
    type: CheckoutActionType.CLEAR;
}

export interface CheckoutChangePaymentStatusAction extends CheckoutBaseAction {
    type: CheckoutActionType.CHANGE_PAYMENT_STATUS;
    payload: {
        status: OnlinePaymentStatus;
    };
}

export interface CheckoutSubmitAction extends CheckoutBaseAction {
    type: CheckoutActionType.SUBMIT;
}

export interface CheckoutSilentLoginSubmitAction extends CheckoutBaseAction {
    type: CheckoutActionType.SILENT_LOGIN_SUBMIT;
    payload: {
        jwt: string;
        username: string;
        role: UserRole;
    };
}

export interface CheckoutShowAddressDialogAction extends CheckoutBaseAction {
    type: CheckoutActionType.SHOW_ADDRESS_DIALOG;
}

export interface CheckoutHideAddressDialogAction extends CheckoutBaseAction {
    type: CheckoutActionType.HIDE_ADDRESS_DIALOG;
}

export interface CheckoutShowSilentLoginDialogAction extends CheckoutBaseAction {
    type: CheckoutActionType.SHOW_SILENT_LOGIN_DIALOG;
}

export interface CheckoutHideSilentLoginDialogAction extends CheckoutBaseAction {
    type: CheckoutActionType.HIDE_SILENT_LOGIN_DIALOG;
}

export interface CheckoutSecondStepAction extends CheckoutBaseAction {
    type: CheckoutActionType.OPEN_CHECKOUT_SECOND_STEP;
}

export interface CheckoutCloseSecondStepAction extends CheckoutBaseAction {
    type: CheckoutActionType.CLOSE_CHECKOUT_SECOND_STEP;
}

export interface CheckoutSubmitSecondStepAction extends CheckoutBaseAction {
    type: CheckoutActionType.ADDRESS_SUBMIT_SECOND_STEP;
}

export interface CheckoutShowSuccessMessageAction extends CheckoutBaseAction {
    type: CheckoutActionType.SHOW_SUCCESS_MESSAGE;
    payload: {
        delay: number;
        orderId: string;
    };
}

export interface CheckoutHideSuccessMessageAction extends CheckoutBaseAction {
    type: CheckoutActionType.HIDE_SUCCESS_MESSAGE;
}

export interface CheckoutUpdateCartInfoAction extends CheckoutBaseAction {
    type: CheckoutActionType.UPDATE_CART_INFO;
}

export interface CheckoutRemoveFullCartAction extends CheckoutBaseAction {
    type: CheckoutActionType.REMOVE_FULL_CART;
}

export interface CheckoutChangePromoAction extends CheckoutBaseAction {
    type: CheckoutActionType.CHANGE_PROMO;
    payload: {
        promoId: string;
    };
}

export interface CheckoutOpenCartAction extends CheckoutBaseAction {
    type: CheckoutActionType.OPEN_CART;
}

export interface CheckoutCloseCartAction extends CheckoutBaseAction {
    type: CheckoutActionType.CLOSE_CART;
}

export type CheckoutAction =
    | CheckoutAddToCartAction
    | CheckoutSetStoreInfoAction
    | CheckoutPurchaseAction
    | CheckoutChangeCartSuccessAction
    | CheckoutChosePaymentAction
    | CheckoutChoseDeliveryAction
    | CheckoutChangeProductQuantityAction
    | CheckoutRemoveFromCartAction
    | CheckoutClearAction
    | CheckoutChangePaymentStatusAction
    | CheckoutSubmitAction
    | CheckoutSilentLoginSubmitAction
    | CheckoutShowAddressDialogAction
    | CheckoutHideAddressDialogAction
    | CheckoutShowSilentLoginDialogAction
    | CheckoutHideSilentLoginDialogAction
    | CheckoutSecondStepAction
    | CheckoutCloseSecondStepAction
    | CheckoutSubmitSecondStepAction
    | CheckoutShowSuccessMessageAction
    | CheckoutUpdateCartInfoAction
    | CheckoutHideSuccessMessageAction
    | CheckoutRemoveFullCartAction
    | CheckoutChangePromoAction
    | CheckoutOpenCartAction
    | CheckoutCloseCartAction;

export const makeCheckoutAddToCartAction = (
    storeId: string,
    product: ProductDto,
    quantity: number,
    selectedOptions: Record<string, string[]>,
): CheckoutAddToCartAction => ({
    type: CheckoutActionType.ADD_TO_CART,
    payload: {
        storeId,
        product,
        quantity,
        selectedOptions,
    },
});

export const makeCheckoutSetStoreInfoAction = (storeInfo: StoreDto): CheckoutSetStoreInfoAction => ({
    type: CheckoutActionType.SET_STORE_INFO,
    payload: {
        storeInfo,
    },
});

export const makeCheckoutRemoveFromCartAction = (product: OrderResponseProduct): CheckoutRemoveFromCartAction => ({
    type: CheckoutActionType.REMOVE_FROM_CART,
    payload: {
        product,
    },
});

export const makeCheckoutUpdateCartInfo = (): CheckoutUpdateCartInfoAction => ({
    type: CheckoutActionType.UPDATE_CART_INFO,
});

export const makeCheckoutChangeCartSuccessAction = (cart: CartDto): CheckoutChangeCartSuccessAction => ({
    type: CheckoutActionType.CHANGE_CART_SUCCESS,
    payload: cart,
});

export const makeCheckoutPurchaseAction = (phoneNumber: string): CheckoutPurchaseAction => ({
    type: CheckoutActionType.PURCHASE,
    payload: {
        phoneNumber,
    },
});

export const makeCheckoutChosePaymentAction = (paymentType: PaymentType): CheckoutChosePaymentAction => ({
    type: CheckoutActionType.CHOSE_PAYMENT,
    payload: {
        paymentType,
    },
});

export const makeCheckoutChoseDeliveryAction = (deliveryType: DeliveryType): CheckoutChoseDeliveryAction => ({
    type: CheckoutActionType.CHOSE_DELIVERY,
    payload: {
        deliveryType,
    },
});

export const makeCheckoutChangeProductQuantityAction = (
    product: OrderResponseProduct,
    quantity: number,
): CheckoutChangeProductQuantityAction => ({
    type: CheckoutActionType.CHANGE_PRODUCT_QUANTITY,
    payload: {
        product,
        quantity,
    },
});

export const makeCheckoutClearAction = (): CheckoutClearAction => ({
    type: CheckoutActionType.CLEAR,
});

export const makeCheckoutChangePaymentStatusAction = (
    status: OnlinePaymentStatus,
): CheckoutChangePaymentStatusAction => ({
    type: CheckoutActionType.CHANGE_PAYMENT_STATUS,
    payload: {
        status,
    },
});

export const makeCheckoutSubmitAction = (): CheckoutSubmitAction => ({
    type: CheckoutActionType.SUBMIT,
});

export const makeCheckoutSilentLoginSubmitAction = (
    jwt: string,
    username: string,
    role: UserRole,
): CheckoutSilentLoginSubmitAction => ({
    type: CheckoutActionType.SILENT_LOGIN_SUBMIT,
    payload: {
        jwt,
        username,
        role,
    },
});

export const makeCheckoutShowAddressDialogAction = (): CheckoutShowAddressDialogAction => ({
    type: CheckoutActionType.SHOW_ADDRESS_DIALOG,
});

export const makeCheckoutHideAddressDialogAction = (): CheckoutHideAddressDialogAction => ({
    type: CheckoutActionType.HIDE_ADDRESS_DIALOG,
});

export const makeCheckoutShowSilentLoginDialogAction = (): CheckoutShowSilentLoginDialogAction => ({
    type: CheckoutActionType.SHOW_SILENT_LOGIN_DIALOG,
});

export const makeCheckoutHideSilentLoginDialogAction = (): CheckoutHideSilentLoginDialogAction => ({
    type: CheckoutActionType.HIDE_SILENT_LOGIN_DIALOG,
});

export const makeCheckoutSecondStepAction = (): CheckoutSecondStepAction => ({
    type: CheckoutActionType.OPEN_CHECKOUT_SECOND_STEP,
});

export const makeCloseCheckoutSecondStepAction = (): CheckoutCloseSecondStepAction => ({
    type: CheckoutActionType.CLOSE_CHECKOUT_SECOND_STEP,
});

export const makeSubmitCheckoutSecondStepAction = (): CheckoutSubmitSecondStepAction => ({
    type: CheckoutActionType.ADDRESS_SUBMIT_SECOND_STEP,
});

export const makeCheckoutShowSuccessMessageAction = (
    delay: number,
    orderId: string,
): CheckoutShowSuccessMessageAction => ({
    type: CheckoutActionType.SHOW_SUCCESS_MESSAGE,
    payload: {
        delay,
        orderId,
    },
});

export const makeCheckoutHideSuccessMessageAction = (): CheckoutHideSuccessMessageAction => ({
    type: CheckoutActionType.HIDE_SUCCESS_MESSAGE,
});

export const makeCheckoutRemoveFullCartAction = (): CheckoutRemoveFullCartAction => ({
    type: CheckoutActionType.REMOVE_FULL_CART,
});

export const makeCheckoutChangePromoAction = (promoId: string): CheckoutChangePromoAction => ({
    type: CheckoutActionType.CHANGE_PROMO,
    payload: {
        promoId,
    },
});

export const makeCheckoutOpenCartAction = (): CheckoutOpenCartAction => ({
    type: CheckoutActionType.OPEN_CART,
});

export const makeCheckoutCloseCartAction = (): CheckoutCloseCartAction => ({
    type: CheckoutActionType.CLOSE_CART,
});
