import { SagaIterator } from 'redux-saga';
import { call, takeEvery } from 'redux-saga/effects';

import { getLocalStorageItem, LocalStorageKey, setLocalStorageItem } from 'core/storage/storage';

import {
    FeedActionType,
    FeedSetAddressAction,
    FeedSetCoordinatesAction,
    FeedSetStandardAddressDataAction,
} from './actions';
import { changeAddressSaga } from 'state/checkout/sagas';

export function* feedSaga(): SagaIterator {
    yield takeEvery(FeedActionType.SET_COORDINATES, function* (action: FeedSetCoordinatesAction) {
        setLocalStorageItem(LocalStorageKey.COORDINATES, action.payload);

        if (getLocalStorageItem(LocalStorageKey.CART_ID)) {
            yield call(changeAddressSaga);
        }
    });
    yield takeEvery(FeedActionType.SET_STANDARD_ADDRESS_DATA, function* (action: FeedSetStandardAddressDataAction) {
        setLocalStorageItem(LocalStorageKey.STANDARD_ADDRESS_DATA, action.payload);

        if (getLocalStorageItem(LocalStorageKey.CART_ID)) {
            yield call(changeAddressSaga);
        }
    });
    yield takeEvery(FeedActionType.SET_ADDRESS, function* (action: FeedSetAddressAction) {
        setLocalStorageItem(LocalStorageKey.ADDRESS, action.payload);
        if (action.payload.isUserInput) {
            setLocalStorageItem(LocalStorageKey.ADDRESS_CHANGED_BY_USER, 'true');
        }
    });
}
