import { SagaIterator } from 'redux-saga';
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';

import { redirectSaga } from 'core/sagas/sagas';

import { makeSnackbarClearAction, SnackbarActionType } from './actions';
import { snackbarMessageTypeSelector, snackbarParamsSelector } from './selectors';
import { SnackbarMessageType } from './reducer';
import { makeUserMenuNavigateAction } from '../userMenu/actions';
import { UserMenuSelectedItem } from '../userMenu/reducer';

import { RoutePath } from 'RoutePath';

export function* snackbarSaga(): SagaIterator {
    yield takeEvery(SnackbarActionType.OPEN, snackbarOpenSaga);
}

function* snackbarOpenSaga(): SagaIterator {
    const messageType = yield select(snackbarMessageTypeSelector);
    switch (messageType) {
        case SnackbarMessageType.VENDOR_ORDER_NEW:
            yield call(snackbarOpenVendorOrderSaga);
            break;
        case SnackbarMessageType.CUSTOMER_ORDER_DELIVERY:
        case SnackbarMessageType.CUSTOMER_ORDER_PREPARING:
        case SnackbarMessageType.CUSTOMER_ORDER_WAITING_FOR_APPROVAL:
            yield call(snackbarOpenCustomerOrderSaga);
            break;
        default:
            break;
    }
    yield delay(250);
    yield put(makeSnackbarClearAction());
}

function* snackbarOpenVendorOrderSaga(): SagaIterator {
    const params = yield select(snackbarParamsSelector);
    yield call(redirectSaga, RoutePath.ORDERS_NEW_DIALOG, { orderId: params.orderId });
}

function* snackbarOpenCustomerOrderSaga(): SagaIterator {
    const params = yield select(snackbarParamsSelector);
    yield put(makeUserMenuNavigateAction(UserMenuSelectedItem.ORDER, params));
}
