import { captureException } from '@sentry/nextjs';
import { call, put } from 'redux-saga/effects';
import { AxiosError } from 'axios';
import { setSubmitFailed, stopAsyncValidation, updateSyncErrors } from 'redux-form';

import { deleteRequest, getRequest, postMultipartRequest, postRequest, putRequest } from 'core/axios/axios';
import { makeUrl } from 'core/utils/utils';
import { getJwt, logout } from 'core/auth/auth';

import { makeAppUIDisableAction, makeAppUIEnableAction } from '../app/actions';

import { Endpoint } from 'Endpoint';

const handleAuthError = (e: AxiosError) => {
    captureException(e);
    if (e.isAxiosError && e.response) {
        const { status } = e.response;
        const jwt = getJwt();
        if (status === 401 && jwt) {
            logout();
        }
    }
};

export const getMessagesFromError = (e: AxiosError) => {
    if (e.isAxiosError && e.response) {
        const { data, status } = e.response;

        if ((status === 400 || status === 409) && data && data.errors) {
            const errors: Record<string, string> = Object.keys(data.errors).reduce((acc, k) => {
                return {
                    ...acc,
                    [k]: (data.errors[k] ?? []).map((item: any) => item.message).join('\n'),
                };
            }, {});
            return errors;
        }

        if (status === 401) {
            return { generic: 'common:errors.invalidCredentials' };
        }

        if (status === 413) {
            return { generic: 'common:errors.requestEntityTooLarge' };
        }

        return { generic: 'common:errors.generic' };
    }
};

export function* handleFormErrorSaga(form: string, error: AxiosError) {
    const { generic, ...rest } = getMessagesFromError(error);
    yield put(setSubmitFailed(form));
    yield put(updateSyncErrors(form, rest, generic));
}

export function* getRequestSaga<P>(
    endpoint: Endpoint,
    params?: P,
    headers?: Record<string, string>,
    queryParams?: Record<string, any>,
) {
    try {
        const url = makeUrl(endpoint, params, queryParams);
        return yield call(getRequest, url, headers);
    } catch (e) {
        handleAuthError(e);
        throw e;
    }
}

export function* formGetRequestSaga<P, T>(
    form: string,
    endpoint: Endpoint,
    params?: P,
    headers?: Record<string, string>,
) {
    try {
        yield put(makeAppUIDisableAction());
        const url = makeUrl(endpoint, params);
        const response = yield call(getRequest, url, headers);
        yield put(stopAsyncValidation(form));
        return response;
    } catch (e) {
        handleAuthError(e);
        yield call(handleFormErrorSaga, form, e);
        yield put(makeAppUIEnableAction());
        throw e;
    } finally {
        yield put(makeAppUIEnableAction());
    }
}

export function* deleteRequestSaga<P, T>(endpoint: Endpoint, params?: P, body?: T, headers?: Record<string, string>) {
    try {
        const url = makeUrl(endpoint, params);
        return yield call(deleteRequest, url, body, headers);
    } catch (e) {
        handleAuthError(e);
        throw e;
    }
}

export function* postRequestSaga<P, T>(
    endpoint: Endpoint,
    params?: P,
    body?: T,
    headers?: Record<string, string>,
    queryParams?: Record<string, any>,
) {
    try {
        const url = makeUrl(endpoint, params, queryParams);
        return yield call(postRequest, url, body, headers);
    } catch (e) {
        handleAuthError(e);
        throw e;
    }
}

export function* formPostRequestSaga<P, T>(
    form: string,
    endpoint: Endpoint,
    params?: P,
    body?: T,
    headers?: Record<string, string>,
    queryParams?: Record<string, any>,
) {
    try {
        yield put(makeAppUIDisableAction());
        const url = makeUrl(endpoint, params, queryParams);
        const response = yield call(postRequest, url, body, headers);
        yield put(stopAsyncValidation(form));
        return response;
    } catch (e) {
        handleAuthError(e);
        yield call(handleFormErrorSaga, form, e);
        throw e;
    } finally {
        yield put(makeAppUIEnableAction());
    }
}

export function* postMultipartRequestSaga<P>(
    endpoint: Endpoint,
    params?: P,
    formData?: FormData,
    headers?: Record<string, string>,
) {
    try {
        const url = makeUrl(endpoint, params);
        return yield call(postMultipartRequest, url, formData, headers);
    } catch (e) {
        handleAuthError(e);
        throw e;
    }
}

export function* formPostMultipartRequestSaga<P>(
    form: string,
    endpoint: Endpoint,
    params?: P,
    formData?: FormData,
    headers?: Record<string, string>,
) {
    try {
        yield put(makeAppUIDisableAction());
        const url = makeUrl(endpoint, params);
        const response = yield call(postMultipartRequest, url, formData, headers);
        yield put(stopAsyncValidation(form));
        return response;
    } catch (e) {
        handleAuthError(e);
        yield call(handleFormErrorSaga, form, e);
        yield put(makeAppUIEnableAction());
        throw e;
    }
}

export function* putRequestSaga<P, T>(endpoint: Endpoint, params?: P, body?: T, headers?: Record<string, string>) {
    try {
        const url = makeUrl(endpoint, params);
        return yield call(putRequest, url, body, headers);
    } catch (e) {
        handleAuthError(e);
        throw e;
    }
}

export function* formPutRequestSaga<P, T>(
    form: string,
    endpoint: Endpoint,
    params?: P,
    body?: T,
    headers?: Record<string, string>,
) {
    try {
        yield put(makeAppUIDisableAction());
        const url = makeUrl(endpoint, params);
        const response = yield call(putRequest, url, body, headers);
        yield put(stopAsyncValidation(form));
        return response;
    } catch (e) {
        handleAuthError(e);
        yield call(handleFormErrorSaga, form, e);
        yield put(makeAppUIEnableAction());
        throw e;
    } finally {
        yield put(makeAppUIEnableAction());
    }
}
