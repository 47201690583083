import { AuthStatus } from 'state/auth/reducer';
import { authStatusSelector } from 'state/auth/selectors';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { redirectSaga } from 'core/sagas/sagas';

import {
    makeStoreChangeModeratedStatusSuccessAction,
    makeStoreFetchByIdFailureAction,
    makeStoreFetchByIdSuccessAction,
    makeStoreFetchNewProductsPageAction,
    makeStoreFetchNewProductsPageSuccessAction,
    makeStoreFetchSuccessAction,
    makeStoreUpdateStoreOwnerInfoAction,
    makeStoreUpdateStorePhoneLinkAction,
    makeStoreUpdateStoreSubscriptionInfoAction,
    StoreActionType,
    StoreActivateCreateLinkAction,
    StoreActivatePullEmailAction,
    StoreActivateTrialPeriodAction,
    StoreChangeModeratedStatusRequestAction,
    StoreCloneAction,
    StoreDeleteRequestAction,
    StoreFetchByIdRequestAction,
    StoreFetchNewProductsPageAction,
    StoreFetchPhoneLinkAction,
    StoreFetchRequestAction,
    StoreFetchSubscriptionInfoAction,
} from './actions';
import { deleteRequestSaga, getRequestSaga, postRequestSaga } from '../rest/sagas';
import { feedCoordinatesSelector } from '../feed/selectors';

import { Endpoint } from 'Endpoint';
import { RoutePath } from 'RoutePath';

import { PhoneLinkResponse, StoreDto, SubscriptionResponse, VendorContactType } from 'types';
import { makeSnackbarErrorAction } from '../snackbar/actions';
import { captureException } from '@sentry/nextjs';
import { splitOnce } from '../../core/utils/utils';

export function* storeSaga(): SagaIterator<void> {
    yield takeEvery(StoreActionType.FETCH_REQUEST, storeFetchAllSaga);
    yield takeEvery(StoreActionType.FETCH_ID_REQUEST, storeFetchStoreByIdSaga);
    yield takeEvery(StoreActionType.DELETE_STORE_REQUEST, storeDeleteSaga);
    yield takeEvery(StoreActionType.CREATE_LINK, storeActivateCreateLinkSaga);
    yield takeEvery(StoreActionType.ACTIVATE_TRIAL_PERIOD, storeActivateTrialPeriodSaga);
    yield takeEvery(StoreActionType.CHANGE_MODERATED_STATUS_REQUEST, storeChangeModeratedStatusSaga);
    yield takeEvery(StoreActionType.FETCH_STORE_SUBSCRIPTION_INFO, storeFetchSubscriptionSaga);
    yield takeEvery(StoreActionType.FETCH_STORE_PHONE_LINK_INFO, storeFetchPhoneLinkSaga);
    yield takeEvery(StoreActionType.CLICK_FOR_BUTTON_PHONE, storeFetchPullEmailSaga);
    yield takeEvery(StoreActionType.FETCH_NEW_PRODUCTS_PAGE, storeFetchNewProductsPageSaga);
    yield takeEvery(StoreActionType.CLONE, storeCloneSaga);
}

function* storeFetchAllSaga(action: StoreFetchRequestAction): SagaIterator<void> {
    const { storeSlug } = action.payload;
    const authStatus = yield select(authStatusSelector);
    try {
        const store = yield call(storeFetchSaga, storeSlug);
        const { storeId, vendorId } = store;
        const {
            data: { products, nextStart },
        } = yield call(getRequestSaga, Endpoint.STORE_PRODUCTS_PAGE, { storeId });
        const { data: categories } = yield call(getRequestSaga, Endpoint.REGISTRATION_CATALOG_CATEGORIES, { storeId });
        const {
            data: { promos },
        } = yield call(getRequestSaga, Endpoint.VENDOR_PROMOS, { storeId });
        yield put(makeStoreFetchSuccessAction(store, products, categories, promos));
        if (nextStart) {
            yield put(makeStoreFetchNewProductsPageAction(storeId, nextStart));
        }
        if (authStatus === AuthStatus.LOGGED_IN) {
            try {
                const { data: userInfo } = yield call(getRequestSaga, Endpoint.USER_INFO_BY_ID, { userId: vendorId });
                yield put(makeStoreUpdateStoreOwnerInfoAction(userInfo.fake));
            } catch (e) {}
        }
    } catch (e) {
        yield put(makeStoreFetchByIdFailureAction());
        // console.error(e);
    }
}

export function* storeFetchSaga(storeSlug: string): SagaIterator<StoreDto> {
    const coordinates = yield select(feedCoordinatesSelector);
    if (coordinates && coordinates.lat && coordinates.lon) {
        const { lat, lon } = coordinates;
        const { data: store } = yield call(getRequestSaga, Endpoint.STORE_BY_SLUG_WITH_COORDINATES, {
            slug: encodeURI(storeSlug),
            lat,
            lon,
        });
        return store;
    }
    const { data: store } = yield call(getRequestSaga, Endpoint.STORE_BY_SLUG, { slug: encodeURI(storeSlug) });
    return store;
}

export function* storeByIdFetchSaga(storeId: string): SagaIterator<StoreDto> {
    const coordinates = yield select(feedCoordinatesSelector);
    if (coordinates && coordinates.lat && coordinates.lon) {
        const { lat, lon } = coordinates;
        const { data: store } = yield call(getRequestSaga, Endpoint.STORE_WITH_COORDINATES, {
            storeId,
            lat,
            lon,
        });
        return store;
    }
    const { data: store } = yield call(getRequestSaga, Endpoint.STORE, { storeId });
    return store;
}

function* storeFetchStoreByIdSaga(action: StoreFetchByIdRequestAction): SagaIterator<void> {
    try {
        const coordinates = yield select(feedCoordinatesSelector);
        const { storeId } = action.payload;
        if (coordinates && coordinates.lat && coordinates.lon) {
            const { lat, lon } = coordinates;
            const { data: store } = yield call(getRequestSaga, Endpoint.STORE_WITH_COORDINATES, {
                storeId: storeId,
                lat,
                lon,
            });
            yield put(makeStoreFetchByIdSuccessAction(store));
        } else {
            const { data: store } = yield call(getRequestSaga, Endpoint.STORE, { storeId });
            yield put(makeStoreFetchByIdSuccessAction(store));
        }
    } catch (e) {
        yield put(makeStoreFetchByIdFailureAction());
    }
}

function* storeDeleteSaga(action: StoreDeleteRequestAction): SagaIterator<void> {
    yield call(deleteRequestSaga, Endpoint.STORE, { storeId: action.payload.storeId });
    yield call(redirectSaga, RoutePath.FEED, {}, true);
}

function* storeActivateTrialPeriodSaga(action: StoreActivateTrialPeriodAction): SagaIterator<void> {
    try {
        const { storeId } = action.payload;
        yield call(postRequestSaga, Endpoint.VENDOR_SUBSCRIPTION_TRIAL, { storeId });
        window.location.assign(RoutePath.VENDOR_DASHBOARD);
    } catch (e) {
        yield put(makeSnackbarErrorAction('common:errors.trialPeriod'));
    }
}

function* storeActivateCreateLinkSaga(action: StoreActivateCreateLinkAction): SagaIterator<void> {
    try {
        const { storeId } = action.payload;
        yield call(postRequestSaga, Endpoint.VENDOR_GET_STORE_LINK, { storeId });
        window.location.assign(RoutePath.VENDOR_DASHBOARD);
    } catch (e) {
        yield put(makeSnackbarErrorAction('common:errors.createLink'));
    }
}

function* storeChangeModeratedStatusSaga(action: StoreChangeModeratedStatusRequestAction): SagaIterator<void> {
    try {
        const { storeId, moderated } = action.payload;
        yield call(postRequestSaga, Endpoint.STORE_CHANGE_MODERATED_STATUS, { storeId, moderated });
        yield put(makeStoreChangeModeratedStatusSuccessAction(moderated));
    } catch (e) {
        yield put(makeSnackbarErrorAction('common:errors.moderated'));
    }
}

function* storeFetchSubscriptionSaga(action: StoreFetchSubscriptionInfoAction): SagaIterator<void> {
    try {
        const { storeId } = action.payload;
        const subscriptionResponse = yield call(getRequestSaga, Endpoint.VENDOR_SUBSCRIBE, {}, {}, { storeId });
        const subscription: SubscriptionResponse = subscriptionResponse.data;
        yield put(makeStoreUpdateStoreSubscriptionInfoAction(subscription));
    } catch (e) {
        yield put(makeSnackbarErrorAction('common:errors.subscription'));
    }
}

function* storeFetchPhoneLinkSaga(action: StoreFetchPhoneLinkAction): SagaIterator<void> {
    try {
        const { storeId } = action.payload;
        const phoneLinkResponse = yield call(getRequestSaga, Endpoint.VENDOR_GET_STORE_LINK, {}, {}, { storeId });
        const phone: PhoneLinkResponse = phoneLinkResponse.data;
        yield put(makeStoreUpdateStorePhoneLinkAction(phone));
    } catch (e) {
        yield put(makeSnackbarErrorAction('common:errors.subscription'));
    }
}

function* storeFetchPullEmailSaga(action: StoreActivatePullEmailAction): SagaIterator<void> {
    try {
        const { storeId } = action.payload;
        yield call(postRequestSaga, Endpoint.CLICK_FOR_BUTTON_PHONE_ACTIVATE, {
            storeId,
        });
    } catch (e) {
        yield put(makeSnackbarErrorAction('common:errors.subscription'));
    }
}

function* storeFetchNewProductsPageSaga(action: StoreFetchNewProductsPageAction): SagaIterator<void> {
    try {
        const { storeId, start } = action.payload;
        const {
            data: { products, nextStart },
        } = yield call(getRequestSaga, Endpoint.STORE_PRODUCTS_PAGE, { storeId }, {}, { start });
        if (nextStart) {
            yield put(makeStoreFetchNewProductsPageAction(storeId, nextStart));
        }
        yield put(makeStoreFetchNewProductsPageSuccessAction(products));
    } catch (e) {
        captureException(e);
    }
}

function* storeCloneSaga(action: StoreCloneAction): SagaIterator<void> {
    try {
        const {
            formValue,
            storeDto: { storeId, ...originalStoreDto },
        } = action.payload;

        const [city, streetAddress] = splitOnce(formValue.address, ', ');
        const addressToSave = formValue.standardAddress
            ? {
                  ...formValue.standardAddress,
                  geoCoordinates: {
                      latitude: formValue.coordinates.lat,
                      longitude: formValue.coordinates.lon,
                  },
              }
            : {
                  ...originalStoreDto.address,
                  geoCoordinates: {
                      latitude: formValue.coordinates.lat,
                      longitude: formValue.coordinates.lon,
                  },
                  city: {
                      name: city ?? formValue.address,
                  },
                  street: {
                      name: streetAddress ?? formValue.address,
                  },
                  formattedAddress: formValue.address,
              };

        const clonedStoreDto = {
            ...originalStoreDto,
            name: formValue.name,
            customUrl: formValue.customUrl,
            address: addressToSave,
            contacts: [
                {
                    defaultContact: true,
                    type: VendorContactType.PHONE,
                    value: formValue.phone,
                },
            ],
        };

        yield call(postRequestSaga, Endpoint.STORE_CLONE, { storeId }, clonedStoreDto);
    } catch (error) {
        captureException(error);
        yield put(makeSnackbarErrorAction('common:errors.generic'));
    }
}
