import { getLocalStorageItem, LocalStorageKey } from '../storage/storage';
import { splitOnce, validateCountryCode } from 'core/utils/utils';
import { getRequest } from '../axios/axios';
import { Endpoint } from 'Endpoint';
import { appConfig } from 'config/app';
import { StoreCategory } from 'types';
import { waitForGoogleMaps } from '../../components/GoogleMapsLoadedObserver/GoogleMapsLoadedObserver';
import browserCookies from 'browser-cookies';

export const renderDefaultMap = (element: HTMLDivElement, lat: number, lon: number): google.maps.Map => {
    return new google.maps.Map(element, {
        center: {
            lat,
            lng: lon,
        },
        zoom: 13,
        disableDefaultUI: true,
        gestureHandling: 'greedy',
    });
};

export const renderFeedMap = (element: HTMLDivElement, lat: number, lon: number): google.maps.Map => {
    return new google.maps.Map(element, {
        center: {
            lat,
            lng: lon,
        },
        zoom: 13,
        disableDefaultUI: true,
        gestureHandling: 'greedy',
        styles: [
            {
                featureType: 'poi',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
            },
        ],
    });
};

export const getMarkerItem = (
    active: boolean,
    storeCategory: StoreCategory,
    hasSubscription: boolean,
    hovered: boolean,
) => {
    const storeCategorySuffix = storeCategory ?? StoreCategory.OTHER;
    let size = hasSubscription ? 32 : 20;
    if (hovered) {
        size *= 1.5;
    }
    return {
        url: `/google-map-icons/shop_${storeCategorySuffix}_active.svg`,
        scaledSize: new google.maps.Size(size, size),
    };
};

export const renderMarker = (lat: number, lng: number, map: google.maps.Map): google.maps.Marker => {
    return new google.maps.Marker({ position: { lat, lng }, map });
};

export const reverseGeocode = async (lat: number, lon: number) => {
    await waitForGoogleMaps();
    const geocoder = new google.maps.Geocoder();
    const location = new google.maps.LatLng(lat, lon);

    return new Promise<{ cityName: string; streetAddress: string }>((resolve, reject) => {
        geocoder.geocode({ location }, (results, status) => {
            if (status === 'OK') {
                const addresses = results.filter(result => result.types.includes('street_address'));
                if (addresses && addresses[0]) {
                    const foundAddress = addresses[0];
                    const city = foundAddress.address_components.find(c => c.types.includes('locality'));
                    if (city) {
                        const cityName = city.long_name ?? city.short_name;
                        const regexp = new RegExp(`, ${cityName},.*$`);
                        const streetAddress = foundAddress.formatted_address.replace(regexp, '');
                        if (streetAddress) {
                            return resolve({ cityName, streetAddress });
                        }
                    }
                }
            }
            reject();
        });
    });
};

export const geocode = async (address: string) => {
    await waitForGoogleMaps();
    const geocoder = new google.maps.Geocoder();

    return new Promise<{ lat: number; lon: number }>((resolve, reject) => {
        geocoder.geocode({ address }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const address = results[0].geometry.location;
                    return resolve({
                        lat: address.lat(),
                        lon: address.lng(),
                    });
                }
            }
            reject();
        });
    });
};

export const getServerGeolocation = async (): Promise<{ lat: number; lon: number; city: string }> => {
    await waitForGoogleMaps();
    const address: { address: string } = getLocalStorageItem(LocalStorageKey.ADDRESS);
    const [localCity] = splitOnce(address?.address || '', ', ');
    const coordinates: { lat: number; lon: number } = getLocalStorageItem(LocalStorageKey.COORDINATES);
    if (localCity && coordinates) {
        return { ...coordinates, city: localCity };
    }

    const response = await getRequest(Endpoint.GEOLOCATION);
    if (response) {
        const { latitude: lat, longitude: lon, city } = response.data;
        const countryCode = response.headers['x-country-code'];
        const hasCountyCodeInCookie = browserCookies.get('country');
        if (countryCode && !hasCountyCodeInCookie) {
            browserCookies.set('country', validateCountryCode(countryCode), { samesite: 'Lax' });
        }
        return { lat, lon, city };
    }
};

export const getGeolocation = async () => {
    await waitForGoogleMaps();
    // return Promise.all([getBrowserGeolocation().catch(() => null), getServerGeolocation().catch(() => null)]).then(
    //     ([positionFromBrowser, positionFromServer]) => {
    //         const coordsFromBrowser = positionFromBrowser?.coords;
    //         const { defaultCoordinates } = appConfig;
    //         const lat = coordsFromBrowser?.latitude ?? positionFromServer?.lat ?? defaultCoordinates.lat;
    //         const lon = coordsFromBrowser?.longitude ?? positionFromServer?.lon ?? defaultCoordinates.lon;
    //         return { lat, lon };
    //     },
    // );
    return getServerGeolocation()
        .then(positionFromServer => {
            const { defaultCoordinates } = appConfig;
            const lat = positionFromServer?.lat ?? defaultCoordinates.lat;
            const lon = positionFromServer?.lon ?? defaultCoordinates.lon;
            return { lat, lon };
        })
        .catch(() => {
            const { defaultCoordinates } = appConfig;
            const lat = defaultCoordinates.lat;
            const lon = defaultCoordinates.lon;
            return { lat, lon };
        });
};
