import { AxiosError } from 'axios';
import { object } from 'yup';
import { useSelector } from 'react-redux';
import { getFormError, getFormAsyncErrors, getFormSyncErrors } from 'redux-form';

import { Form } from '../../forms/types';
import { useEffect } from 'react';

export const validator = <V>(rules: any) => (formValues: V) => {
    const schema = object().shape(rules);
    try {
        schema.validateSync(formValues, { abortEarly: false });
        return {};
    } catch (errors) {
        return errors.inner.reduce(
            (errors: any, err: any) => ({
                ...errors,
                [err.path]: err.message,
            }),
            {},
        );
    }
};

export const useFormError = (form: Form): string => {
    const selector = getFormError(form);
    return useSelector(selector) as string;
};

export const useFormSyncError = (form: Form): Record<string, string> => {
    const selector = getFormSyncErrors(form);
    return useSelector(selector) as Record<string, string>;
};

export const useFormHasAsyncError = (form: Form): boolean => {
    const selector = getFormAsyncErrors(form);
    const errors = useSelector(selector);
    return errors && ((Object.keys(errors).length > 0) as boolean);
};

export const useEnterListener = (callback: () => void) => {
    useEffect(() => {
        const onKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                callback();
            }
        };
        document.addEventListener('keypress', onKeyPress);
        return () => document.removeEventListener('keypress', onKeyPress);
    }, [callback]);
};

export const getErrorForFieldOrGeneric = (e: AxiosError, name: string) => {
    if (e.isAxiosError && e.response) {
        const errors = e.response.data?.errors;
        if (errors && errors[name] && errors[name][0]) {
            return errors[name][0].message;
        }
        if (errors && errors.generic && errors.generic[0]) {
            return errors.generic[0].message;
        }
    } else {
        return 'Произошла ошибка. Попробуйте еще раз.';
    }
};
