import { AdminActionType, ReportAction } from './actions';
import { ReportItemDetails, StoreEntry } from '../../pages/AdminReport/Report';

export interface ReportState {
    stores: StoreEntry[];
    selectedStore: ReportItemDetails;
}

export interface ReportSlice {
    adminReport: ReportState;
}

const initialState: ReportState = {
    stores: [],
    selectedStore: null,
};

export const reportReducer = (state = initialState, action: ReportAction): ReportState => {
    switch (action.type) {
        case AdminActionType.GET_STORE_ENTRIES:
            return {
                ...state,
                stores: [],
            };
        case AdminActionType.GET_STORE_ENTRIES_SUCCESS:
            return {
                ...state,
                stores: action.stores,
            };
        case AdminActionType.GET_STORE_DETAILS_SUCCESS:
            return {
                ...state,
                selectedStore: action.selectedStore,
            };
        case AdminActionType.GET_STORE_DETAILS:
            return {
                ...state,
                selectedStore: null,
            };
    }
    return state;
};
