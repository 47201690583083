export enum VendorMenuActionType {
    SHOW = 'vendorMenu/SHOW',
    HIDE = 'vendorMenu/HIDE',
}

export interface VendorMenuBaseAction {
    type: VendorMenuActionType;
}

export interface VendorMenuShowAction extends VendorMenuBaseAction {
    type: VendorMenuActionType.SHOW;
}

export interface VendorMenuHideAction extends VendorMenuBaseAction {
    type: VendorMenuActionType.HIDE;
}

export type VendorMenuAction = VendorMenuShowAction | VendorMenuHideAction;

export const makeVendorMenuShowAction = (): VendorMenuShowAction => ({
    type: VendorMenuActionType.SHOW,
});

export const makeVendorMenuHideAction = (): VendorMenuHideAction => ({
    type: VendorMenuActionType.HIDE,
});
