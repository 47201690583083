import { CheckoutSlice } from './reducer';

export const checkoutCartSelector = (state: CheckoutSlice) => {
    return state.checkout.cart;
};

export const checkoutCartIdSelector = (state: CheckoutSlice) => {
    return state.checkout.cart?.cartId;
};

export const checkoutTotalPriceSelector = (state: CheckoutSlice) => {
    return state.checkout.cart?.total;
};

export const checkoutIsoCodeSelector = (state: CheckoutSlice) => {
    return state.checkout?.storeInfo?.address?.isoCode;
};

export const checkoutSubTotalPriceSelector = (state: CheckoutSlice) => {
    return state.checkout.cart?.subtotal;
};

export const checkoutCartItemsQuantitySelector = (state: CheckoutSlice) => {
    return state.checkout.cart?.orderedProducts?.length;
};

export const checkoutCheckoutCartStatusSelector = (state: CheckoutSlice) => {
    return state.checkout.cart?.status;
};

export const checkoutCartPaymentTypeSelector = (state: CheckoutSlice) => {
    return state.checkout.cart?.paymentInfo?.paymentType;
};

export const checkoutCartDeliveryTypeSelector = (state: CheckoutSlice) => {
    return state.checkout.cart?.deliveryInfo?.deliveryType;
};

export const checkoutCartStoreInfoSelector = (state: CheckoutSlice) => {
    return state.checkout.storeInfo;
};

export const checkoutCartStoreIdSelector = (state: CheckoutSlice) => {
    return state.checkout.storeInfo?.storeId;
};

export const checkoutCartStoreSlugSelector = (state: CheckoutSlice) => {
    return state.checkout.storeInfo?.customUrl;
};

export const checkoutCartStoreDeliveryInfoSelector = (state: CheckoutSlice) => {
    return state.checkout.storeInfo?.storeDeliveries;
};

export const checkoutCartStorePaymentInfoSelector = (state: CheckoutSlice) => {
    return state.checkout.storeInfo?.storePayments;
};

export const checkoutPaymentStatusSelector = (state: CheckoutSlice) => {
    return state.checkout.paymentStatus;
};

export const checkoutShowSilentLoginDialogSelector = (state: CheckoutSlice) => {
    return state.checkout.showSilentLoginDialog;
};

export const checkoutShowAddressDialogSelector = (state: CheckoutSlice) => {
    return state.checkout.showAddressDialog;
};

export const checkoutCheckoutSuccessMessageSelector = (state: CheckoutSlice) => {
    return state.checkout.showSuccessMessage;
};

export const checkoutCardStorePromosSelector = (state: CheckoutSlice) => {
    return state.checkout.storeInfo?.promos || [];
};

export const checkoutShowCart = (state: CheckoutSlice) => {
    return state.checkout?.showCheckout;
};
