export enum AppActionType {
    INIT_APP = 'app/INIT_APP',
    UI_ENABLE = 'app/UI_ENABLE',
    UI_DISABLE = 'app/UI_DISABLE',
    INIT_SUCCESS = 'app/INIT_SUCCESS',
    INIT_FAILURE = 'app/INIT_FAILURE',
    INIT_SELLING = 'app/INIT_SELLING',
    INIT_SELLING_FAILURE = 'app/INIT_SELLING_FAILURE',
}

export interface BaseAppAction {
    type: AppActionType;
}

export interface AppUIEnableAction extends BaseAppAction {
    type: AppActionType.UI_ENABLE;
}

export interface AppUIDisableAction extends BaseAppAction {
    type: AppActionType.UI_DISABLE;
}

export interface AppInitSuccessAction extends BaseAppAction {
    type: AppActionType.INIT_SUCCESS;
}

export interface AppInitAction extends BaseAppAction {
    type: AppActionType.INIT_APP;
}

export interface AppInitFailureAction extends BaseAppAction {
    type: AppActionType.INIT_FAILURE;
}

export interface AppInitSellingFailureAction extends BaseAppAction {
    type: AppActionType.INIT_SELLING_FAILURE;
    payload: { errorText: string };
}

export interface AppInitSellingAction extends BaseAppAction {
    type: AppActionType.INIT_SELLING;
    payload: {
        storeId: string;
        code: string;
    };
}

export type AppAction =
    | AppUIEnableAction
    | AppUIDisableAction
    | AppInitSuccessAction
    | AppInitFailureAction
    | AppInitSellingFailureAction
    | AppInitSellingAction;

export const makeAppUIEnableAction = (): AppUIEnableAction => ({
    type: AppActionType.UI_ENABLE,
});

export const makeAppUIDisableAction = (): AppUIDisableAction => ({
    type: AppActionType.UI_DISABLE,
});

export const makeAppInitSuccessAction = (): AppInitSuccessAction => ({
    type: AppActionType.INIT_SUCCESS,
});

export const makeAppInitFailureAction = (): AppInitFailureAction => ({
    type: AppActionType.INIT_FAILURE,
});

export const makeAppInitAction = (): AppInitAction => ({
    type: AppActionType.INIT_APP,
});

export const makeAppInitSellingFailureAction = (errorText: string): AppInitSellingFailureAction => ({
    type: AppActionType.INIT_SELLING_FAILURE,
    payload: {
        errorText,
    },
});

export const makeAppInitSellingAction = (storeId: string, code: string): AppInitSellingAction => ({
    type: AppActionType.INIT_SELLING,
    payload: {
        storeId,
        code,
    },
});
