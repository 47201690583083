import { captureException } from '@sentry/nextjs';
import { SagaIterator } from 'redux-saga';
import { all, call, delay, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { actionTypes, change, updateSyncErrors } from 'redux-form';

import { appConfig } from 'config/app';

import { activatedSaga } from 'core/sagas/sagas';
import { LocalStorageKey, setLocalStorageItem } from 'core/storage/storage';

import { uiBlockingSagaWrapper } from '../app/sagas';
import { formPostRequestSaga, postRequestSaga } from '../rest/sagas';
import { correlationIdSelector, phoneNumberSelector } from './selectors';
import {
    makeSignupSaveMetricsSuccessAction,
    makeSignupSubmitOTPSuccessAction,
    makeSignupSubmitUsernameSuccessAction,
    makeSignupUpdateTimerAction,
    SignupActionType,
    SignupSaveMetricsAction,
} from './actions';

import { Endpoint } from 'Endpoint';

import { Form } from 'forms/types';
import { UserRole } from 'types';
import { RoutePath } from '../../RoutePath';
import { setJwt, setRole } from '../../core/auth/auth';
import { makeSnackbarErrorAction } from '../snackbar/actions';

export function* signupSaga(): SagaIterator {
    yield all([
        fork(activatedSaga, signupProcessSaga, SignupActionType.ACTIVATE, SignupActionType.DEACTIVATE),
        takeEvery(SignupActionType.SUBMIT_USERNAME_REQUEST, resendTimerSaga),
        takeEvery(SignupActionType.SAVE_METRICS, saveMetricsSaga),
        takeEvery(actionTypes.UPDATE_SYNC_ERRORS, function* (action: any) {
            const { meta, payload } = action;
            if (meta.form === Form.SIGNUP_CODE_FORM && payload?.syncErrors?.otp) {
                yield put(change(Form.SIGNUP_CODE_FORM, 'otp', ''));
            }
        }),
        takeEvery('RESEND', resendSaga),
    ]);
}

function* resetPasswordFillOtpSaga(): SagaIterator {
    while (true) {
        const otpChangeAction = yield take(actionTypes.CHANGE);
        const { meta, payload } = otpChangeAction;
        if (meta.form === Form.SIGNUP_CODE_FORM && meta.field === 'otp') {
            yield put(updateSyncErrors(Form.SIGNUP_CODE_FORM, {}, null));
            if (payload.replace(/\s+/g, '').length === 6) {
                return payload;
            }
        }
    }
}

export function* signupProcessSaga(): SagaIterator {
    while (true) {
        try {
            const {
                payload: { username, password, captcha },
            } = yield take(SignupActionType.SUBMIT_USERNAME_REQUEST);
            yield call(
                uiBlockingSagaWrapper,
                formPostRequestSaga,
                Form.SIGNUP_FORM,
                Endpoint.SIGNUP_OTP,
                { role: UserRole.CUSTOMER },
                { username, captcha },
            );
            yield put(makeSignupSubmitUsernameSuccessAction(username));
            while (true) {
                try {
                    const otp = yield call(resetPasswordFillOtpSaga);
                    const correlationId = yield select(correlationIdSelector);
                    yield call(
                        uiBlockingSagaWrapper,
                        formPostRequestSaga,
                        Form.SIGNUP_CODE_FORM,
                        Endpoint.SIGNUP_VENDOR,
                        {},
                        { username, password, otp },
                        {},
                        { correlationId },
                    );
                    break;
                } catch (e) {
                    captureException(e);
                    yield put(makeSnackbarErrorAction('auth:errors.login'));
                }
            }

            const body = { username, password };
            const headers = { 'X-Login-As': UserRole.CUSTOMER };
            const response: AxiosResponse = yield call(
                uiBlockingSagaWrapper,
                postRequestSaga,
                Endpoint.LOGIN,
                {},
                body,
                headers,
            );
            setJwt(response.headers.authorization);
            setRole(UserRole.CUSTOMER);
            setLocalStorageItem(LocalStorageKey.PHONE, username);
            window.location.assign(RoutePath.FEED);
        } catch (e) {
            captureException(e);
            yield put(makeSnackbarErrorAction('auth:errors.password'));
        }
    }
    // }
}

function* resendSaga(): SagaIterator {
    const username = yield select(phoneNumberSelector);
    yield call(uiBlockingSagaWrapper, postRequestSaga, Endpoint.SIGNUP_OTP, { role: UserRole.VENDOR }, { username });
    yield call(resendTimerSaga);
}

function* resendTimerSaga(): SagaIterator {
    let timeLeft = appConfig.otpTimeout;
    while (true) {
        if (timeLeft <= 0) {
            yield put(makeSignupUpdateTimerAction(0));
            break;
        } else {
            yield put(makeSignupUpdateTimerAction(timeLeft));
        }
        yield delay(1e3);
        timeLeft--;
    }
}

function* saveMetricsSaga(action: SignupSaveMetricsAction): SagaIterator {
    try {
        const { correlationId, utm_source } = action.payload;
        const newCorrelationId = !correlationId
            ? yield call(postRequestSaga, Endpoint.METRICS_WITHOUT_CORRELATION_ID, {
                  source: 'registration',
                  utm_source: utm_source || '',
              })
            : yield call(postRequestSaga, Endpoint.METRICS, { source: 'registration', utm_source: '', correlationId });
        yield put(makeSignupSaveMetricsSuccessAction(newCorrelationId?.data));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('auth:errors.registration'));
    }
}
