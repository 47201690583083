import { SnackbarAction, SnackbarActionType } from './actions';

export enum SnackbarMessageType {
    CUSTOMER_ORDER_WAITING_FOR_APPROVAL = 'CUSTOMER_ORDER_WAITING_FOR_APPROVAL',
    CUSTOMER_ORDER_PREPARING = 'CUSTOMER_ORDER_PREPARING',
    CUSTOMER_ORDER_DELIVERY = 'CUSTOMER_ORDER_DELIVERY',
    SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_REQUEST',
    VENDOR_ORDER_NEW = 'VENDOR_ORDER_NEW',
    SUCCESS_COPY = 'SUCCESS_COPY',
    CHECKOUT_ONE_AVAILABLE_DELIVERY_TYPE = 'CHECKOUT_ONE_AVAILABLE_DELIVERY_TYPE',
    CHECKOUT_ONE_AVAILABLE_PAYMENT_TYPE = 'CHECKOUT_ONE_AVAILABLE_PAYMENT_TYPE',
}

export interface SnackbarState {
    messageType: SnackbarMessageType;
    params: Record<string, any>;
    message: string;
}

export interface SnackbarSlice {
    snackbar: SnackbarState;
}

const snackbarInitialState: SnackbarState = {
    messageType: null,
    params: {},
    message: null,
};

export const snackbarReducer = (state = snackbarInitialState, action: SnackbarAction): SnackbarState => {
    switch (action.type) {
        case SnackbarActionType.CREATE:
            const { messageType, params } = action.payload;
            return {
                ...state,
                messageType,
                params,
            };
        case SnackbarActionType.CLEAR:
            return snackbarInitialState;
        case SnackbarActionType.ERROR: {
            const { message } = action.payload;
            return {
                ...state,
                message,
            };
        }
        default:
            return state;
    }
};
