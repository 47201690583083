import { CatalogAction, CatalogActionType } from './actions';
import { ProductDto, ProductOption } from 'types';

export interface CatalogState {
    categories: any[];
    products: {
        [key: string]: any[];
    };
    productsSlice: ProductOption[];
    product: ProductDto;
}

export interface CatalogSlice {
    catalog: CatalogState;
}

const initialCatalogState: CatalogState = {
    categories: [],
    products: {},
    productsSlice: [],
    product: null,
};

export const catalogReducer = (state = initialCatalogState, action: CatalogAction): CatalogState => {
    switch (action.type) {
        case CatalogActionType.FETCH_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.payload,
            };
        case CatalogActionType.FETCH_PRODUCTS_SUCCESS:
            const products: Record<string, ProductDto[]> = {};
            const productsSlice: ProductOption[] = [];
            action.payload.products.forEach(product => {
                productsSlice.push({ productId: product.productId, name: product.name });
                const {
                    category: { categoryId },
                } = product;
                if (!products[categoryId]) {
                    products[categoryId] = [];
                }
                products[categoryId].push(product);
            });
            // restore sortOrder for each product's group
            Object.entries(products).forEach(([_, categoryProducts]) =>
                categoryProducts.sort((a, b) => a.sortOrder - b.sortOrder),
            );
            return {
                ...state,
                products,
                productsSlice,
            };
        case CatalogActionType.FETCH_PRODUCT_SUCCESS:
            return {
                ...state,
                product: action.payload.product,
            };
        case CatalogActionType.SUBMIT_PRODUCT_SUCCESS:
            return {
                ...state,
                product: null,
            };
        case CatalogActionType.CHANGE_CATEGORY_PRODUCTS_ORDER_SUCCESS:
            return {
                ...state,
                products: {
                    ...state.products,
                    ...action.payload.category,
                },
            };
        default:
            return state;
    }
};
