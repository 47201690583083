import { SnackbarSlice } from './reducer';

export const snackbarMessageTypeSelector = (slice: SnackbarSlice) => {
    return slice.snackbar.messageType;
};

export const snackbarParamsSelector = (slice: SnackbarSlice) => {
    return slice.snackbar.params;
};

export const snackbarMessageSelector = (slice: SnackbarSlice) => {
    return slice.snackbar.message;
};
