export enum DeliveryServiceFormTab {
    WEIGHT = 'WEIGHT',
    PLAN = 'PLAN',
    DETAILS = 'DETAILS',
}

export interface SubmitDeliveryDetailsFormData {
    weight: number;
}

export interface DeliveryAddressCoordinates {
    lat: number;
    lon: number;
    qc_geo?: number;
    internalQcGeo?: number;
}

export interface DeliveryPlan {
    aliasName: string;
    deliveryType: number;
    description: string;
    id: number;
    name: string;
    pickupType: number;
    providerKey: string;
    weightMax: number;
    weightMin: number;
}

export interface DeliveryServiceOptionsOrder {
    clientNumber: string;
    weight: number;
    providerKey: string;
    pickupType: number;
    deliveryType: number;
    tariffId: number;
}

export interface DeliveryServiceOptionsCost {
    assessedCost: number;
    codCost: number;
    isDeliveryPayedByRecipient: boolean;
}

export interface DeliveryServiceOptionsSender {
    addressString: string;
    lat: number;
    lng: number;
    countryCode: string;
    companyName: string;
    contactName: string;
    phone: string;
    email: string;
}

export interface DeliveryServiceOptionsRecipient {
    addressString: string;
    countryCode: string;
    contactName: string;
    phone: string;
    lat: number;
    lng: number;
}

export interface DeliveryServiceOptionsPlaceItem {
    description: string;
    quantity: number;
    weight: number;
}

export interface DeliveryServiceOptionsPlace {
    height: number;
    length: number;
    width: number;
    weight: number;
    items: DeliveryServiceOptionsPlaceItem[];
}

export interface DeliveryServiceOptions {
    order: DeliveryServiceOptionsOrder;
    cost: DeliveryServiceOptionsCost;
    sender: DeliveryServiceOptionsSender;
    recipient: DeliveryServiceOptionsRecipient;
    places: DeliveryServiceOptionsPlace[];
}

export interface DeliveryServiceFormData {
    senderAddress: string;
    senderPhone: string;
    senderEmail: string;
    recipientAddress: string;
    recipientPhone: string;
    recipientName: string;
    planId: number;
    weight: number;
    width: number;
    height: number;
    length: number;
    senderAddressCoordinates: DeliveryAddressCoordinates;
    recipientAddressCoordinates: DeliveryAddressCoordinates;
}
