import { ProductAction, ProductActionType } from './actions';

import { ProductDto, RequestStatus } from 'types';

export interface ProductState {
    status: RequestStatus;
    product: ProductDto;
}

export interface ProductSlice {
    product: ProductState;
}

const productInitialState: ProductState = {
    status: RequestStatus.NONE,
    product: null,
};

export const productReducer = (state = productInitialState, action: ProductAction): ProductState => {
    switch (action.type) {
        case ProductActionType.FETCH_REQUEST:
            return {
                ...state,
                status: RequestStatus.PENDING,
            };
        case ProductActionType.FETCH_SUCCESS:
            return {
                ...state,
                status: RequestStatus.SUCCESS,
                product: action.payload.product,
            };
        case ProductActionType.FETCH_FAILURE:
            return {
                ...state,
                status: RequestStatus.FAILURE,
                product: null,
            };
        default:
            return state;
    }
};
