import { DateTime } from 'luxon';

import { DayOfWeek } from 'types';

export const formatISODate = (v: string) => DateTime.fromISO(v).toLocaleString(DateTime.DATE_MED);
export const formatISOAllDate = (v: string) => DateTime.fromISO(v).toLocaleString(DateTime.DATE_FULL);

export const formatISOShortDate = (v: string) =>
    DateTime.fromISO(v).toLocaleString(DateTime.DATE_SHORT).replace(/\//g, '.');

export const formatToISO = (v?: string) => v && DateTime.fromFormat(v, 'dd.mm.yyyy').toFormat('yyyy-mm-dd');

export const formatFromISO = (v?: string) => v && DateTime.fromFormat(v, 'yyyy-mm-dd').toFormat('dd.mm.yyyy');

export const formatISODateTime = (v: string) => DateTime.fromISO(v).toLocaleString(DateTime.DATETIME_MED);

export const formatISOTime = (v: string) => DateTime.fromISO(v).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

export const numDaysBetween = (d1: Date, d2: Date) => {
    const diff = Math.abs(d1.getTime() - d2.getTime());
    return diff / (1000 * 3600 * 24);
};

export const daysOfWeek: Record<number, DayOfWeek> = {
    0: DayOfWeek.MONDAY,
    1: DayOfWeek.TUESDAY,
    2: DayOfWeek.WEDNESDAY,
    3: DayOfWeek.THURSDAY,
    4: DayOfWeek.FRIDAY,
    5: DayOfWeek.SATURDAY,
    6: DayOfWeek.SUNDAY,
};
