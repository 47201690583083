import { OrderListResponse, OrderResponse, OrderStatsResponse } from './types';
import { OrderChangeStatusIntent } from 'types';

export enum OrderListFilterStatus {
    NEW = 'NEW',
    ACTIVE = 'ACTIVE',
    CLOSED = 'CLOSED',
}

export enum OrderActionType {
    ACTIVATE = 'order/ACTIVATE',
    DEACTIVATE = 'order/DEACTIVATE',
    NEW_ORDERS_ACTIVATE = 'order/NEW_ORDERS_ACTIVATE',
    NEW_ORDERS_DEACTIVATE = 'order/NEW_ORDERS_DEACTIVATE',
    ACTIVE_ORDERS_ACTIVATE = 'order/ACTIVE_ORDERS_ACTIVATE',
    ACTIVE_ORDERS_DEACTIVATE = 'order/ACTIVE_ORDERS_DEACTIVATE',
    CLOSED_ORDERS_ACTIVATE = 'order/CLOSED_ORDERS_ACTIVATE',
    CLOSED_ORDERS_DEACTIVATE = 'order/CLOSED_ORDERS_DEACTIVATE',
    FETCH_STATS_REQUEST = 'order/FETCH_STATS_REQUEST',
    FETCH_STATS_SUCCESS = 'order/FETCH_STATS_SUCCESS',
    FETCH_STATS_FAILURE = 'order/FETCH_STATS_FAILURE',
    FETCH_LIST_SUCCESS = 'order/FETCH_LIST_SUCCESS',
    FETCH_LIST_FAILURE = 'order/FETCH_LIST_FAILURE',
    FETCH_ITEM_REQUEST = 'order/FETCH_ITEM_REQUEST',
    FETCH_ITEM_SUCCESS = 'order/FETCH_ITEM_SUCCESS',
    FETCH_ITEM_FAILURE = 'order/FETCH_ITEM_FAILURE',
    ACCEPT = 'order/ACCEPT',
    DECLINE = 'order/DECLINE',
    FINISH_PREPARING = 'order/FINISH_PREPARING',
    FINISH_DELIVERY = 'order/FINISH_DELIVERY',
    SEND_FOR_APPROVAL = 'order/SEND_FOR_APPROVAL',
    CHANGE_STATUS = 'order/CHANGE_STATUS',
    UPDATE_ERROR_MESSAGE = 'order/UPDATE_ERROR_MESSAGE',
}

export interface BaseOrderAction {
    type: OrderActionType;
}

export interface OrderFetchStatsRequestAction extends BaseOrderAction {
    type: OrderActionType.FETCH_STATS_REQUEST;
}

export interface OrderFetchStatsSuccessAction extends BaseOrderAction {
    type: OrderActionType.FETCH_STATS_SUCCESS;
    payload: {
        response: OrderStatsResponse;
    };
}

export interface OrderFetchStatsFailureAction extends BaseOrderAction {
    type: OrderActionType.FETCH_STATS_FAILURE;
}

export interface OrderFetchListSuccessAction extends BaseOrderAction {
    type: OrderActionType.FETCH_LIST_SUCCESS;
    payload: {
        status: OrderListFilterStatus;
        response: OrderListResponse;
    };
}

export interface OrderFetchListFailureAction extends BaseOrderAction {
    type: OrderActionType.FETCH_LIST_FAILURE;
}

export interface OrderFetchItemRequestAction extends BaseOrderAction {
    type: OrderActionType.FETCH_ITEM_REQUEST;
    payload: {
        orderId: string;
    };
}

export interface OrderFetchItemSuccessAction extends BaseOrderAction {
    type: OrderActionType.FETCH_ITEM_SUCCESS;
    payload: {
        response: OrderResponse;
    };
}

export interface OrderFetchItemFailureAction extends BaseOrderAction {
    type: OrderActionType.FETCH_ITEM_FAILURE;
}

export interface OrderAcceptAction extends BaseOrderAction {
    type: OrderActionType.ACCEPT;
    payload: {
        orderId: string;
    };
}

export interface OrderDeclineAction extends BaseOrderAction {
    type: OrderActionType.DECLINE;
    payload: {
        orderId: string;
    };
}

export interface OrderFinishPreparingAction extends BaseOrderAction {
    type: OrderActionType.FINISH_PREPARING;
    payload: {
        orderId: string;
    };
}

export interface OrderFinishDeliveryAction extends BaseOrderAction {
    type: OrderActionType.FINISH_DELIVERY;
    payload: {
        orderId: string;
    };
}

export interface OrderSendForApprovalAction extends BaseOrderAction {
    type: OrderActionType.SEND_FOR_APPROVAL;
    payload: {
        quantities: Record<string, number>;
    };
}

export interface OrderChangeStatusAction extends BaseOrderAction {
    type: OrderActionType.CHANGE_STATUS;
    payload: {
        orderId: string;
        intent: OrderChangeStatusIntent;
    };
}

export interface OrderUpdateErrorMessageAction extends BaseOrderAction {
    type: OrderActionType.UPDATE_ERROR_MESSAGE;
    payload: {
        errorMessage: string;
    };
}

export type OrderAction =
    | OrderFetchStatsRequestAction
    | OrderFetchStatsSuccessAction
    | OrderFetchStatsFailureAction
    | OrderFetchListSuccessAction
    | OrderFetchListFailureAction
    | OrderFetchItemRequestAction
    | OrderFetchItemSuccessAction
    | OrderFetchItemFailureAction
    | OrderAcceptAction
    | OrderDeclineAction
    | OrderFinishPreparingAction
    | OrderFinishDeliveryAction
    | OrderSendForApprovalAction
    | OrderChangeStatusAction
    | OrderUpdateErrorMessageAction;

export const makeOrderFetchStatsRequestAction = (): OrderFetchStatsRequestAction => ({
    type: OrderActionType.FETCH_STATS_REQUEST,
});

export const makeOrderFetchStatsSuccessAction = (response: OrderStatsResponse): OrderFetchStatsSuccessAction => ({
    type: OrderActionType.FETCH_STATS_SUCCESS,
    payload: {
        response,
    },
});

export const makeOrderFetchStatsFailureAction = (): OrderFetchStatsFailureAction => ({
    type: OrderActionType.FETCH_STATS_FAILURE,
});

export const makeOrderFetchListSuccessAction = (
    status: OrderListFilterStatus,
    response: OrderListResponse,
): OrderFetchListSuccessAction => ({
    type: OrderActionType.FETCH_LIST_SUCCESS,
    payload: {
        status,
        response,
    },
});

export const makeOrderFetchListFailureAction = (): OrderFetchListFailureAction => ({
    type: OrderActionType.FETCH_LIST_FAILURE,
});

export const makeOrderFetchItemRequestAction = (orderId: string): OrderFetchItemRequestAction => ({
    type: OrderActionType.FETCH_ITEM_REQUEST,
    payload: {
        orderId,
    },
});

export const makeOrderFetchItemSuccessAction = (response: OrderResponse): OrderFetchItemSuccessAction => ({
    type: OrderActionType.FETCH_ITEM_SUCCESS,
    payload: {
        response,
    },
});

export const makeOrderFetchItemFailureAction = (): OrderFetchItemFailureAction => ({
    type: OrderActionType.FETCH_ITEM_FAILURE,
});

export const makeOrderAcceptAction = (orderId: string): OrderAcceptAction => ({
    type: OrderActionType.ACCEPT,
    payload: {
        orderId,
    },
});

export const makeOrderDeclineAction = (orderId: string): OrderDeclineAction => ({
    type: OrderActionType.DECLINE,
    payload: {
        orderId,
    },
});

export const makeOrderFinishPreparingAction = (orderId: string): OrderFinishPreparingAction => ({
    type: OrderActionType.FINISH_PREPARING,
    payload: {
        orderId,
    },
});

export const makeOrderFinishDeliveryAction = (orderId: string): OrderFinishDeliveryAction => ({
    type: OrderActionType.FINISH_DELIVERY,
    payload: {
        orderId,
    },
});

export const makeOrderSendForApprovalAction = (quantities: Record<string, number>): OrderSendForApprovalAction => ({
    type: OrderActionType.SEND_FOR_APPROVAL,
    payload: {
        quantities,
    },
});

export const makeOrderChangeStatusAction = (
    orderId: string,
    intent: OrderChangeStatusIntent,
): OrderChangeStatusAction => ({
    type: OrderActionType.CHANGE_STATUS,
    payload: {
        orderId,
        intent,
    },
});

export const makeOrderUpdateErrorMessageAction = (errorMessage: string): OrderUpdateErrorMessageAction => ({
    type: OrderActionType.UPDATE_ERROR_MESSAGE,
    payload: {
        errorMessage,
    },
});
