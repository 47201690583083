import { StoreSlice } from './reducer';

export const storeSelector = (state: StoreSlice) => {
    return state.store.store;
};

export const storeStateSelector = (state: StoreSlice) => {
    return state.store;
};

export const makeStoreSelector = (storeSlug: string) => (state: StoreSlice) => {
    const { store } = state.store;
    if (store && store.customUrl?.toLowerCase() === storeSlug?.toLowerCase()) {
        return store;
    }
    return null;
};

export const makeStoreByIdSelector = (storeId: string) => (state: StoreSlice) => {
    const { store } = state.store;
    if (store && store.storeId === storeId) {
        return store;
    }
    return null;
};

export const storeStatusSelector = (state: StoreSlice) => {
    return state.store.storeStatus;
};

export const storeProductsSelector = (state: StoreSlice) => {
    return state.store.products;
};

export const storeCategoriesSelector = (state: StoreSlice) => {
    return state.store.categories;
};

export const storePromosSelector = (state: StoreSlice) => {
    return state.store.promos || [];
};

export const storeActivePromosSelector = (state: StoreSlice) => {
    return (state.store.promos || []).filter(promo => promo.active);
};

export const isStoreOwnerFakeSelector = (state: StoreSlice) => {
    return state?.store?.store?.fakeOwner || false;
};

export const isStoreModeratedSelector = (state: StoreSlice) => {
    return state?.store?.store?.moderated || false;
};

export const storeSubscriptionSelector = (state: StoreSlice) => {
    return state?.store?.subscription;
};
export const storePhoneLinkSelector = (state: StoreSlice) => {
    return state?.store?.phone;
};

export const storeSubscriptionRenewalDateSelector = (state: StoreSlice) => {
    return state?.store?.subscription?.renewalDate;
};
