import { OnboardingAction, OnboardingActionType } from './actions';

import { OnboardingScreen, SellerFormData, StoreFormData } from 'pages/Onboarding/types';

export interface OnboardingState {
    screen: OnboardingScreen;
    sellerFormData: SellerFormData | null;
    storeFormData: StoreFormData | null;
}

const initialOnboardingState: OnboardingState = {
    screen: OnboardingScreen.SELLER,
    sellerFormData: null,
    storeFormData: null,
};

export const onboardingReducer = (state = initialOnboardingState, action: OnboardingAction): OnboardingState => {
    switch (action.type) {
        case OnboardingActionType.SET_SCREEN:
            return {
                ...state,
                screen: action.payload.onboardingScreen,
            };
        case OnboardingActionType.BACK:
            return {
                ...state,
                screen: state.screen - 1,
            };
        case OnboardingActionType.SAVE_SELLER_FORM_DATA:
            return {
                ...state,
                sellerFormData: action.payload,
                screen: state.screen + 1,
            };
        case OnboardingActionType.FETCH_STORE_SUCCESS:
            return {
                ...state,
                storeFormData: action.payload,
            };
        case OnboardingActionType.SAVE_STORE_FORM_DATA:
            return {
                ...state,
                storeFormData: action.payload,
                screen: action.meta.skipPhoneVerification ? state.screen : state.screen + 1,
            };
        case OnboardingActionType.INIT_FAKE_STORE:
            return {
                ...state,
                screen: OnboardingScreen.STORE,
            };
        default:
            return state;
    }
};
