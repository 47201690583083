import { PromoActionType, PromoAction } from './actions';
import { PromoDto } from 'types';

export interface PromoState {
    promos: PromoDto[];
    promo: PromoDto;
}

export interface PromoSlice {
    promo: PromoState;
}

const initialPromoState: PromoState = {
    promos: [],
    promo: null,
};

export const promoReducer = (state = initialPromoState, action: PromoAction): PromoState => {
    switch (action.type) {
        case PromoActionType.FETCH_PROMOS_SUCCESS:
            return {
                ...state,
                promos: action.payload.promos,
            };
        case PromoActionType.SUBMIT_PROMO_SUCCESS:
            return {
                ...state,
                promo: null,
            };
        case PromoActionType.FETCH_PROMO_ENTITY_SUCCESS:
            return {
                ...state,
                promo: action.payload.promo,
            };
        default:
            return state;
    }
};
