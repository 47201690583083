import { captureException } from '@sentry/nextjs';
import { StoreDetailsSubmitRequest, WorkingHours } from '../storeDetails/types';
import {
    makeUserMenuAddressesFetchSuccessAction,
    makeUserMenuAddressFetchSuccessAction,
    makeUserMenuNavigateAction,
    makeUserMenuOrderFetchSuccessAction,
    makeUserMenuOrdersFetchSuccessAction,
    makeUserMenuProfileFetchSuccessAction,
    makeUserMenuStoreInfoFetchSuccessAction,
    makeUserMenuStoreNotificationSettingsFetchSuccessAction,
    UserMenuActionType,
    UserMenuAddressSubmitAction,
    UserMenuOrderChangeStatusAction,
    UserMenuProfileSubmitAction,
    UserMenuStoreInfoUpdateAction,
} from './actions';
import { UserMenuSelectedItem } from './reducer';
import { userMenuRoutingParamsSelector, userStoreNotificationSettings } from './selector';
import { AddressFetchData, AddressListItemData, AddressPostData } from './types';
import { Endpoint } from 'Endpoint';
import { RoutePath } from 'RoutePath';
import { AxiosResponse } from 'axios';
import { appConfig } from 'config/app';
import { daysOfWeek, formatFromISO, formatToISO } from 'core/date/date';
import { findChangedOrder } from 'core/orders/orders';
import { activatedSaga, redirectSaga } from 'core/sagas/sagas';
import { hasDataChanged, splitOnce } from 'core/utils/utils';
import { Form } from 'forms/types';
import { DateTime } from 'luxon';
import { SagaIterator } from 'redux-saga';
import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { makeAppUIDisableAction, makeAppUIEnableAction } from 'state/app/actions';
import { authRoleSelector, authStoreIdSelector } from 'state/auth/selectors';
import {
    deleteRequestSaga,
    formPostRequestSaga,
    formPutRequestSaga,
    getMessagesFromError,
    getRequestSaga,
    handleFormErrorSaga,
    postRequestSaga,
    putRequestSaga,
} from 'state/rest/sagas';
import { makeSnackbarCreateAction, makeSnackbarErrorAction } from 'state/snackbar/actions';
import { SnackbarMessageType } from 'state/snackbar/reducer';
import { convertTime, storeDetailsSubmitImageSaga } from 'state/storeDetails/sagas';
import {
    ImageDto,
    ImageType,
    NotificationType,
    OrderChangeStatusIntent,
    OrderStatus,
    ScheduleDayDto,
    StoreDto,
    StoreNotificationDto,
    UserRole,
    VendorContactType,
} from 'types';

const isServer = typeof window === 'undefined';

export function* userMenuSaga(): SagaIterator {
    yield takeEvery(UserMenuActionType.SUBMIT_PROFILE, userMenuProfileSubmitSaga);
    yield takeEvery(UserMenuActionType.FETCH_PROFILE, userMenuProfileFetchSaga);
    yield takeEvery(UserMenuActionType.FETCH_ADDRESSES, userMenuFetchAddressesSaga);
    yield takeEvery(UserMenuActionType.FETCH_STORE_INFO, userMenuStoreInfoFetchSaga);
    yield takeEvery(UserMenuActionType.UPDATE_STORE_INFO, userMenuStoreInfoUpdateSaga);
    yield takeEvery(UserMenuActionType.CHANGE_STATUS, userMenuChangeStatusSaga);
    yield takeEvery(UserMenuActionType.FETCH_ADDRESS, userMenuFetchAddressSaga);
    yield takeEvery(UserMenuActionType.SUBMIT_ADDRESS, userMenuSubmitAddressSaga);
    yield takeEvery(UserMenuActionType.DELETE_ADDRESS, userMenuDeleteAddressSaga);
    yield takeEvery(UserMenuActionType.FETCH_ORDERS, userMenuOrdersSaga);
    if (!isServer) {
        yield all([
            call(
                activatedSaga,
                userMenuOrdersSaga,
                UserMenuActionType.ORDERS_ACTIVATE,
                UserMenuActionType.ORDERS_DEACTIVATE,
            ),
            call(
                activatedSaga,
                userMenuOrderSaga,
                UserMenuActionType.ORDER_ACTIVATE,
                UserMenuActionType.ORDER_DEACTIVATE,
            ),
            call(userMenuOrdersWatchSaga),
        ]);
    }
}

function* userMenuProfileSubmitSaga(action: UserMenuProfileSubmitAction): SagaIterator {
    try {
        yield call(
            formPutRequestSaga,
            Form.PROFILE_FORM,
            Endpoint.PROFILE,
            {},
            {
                name: action.payload.name || null,
                email: action.payload.email || null,
                birthDate: formatToISO(action.payload.birthDate) || null,
            },
        );
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('auth:errors.user'));
    }
}

function* userMenuProfileFetchSaga(): SagaIterator {
    try {
        const response = yield call(getRequestSaga, Endpoint.PROFILE);
        const formatedResponse = { ...response.data, birthDate: formatFromISO(response.data.birthDate) };
        yield put(makeUserMenuProfileFetchSuccessAction(formatedResponse));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('auth:errors.user'));
    }
}

const notificationStatuses: Record<OrderStatus, SnackbarMessageType> = {
    [OrderStatus.WAITING_FOR_APPROVAL]: SnackbarMessageType.CUSTOMER_ORDER_WAITING_FOR_APPROVAL,
    [OrderStatus.PREPARING]: SnackbarMessageType.CUSTOMER_ORDER_PREPARING,
    [OrderStatus.DELIVERY]: SnackbarMessageType.CUSTOMER_ORDER_DELIVERY,
    [OrderStatus.NEW]: null,
    [OrderStatus.UNPAID]: null,
    [OrderStatus.CLOSED]: null,
};

function* userMenuOrdersWatchSaga(): SagaIterator {
    let previousData = null;
    while (true) {
        const userRole = yield select(authRoleSelector);
        if (userRole === UserRole.CUSTOMER) {
            try {
                const { data } = yield call(getRequestSaga, Endpoint.ORDERS);
                if (hasDataChanged(previousData, data)) {
                    if (previousData) {
                        const changedOrder = findChangedOrder(previousData, data);
                        if (changedOrder) {
                            const { orderId, totalPrice, orderStatus } = changedOrder;
                            const params = yield select(userMenuRoutingParamsSelector);
                            const messageType = notificationStatuses[orderStatus];
                            if (params && params.orderId === orderId) {
                                const { data } = yield call(getRequestSaga, Endpoint.ORDER, { orderId });
                                yield put(makeUserMenuOrderFetchSuccessAction(data));
                            } else if (messageType) {
                                yield put(makeSnackbarCreateAction(messageType, { orderId, totalPrice }));
                            }
                        }
                    }
                    const successAction = yield call(makeUserMenuOrdersFetchSuccessAction, data);
                    yield put(successAction);
                    previousData = data;
                }
            } catch (e) {
                captureException(e);
                yield put(makeSnackbarErrorAction('auth:errors.changedOrder'));
            }
        }
        yield delay(appConfig.orderRefreshInterval);
    }
}

function* userMenuOrdersSaga(): SagaIterator {
    try {
        const { data } = yield call(getRequestSaga, Endpoint.ORDERS);
        yield put(makeUserMenuOrdersFetchSuccessAction(data));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('auth:errors.userMenuOrders'));
    }
}

function* userMenuOrderSaga(): SagaIterator {
    try {
        const { orderId } = yield select(userMenuRoutingParamsSelector);
        const { data } = yield call(getRequestSaga, Endpoint.ORDER, { orderId });
        const r = yield call(getRequestSaga, `/api/store/${data.storeId}` as any);
        const phoneNumber = (r.data.contacts || []).find((contact: any) => contact.type === VendorContactType.PHONE);
        yield put(makeUserMenuOrderFetchSuccessAction({ ...data, phoneNumber }));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('auth:errors.phoneNumber'));
    }
}

function* userMenuFetchAddressesSaga(): SagaIterator {
    try {
        const { data }: AxiosResponse<AddressFetchData[]> = yield call(getRequestSaga, Endpoint.ADDRESS);
        const listData: AddressListItemData[] = data.map(address => ({
            title: address.title,
            address: `${address.city.name}, ${address.street.name}`,
            addressId: address.addressId,
            isDefault: address.defaultAddress,
        }));
        yield put(makeUserMenuAddressesFetchSuccessAction(listData));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('auth:errors.address'));
    }
}

function* userMenuFetchAddressSaga(): SagaIterator {
    try {
        const params = yield select(userMenuRoutingParamsSelector);
        if (params && params.addressId) {
            const response: AxiosResponse<AddressFetchData> = yield call(getRequestSaga, Endpoint.ADDRESS_BY_ID, {
                addressId: params.addressId,
            });
            const { geoCoordinates, ...address } = response.data;
            yield put(
                makeUserMenuAddressFetchSuccessAction({
                    title: address.title,
                    address: `${address.city.name}, ${address.street.name}`,
                    coordinates: {
                        lat: geoCoordinates.latitude,
                        lon: geoCoordinates.longitude,
                    },
                    comment: address.comment,
                    isDefault: address.defaultAddress,
                }),
            );
        }
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('auth:errors.address'));
    }
}

function* userMenuSubmitAddressSaga(action: UserMenuAddressSubmitAction): SagaIterator {
    try {
        const routeParams = yield select(userMenuRoutingParamsSelector);
        const address = action.payload;
        const [city, street] = splitOnce(address.address, ', ');
        const { lat, lon } = address.coordinates;
        const data: AddressPostData = {
            city,
            street,
            title: address.title,
            comment: address.comment,
            defaultAddress: address.isDefault,
            geoCoordinates: {
                latitude: lat,
                longitude: lon,
            },
        };
        yield call(formPostRequestSaga, Form.ADDRESS_FORM, Endpoint.ADDRESS, {}, data);
        if (routeParams?.addressId) {
            yield call(deleteRequestSaga, Endpoint.ADDRESS_BY_ID, { addressId: routeParams.addressId });
        }
        yield put(makeUserMenuNavigateAction(UserMenuSelectedItem.ADDRESSES));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('auth:errors.address'));
    }
}

function* userMenuDeleteAddressSaga(): SagaIterator {
    const { addressId } = yield select(userMenuRoutingParamsSelector);
    yield call(deleteRequestSaga, Endpoint.ADDRESS_BY_ID, { addressId });
    yield put(makeUserMenuNavigateAction(UserMenuSelectedItem.ADDRESSES));
}

function* changeOrderStatusSaga(orderId: string, orderStatus: OrderStatus, userNotes?: string): SagaIterator {
    try {
        yield put(makeAppUIDisableAction());
        const body = { orderStatus, userNotes };
        yield call(putRequestSaga, Endpoint.ORDER_STATUS, { orderId }, body);
        yield call(userMenuOrderSaga);
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('auth:errors.orderStatus'));
    } finally {
        yield put(makeAppUIEnableAction());
    }
}

function* userMenuChangeStatusSaga(action: UserMenuOrderChangeStatusAction): SagaIterator {
    const { orderId, intent } = action.payload;
    switch (intent) {
        case OrderChangeStatusIntent.ACCEPT_BY_CUSTOMER: {
            yield call(changeOrderStatusSaga, orderId, OrderStatus.PREPARING);
            break;
        }
        case OrderChangeStatusIntent.DECLINE: {
            yield call(changeOrderStatusSaga, orderId, OrderStatus.CLOSED, 'Declined by customer');
            break;
        }
    }
}

function formatWorkingHours(schedule: ScheduleDayDto[]): WorkingHours {
    return schedule.reduce((acc, day) => {
        const dayNumber = Object.keys(daysOfWeek).find(
            (dayNum: string) => daysOfWeek[Number(dayNum)] === day.dayOfWeek,
        );
        const roundTheClockEnabled =
            DateTime.fromSQL(day.opens) === DateTime.fromObject({ hour: 0, minute: 0 }) &&
            DateTime.fromSQL(day.closes) === DateTime.fromObject({ hour: 23, minute: 59 });
        const breakEnabled = day.timeBreak;
        return {
            ...acc,
            [dayNumber]: {
                roundTheClockEnabled,
                workingHoursEnabled: day.opens,
                workingHoursFrom: DateTime.fromSQL(day.opens),
                workingHoursTo: DateTime.fromSQL(day.closes),
                breakEnabled,
                breakFrom: breakEnabled && DateTime.fromSQL(day.timeBreak.start),
                breakTo: breakEnabled && DateTime.fromSQL(day.timeBreak.end),
            },
        };
    }, {} as WorkingHours);
}

function* userMenuStoreInfoFetchSaga(): SagaIterator {
    try {
        const storeId = yield select(authStoreIdSelector);
        const { data }: { data: StoreDto } = yield call(getRequestSaga, Endpoint.STORE, { storeId });
        const { data: notifications } = yield call(getRequestSaga, Endpoint.STORE_NOTIFICATION_SETTINGS, { storeId });

        if (data) {
            const phone = data.contacts.find(el => el.type === VendorContactType.PHONE);
            const storeInfo: StoreDetailsSubmitRequest = {
                image: data.image?.imageId,
                coverImage: data.coverImage?.imageId,
                coordinates: {
                    lat: data.address.geoCoordinates.latitude,
                    lon: data.address.geoCoordinates.longitude,
                    qc_geo: 0,
                    internalQcGeo: 1,
                }, // qc_geo is used inside async validator
                name: data.name,
                storeCategory: data.storeCategory,
                customUrl: data.customUrl,
                address: data.address.formattedAddress,
                sellingDescription: data.sellingDescription,
                shortDescription: data.shortDescription,
                slogan: data.slogan,
                fullDescription: data.fullDescription,
                advantages: data.advantages,
                // @ts-ignore
                phone: phone?.value,
                workingHours: formatWorkingHours(data.schedule),
                tags: data.tags.map(el => el.name),
                website: data.contacts.find(el => el.type === VendorContactType.SITE)?.value,
                instagram: data.contacts.find(el => el.type === VendorContactType.INSTA)?.value,
                facebook: data.contacts.find(el => el.type === VendorContactType.FB)?.value,
                waPhone: !!data.contacts.find(el => el.type === VendorContactType.WA),
                carousel: data.carouselData,
                standardAddress: data.address,
            };
            yield put(makeUserMenuStoreInfoFetchSuccessAction(storeInfo));
        }
        if (notifications) {
            yield put(makeUserMenuStoreNotificationSettingsFetchSuccessAction(notifications.notificationSettings));
        }
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('auth:errors.info'));
    }
}

function* userMenuStoreInfoUpdateSaga(action: UserMenuStoreInfoUpdateAction): SagaIterator {
    try {
        const storeId = yield select(authStoreIdSelector);
        const {
            address,
            coordinates,
            image,
            coverImage,
            imageCrop,
            coverImageCrop,
            gallery,
            emailNotification,
            smsNotification,
            standardAddress,
            ...rest
        } = action.payload.storeInfo;

        yield put(makeAppUIDisableAction());

        for (let galleryItem of gallery) {
            if (galleryItem.file) {
                galleryItem.imageId = yield call(
                    storeDetailsSubmitImageSaga,
                    galleryItem.file,
                    galleryItem.croppedArea,
                );
            }
        }

        let imageDto: ImageDto;
        if (image) {
            const imageId =
                typeof image === 'string' ? image : yield call(storeDetailsSubmitImageSaga, image, imageCrop);
            imageDto = {
                imageId,
                type: ImageType.STORE,
                defaultImage: true,
            };
        }

        const [city, streetAddress] = splitOnce(address, ', ');
        const addressToSave = standardAddress
            ? {
                  ...standardAddress,
                  geoCoordinates: {
                      latitude: coordinates.lat,
                      longitude: coordinates.lon,
                  },
              }
            : {
                  geoCoordinates: {
                      latitude: coordinates.lat,
                      longitude: coordinates.lon,
                  },
                  city: {
                      name: standardAddress.city?.name ?? city ?? address,
                  },
                  street: {
                      name: standardAddress.street?.name ?? streetAddress ?? address,
                  },
                  formattedAddress: address,
              };
        const body = {
            address: addressToSave,
            contacts: [
                {
                    defaultContact: true,
                    type: VendorContactType.PHONE,
                    value: rest.phone,
                },
                {
                    type: VendorContactType.FB,
                    value: rest.facebook,
                },
                {
                    type: VendorContactType.INSTA,
                    value: rest.instagram,
                },
                {
                    type: VendorContactType.SITE,
                    value: rest.website,
                },
                {
                    type: VendorContactType.WA,
                    value: rest.waPhone ? rest.phone : null,
                },
            ].filter(item => item.value),
            image: imageDto,
            name: rest.name,
            storeCategory: rest.storeCategory,
            customUrl: rest.customUrl,
            sellingDescription: rest.sellingDescription,
            shortDescription: rest.shortDescription,
            fullDescription: rest.fullDescription,
            advantages: rest.advantages,
            tags: rest.tags.map(name => ({ name })),
            schedule: Object.keys(rest.workingHours).map(k => {
                const day = rest.workingHours[+k];
                return {
                    dayOfWeek: daysOfWeek[+k],
                    opens: day.roundTheClockEnabled ? [0, 0] : convertTime(day.workingHoursFrom),
                    closes: day.roundTheClockEnabled ? [23, 59] : convertTime(day.workingHoursTo),
                    timeBreak: rest.workingHours[+k].breakEnabled
                        ? { start: convertTime(day.breakFrom), end: convertTime(day.breakTo) }
                        : null,
                };
            }),
            carouselData: gallery.map((galleryItem, index) => ({
                images: {
                    360: galleryItem.imageId,
                    480: galleryItem.imageId,
                    640: galleryItem.imageId,
                    768: galleryItem.imageId,
                    960: galleryItem.imageId,
                    1280: galleryItem.imageId,
                    1920: galleryItem.imageId,
                },
                url: null,
                description: galleryItem.label,
                order: index + 1,
                styles: {
                    color: galleryItem.color,
                    textAlign: galleryItem.horizontalAlignment,
                    justifyContent: galleryItem.verticalAlignment,
                },
            })),
            slogan: rest.slogan,
        };

        const notificationSettings: StoreNotificationDto[] = [
            {
                notificationMethod: NotificationType.EMAIL,
                type: 'NEW_ORDER',
                notificationSettingId: null,
                enabled: emailNotification || false,
            },
            {
                notificationMethod: NotificationType.PHONE,
                type: 'NEW_ORDER',
                notificationSettingId: null,
                enabled: smsNotification || false,
            },
        ];

        yield call(formPutRequestSaga, Form.STORE_DETAILS_FORM, Endpoint.STORE, { storeId }, body);
        yield call(
            postRequestSaga,
            Endpoint.STORE_NOTIFICATION_SETTINGS,
            { storeId },
            { storeId, notificationSettings },
        );
        yield call(redirectSaga, RoutePath.VENDOR_DASHBOARD);
    } catch (error) {
        captureException(error);
        const { generic, image } = getMessagesFromError(error);
        const message = generic || image;
        if (message) {
            yield put(makeSnackbarErrorAction(message));
        } else {
            yield put(makeSnackbarErrorAction('auth:errors.storeInfo'));
        }
    } finally {
        yield put(makeAppUIEnableAction());
    }
}
