import { CategoryDto, PhoneLinkResponse, ProductDto, PromoDto, StoreDto, SubscriptionResponse } from 'types';
import { CloneStoreFormValue } from '../../forms/CloneStoreForm/CloneStoreForm';

export enum StoreActionType {
    FETCH_NEW_PRODUCTS_PAGE = 'store/FETCH_NEW_PRODUCTS_PAGE',
    FETCH_NEW_PRODUCTS_PAGE_SUCCESS = 'store/FETCH_NEW_PRODUCTS_PAGE_SUCCESS',
    FETCH_REQUEST = 'store/FETCH_REQUEST',
    FETCH_ID_REQUEST = 'store/FETCH_ID_REQUEST',
    FETCH_SUCCESS = 'store/FETCH_SUCCESS',
    FETCH_FAILURE = 'store/FETCH_FAILURE',
    FETCH_STORE_SUCCESS = 'store/FETCH_STORE_SUCCESS',
    FETCH_STORE_FAILURE = 'store/FETCH_STORE_FAILURE',
    FETCH_STORE_SUBSCRIPTION_INFO = 'store/FETCH_STORE_SUBSCRIPTION_INFO',
    FETCH_STORE_PHONE_LINK_INFO = 'store/FETCH_STORE_PHONE_LINK_INFO',
    UPDATE_STORE_OWNER_INFO = 'store/UPDATE_STORE_OWNER_INFO',
    UPDATE_STORE_SUBSCRIPTION_INFO = 'store/UPDATE_STORE_SUBSCRIPTION_INFO',
    DELETE_STORE_REQUEST = 'store/DELETE_STORE_REQUEST',
    ACTIVATE_TRIAL_PERIOD = 'store/ACTIVATE_TRIAL_PERIOD',
    CHANGE_MODERATED_STATUS_REQUEST = 'store/CHANGE_MODERATED_STATUS_REQUEST',
    CHANGE_MODERATED_STATUS_SUCCESS = 'store/CHANGE_MODERATED_STATUS_SUCCESS',
    CREATE_LINK = 'store/CREATE_LINK',
    CLICK_FOR_BUTTON_PHONE = 'store/CLICK_FOR_BUTTON_PHONE',
    CLONE = 'store/CLONE',
}

export interface StoreBaseAction {
    type: StoreActionType;
}

export interface StoreFetchRequestAction extends StoreBaseAction {
    type: StoreActionType.FETCH_REQUEST;
    payload: {
        storeSlug: string;
    };
}

export interface StoreFetchNewProductsPageAction extends StoreBaseAction {
    type: StoreActionType.FETCH_NEW_PRODUCTS_PAGE;
    payload: {
        storeId: string;
        start: string;
    };
}

export interface StoreFetchNewProductsPageSuccessAction extends StoreBaseAction {
    type: StoreActionType.FETCH_NEW_PRODUCTS_PAGE_SUCCESS;
    payload: {
        newProducts: ProductDto[];
    };
}

export interface StoreFetchByIdRequestAction extends StoreBaseAction {
    type: StoreActionType.FETCH_ID_REQUEST;
    payload: {
        storeId: string;
    };
}

export interface StoreFetchByIdSuccessAction extends StoreBaseAction {
    type: StoreActionType.FETCH_STORE_SUCCESS;
    payload: {
        store: StoreDto;
    };
}

export interface StoreFetchByIdFailureAction extends StoreBaseAction {
    type: StoreActionType.FETCH_STORE_FAILURE;
}

export interface StoreFetchSubscriptionInfoAction extends StoreBaseAction {
    type: StoreActionType.FETCH_STORE_SUBSCRIPTION_INFO;
    payload: {
        storeId: string;
    };
}

export interface StoreFetchPhoneLinkAction extends StoreBaseAction {
    type: StoreActionType.FETCH_STORE_PHONE_LINK_INFO;
    payload: {
        storeId: string;
    };
}

export interface StoreUpdateStoreOwnerInfoAction extends StoreBaseAction {
    type: StoreActionType.UPDATE_STORE_OWNER_INFO;
    payload: {
        fakeOwner: boolean;
    };
}

export interface StoreUpdateStoreSubscriptionInfoAction extends StoreBaseAction {
    type: StoreActionType.UPDATE_STORE_SUBSCRIPTION_INFO;
    payload: {
        subscription: SubscriptionResponse;
    };
}

export interface StoreUpdateStorePhoneLinkAction extends StoreBaseAction {
    type: StoreActionType.CREATE_LINK;
    payload: {
        phone: PhoneLinkResponse;
    };
}

export interface StoreFetchSuccessAction extends StoreBaseAction {
    type: StoreActionType.FETCH_SUCCESS;
    payload: {
        store: StoreDto;
        products: ProductDto[];
        categories: CategoryDto[];
        promos: PromoDto[];
    };
}

export interface StoreFetchFailureAction extends StoreBaseAction {
    type: StoreActionType.FETCH_FAILURE;
}

export interface StoreDeleteRequestAction extends StoreBaseAction {
    type: StoreActionType.DELETE_STORE_REQUEST;
    payload: {
        storeId: string;
    };
}

export interface StoreActivateTrialPeriodAction extends StoreBaseAction {
    type: StoreActionType.ACTIVATE_TRIAL_PERIOD;
    payload: {
        storeId: string;
    };
}

export interface StoreActivateCreateLinkAction extends StoreBaseAction {
    type: StoreActionType.CREATE_LINK;
    payload: {
        storeId: string;
    };
}

export interface StoreChangeModeratedStatusRequestAction extends StoreBaseAction {
    type: StoreActionType.CHANGE_MODERATED_STATUS_REQUEST;
    payload: {
        storeId: string;
        moderated: boolean;
    };
}

export interface StoreChangeModeratedStatusSuccessAction extends StoreBaseAction {
    type: StoreActionType.CHANGE_MODERATED_STATUS_SUCCESS;
    payload: {
        moderated: boolean;
    };
}

export interface StoreActivatePullEmailAction extends StoreBaseAction {
    type: StoreActionType.CLICK_FOR_BUTTON_PHONE;
    payload: {
        storeId: string;
    };
}

export interface StoreCloneAction extends StoreBaseAction {
    type: StoreActionType.CLONE;
    payload: {
        storeDto: StoreDto;
        formValue: CloneStoreFormValue;
    };
}

export type StoreAction =
    | StoreFetchRequestAction
    | StoreFetchSuccessAction
    | StoreFetchFailureAction
    | StoreFetchByIdRequestAction
    | StoreFetchByIdSuccessAction
    | StoreFetchByIdFailureAction
    | StoreFetchSubscriptionInfoAction
    | StoreUpdateStoreOwnerInfoAction
    | StoreUpdateStoreSubscriptionInfoAction
    | StoreUpdateStorePhoneLinkAction
    | StoreDeleteRequestAction
    | StoreActivateTrialPeriodAction
    | StoreChangeModeratedStatusRequestAction
    | StoreChangeModeratedStatusSuccessAction
    | StoreActivateCreateLinkAction
    | StoreActivatePullEmailAction
    | StoreFetchNewProductsPageAction
    | StoreFetchNewProductsPageSuccessAction
    | StoreCloneAction;

export const makeStoreFetchRequestAction = (storeSlug: string): StoreFetchRequestAction => ({
    type: StoreActionType.FETCH_REQUEST,
    payload: {
        storeSlug,
    },
});

export const makeStoreFetchNewProductsPageAction = (
    storeId: string,
    start: string,
): StoreFetchNewProductsPageAction => ({
    type: StoreActionType.FETCH_NEW_PRODUCTS_PAGE,
    payload: {
        storeId,
        start,
    },
});

export const makeStoreFetchNewProductsPageSuccessAction = (
    newProducts: ProductDto[],
): StoreFetchNewProductsPageSuccessAction => ({
    type: StoreActionType.FETCH_NEW_PRODUCTS_PAGE_SUCCESS,
    payload: {
        newProducts,
    },
});

export const makeStoreFetchByIdRequestAction = (storeId: string): StoreFetchByIdRequestAction => ({
    type: StoreActionType.FETCH_ID_REQUEST,
    payload: {
        storeId,
    },
});

export const makeStoreFetchByIdSuccessAction = (store: StoreDto): StoreFetchByIdSuccessAction => ({
    type: StoreActionType.FETCH_STORE_SUCCESS,
    payload: {
        store,
    },
});

export const makeStoreFetchByIdFailureAction = (): StoreFetchByIdFailureAction => ({
    type: StoreActionType.FETCH_STORE_FAILURE,
});

export const makeStoreFetchSubscriptionInfoAction = (storeId: string): StoreFetchSubscriptionInfoAction => ({
    type: StoreActionType.FETCH_STORE_SUBSCRIPTION_INFO,
    payload: {
        storeId,
    },
});

export const makeStoreUpdateStoreOwnerInfoAction = (fakeOwner: boolean): StoreUpdateStoreOwnerInfoAction => ({
    type: StoreActionType.UPDATE_STORE_OWNER_INFO,
    payload: {
        fakeOwner,
    },
});

export const makeStoreUpdateStoreSubscriptionInfoAction = (
    subscription: SubscriptionResponse,
): StoreUpdateStoreSubscriptionInfoAction => ({
    type: StoreActionType.UPDATE_STORE_SUBSCRIPTION_INFO,
    payload: {
        subscription,
    },
});

export const makeStoreUpdateStorePhoneLinkAction = (phone: PhoneLinkResponse): StoreUpdateStorePhoneLinkAction => ({
    type: StoreActionType.CREATE_LINK,
    payload: {
        phone,
    },
});

export const makeStoreFetchSuccessAction = (
    store: StoreDto,
    products: ProductDto[],
    categories: CategoryDto[],
    promos: PromoDto[],
): StoreFetchSuccessAction => ({
    type: StoreActionType.FETCH_SUCCESS,
    payload: {
        store,
        products,
        categories,
        promos,
    },
});

export const makeStoreFetchFailureAction = (): StoreFetchFailureAction => ({
    type: StoreActionType.FETCH_FAILURE,
});

export const makeStoreDeleteRequestAction = (storeId: string): StoreDeleteRequestAction => ({
    type: StoreActionType.DELETE_STORE_REQUEST,
    payload: {
        storeId,
    },
});

export const makeStoreActivateTrialPeriodAction = (storeId: string): StoreActivateTrialPeriodAction => ({
    type: StoreActionType.ACTIVATE_TRIAL_PERIOD,
    payload: {
        storeId,
    },
});

export const makeStoreActivateCreateLinkAction = (storeId: string): StoreActivateCreateLinkAction => ({
    type: StoreActionType.CREATE_LINK,
    payload: {
        storeId,
    },
});

export const makeStoreChangeModeratedStatusRequestAction = (
    storeId: string,
    moderated: boolean,
): StoreChangeModeratedStatusRequestAction => ({
    type: StoreActionType.CHANGE_MODERATED_STATUS_REQUEST,
    payload: {
        storeId,
        moderated,
    },
});

export const makeStoreChangeModeratedStatusSuccessAction = (
    moderated: boolean,
): StoreChangeModeratedStatusSuccessAction => ({
    type: StoreActionType.CHANGE_MODERATED_STATUS_SUCCESS,
    payload: {
        moderated,
    },
});

export const makeStoreClickPhoneAction = (storeId: string): StoreActivatePullEmailAction => ({
    type: StoreActionType.CLICK_FOR_BUTTON_PHONE,
    payload: {
        storeId,
    },
});

export const makeStoreCloneAction = (storeDto: StoreDto, formValue: CloneStoreFormValue): StoreCloneAction => ({
    type: StoreActionType.CLONE,
    payload: {
        storeDto,
        formValue,
    },
});
