import { captureException } from '@sentry/nextjs';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import {
    makeProductFetchFailureAction,
    makeProductFetchSuccessAction,
    ProductActionType,
    ProductFetchRequestAction,
} from './actions';
import { getRequestSaga } from '../rest/sagas';
import { storeSelector } from '../store/selectors';

import { Endpoint } from '../../Endpoint';

import { StoreDto } from 'types';

export function* productSaga(): SagaIterator<void> {
    yield takeEvery(ProductActionType.FETCH_REQUEST, productFetchSaga);
}

function* productFetchSaga(action: ProductFetchRequestAction): SagaIterator<void> {
    try {
        let storeId;
        const { storeSlug, categoryId, productId } = action.payload;
        const preloadedStore: StoreDto = yield select(storeSelector);
        if (preloadedStore && preloadedStore.customUrl === storeSlug) {
            storeId = preloadedStore.storeId;
        } else {
            const { data: store } = yield call(getRequestSaga, Endpoint.STORE_BY_SLUG, { slug: encodeURI(storeSlug) });
            storeId = store.storeId;
        }
        const { data: product } = yield call(getRequestSaga, Endpoint.PRODUCT, {
            storeId,
            categoryId,
            productId,
        });
        yield put(makeProductFetchSuccessAction(product));
    } catch (e) {
        captureException(e);
        yield put(makeProductFetchFailureAction());
    }
}
