import { VendorPromoSubmitRequest } from './types';
import { PromoDto } from 'types';

export enum PromoActionType {
    ACTIVATE = 'promo/ACTIVATE',
    DEACTIVATE = 'promo/DEACTIVATE',
    FETCH_PROMOS_SUCCESS = 'promo/FETCH_PROMOS_SUCCESS',
    SUBMIT_PROMO = 'promo/SUBMIT_PROMO',
    SUBMIT_PROMO_SUCCESS = 'promo/SUBMIT_PROMO_SUCCESS',
    TOGGLE_PROMO_ACTIVE_STATE = 'promo/TOGGLE_PROMO_ACTIVE_STATE',
    DELETE_PROMO_REQUEST = 'promo/DELETE_PROMO_REQUEST',
    FETCH_PROMO_REQUEST = 'promo/FETCH_PROMO_REQUEST',
    FETCH_PROMO_ENTITY_SUCCESS = 'promo/FETCH_PROMO_ENTITY_SUCCESS',
}

export interface PromoBaseAction {
    type: PromoActionType;
}

export interface PromoFetchSuccessAction extends PromoBaseAction {
    type: PromoActionType.FETCH_PROMOS_SUCCESS;
    payload: {
        promos: PromoDto[];
    };
}

export interface PromoSubmitAction extends PromoBaseAction {
    type: PromoActionType.SUBMIT_PROMO;
    payload: {
        promo: VendorPromoSubmitRequest;
        promoId: string;
    };
}

export interface PromoSubmitSuccessAction extends PromoBaseAction {
    type: PromoActionType.SUBMIT_PROMO_SUCCESS;
}

export interface PromoToggleActivePromoStateAction extends PromoBaseAction {
    type: PromoActionType.TOGGLE_PROMO_ACTIVE_STATE;
    payload: {
        active: boolean;
        promoId: string;
    };
}

export interface PromoDeleteRequestAction extends PromoBaseAction {
    type: PromoActionType.DELETE_PROMO_REQUEST;
    payload: {
        promoId: string;
    };
}

export interface PromoFetchRequestAction extends PromoBaseAction {
    type: PromoActionType.FETCH_PROMO_REQUEST;
    payload: {
        promoId: string;
    };
}

export interface PromoFetchEntitySuccessAction extends PromoBaseAction {
    type: PromoActionType.FETCH_PROMO_ENTITY_SUCCESS;
    payload: {
        promo: PromoDto;
    };
}

export type PromoAction =
    | PromoFetchSuccessAction
    | PromoSubmitAction
    | PromoSubmitSuccessAction
    | PromoToggleActivePromoStateAction
    | PromoDeleteRequestAction
    | PromoFetchRequestAction
    | PromoFetchEntitySuccessAction;

export const makePromosFetchSuccessAction = (promos: PromoDto[]): PromoFetchSuccessAction => ({
    type: PromoActionType.FETCH_PROMOS_SUCCESS,
    payload: {
        promos,
    },
});

export const makePromoSubmitAction = (promo: VendorPromoSubmitRequest, promoId: string = null): PromoSubmitAction => ({
    type: PromoActionType.SUBMIT_PROMO,
    payload: {
        promo,
        promoId,
    },
});

export const makePromoSubmitSuccessAction = (): PromoSubmitSuccessAction => ({
    type: PromoActionType.SUBMIT_PROMO_SUCCESS,
});

export const makePromoToggleActivePromoAction = (
    active: boolean,
    promoId: string,
): PromoToggleActivePromoStateAction => ({
    type: PromoActionType.TOGGLE_PROMO_ACTIVE_STATE,
    payload: {
        active,
        promoId,
    },
});

export const makePromoDeleteRequestAction = (promoId: string): PromoDeleteRequestAction => ({
    type: PromoActionType.DELETE_PROMO_REQUEST,
    payload: {
        promoId,
    },
});

export const makePromoFetchRequestAction = (promoId: string): PromoFetchRequestAction => ({
    type: PromoActionType.FETCH_PROMO_REQUEST,
    payload: {
        promoId,
    },
});

export const makePromoFetchEntitySuccessAction = (promo: PromoDto): PromoFetchEntitySuccessAction => ({
    type: PromoActionType.FETCH_PROMO_ENTITY_SUCCESS,
    payload: {
        promo,
    },
});
