import { CategoryDto, ProductDto, StoreDto } from 'types';

export enum ProductActionType {
    FETCH_REQUEST = 'product/FETCH_REQUEST',
    FETCH_SUCCESS = 'product/FETCH_SUCCESS',
    FETCH_FAILURE = 'product/FETCH_FAILURE',
}

export interface ProductBaseAction {
    type: ProductActionType;
}

export interface ProductFetchRequestAction extends ProductBaseAction {
    type: ProductActionType.FETCH_REQUEST;
    payload: {
        storeSlug: string;
        categoryId: string;
        productId: string;
    };
}

export interface ProductFetchSuccessAction extends ProductBaseAction {
    type: ProductActionType.FETCH_SUCCESS;
    payload: {
        product: ProductDto;
    };
}

export interface ProductFetchFailureAction extends ProductBaseAction {
    type: ProductActionType.FETCH_FAILURE;
}

export type ProductAction = ProductFetchRequestAction | ProductFetchSuccessAction | ProductFetchFailureAction;

export const makeProductFetchRequestAction = (
    storeSlug: string,
    categoryId: string,
    productId: string,
): ProductFetchRequestAction => ({
    type: ProductActionType.FETCH_REQUEST,
    payload: {
        storeSlug,
        categoryId,
        productId,
    },
});

export const makeProductFetchSuccessAction = (product: ProductDto): ProductFetchSuccessAction => ({
    type: ProductActionType.FETCH_SUCCESS,
    payload: {
        product,
    },
});

export const makeProductFetchFailureAction = (): ProductFetchFailureAction => ({
    type: ProductActionType.FETCH_FAILURE,
});
