import { OnboardingState } from './reducer';

export interface OnboardingSlice {
    onboarding: OnboardingState;
}

export const onboardingScreenSelector = (slice: OnboardingSlice) => {
    return slice.onboarding.screen;
};

export const onboardingSellerFormDataSelector = (slice: OnboardingSlice) => {
    return slice.onboarding.sellerFormData;
};

export const onboardingStoreFormDataSelector = (slice: OnboardingSlice) => {
    return slice.onboarding.storeFormData;
};
