import { DeliveryServiceFormData, DeliveryServiceOptions } from './types';
import { DeepPartial } from 'types';

export const mapDeliveryServiceOptionsToFormData = (options: DeliveryServiceOptions): DeliveryServiceFormData => {
    const { order, places, recipient, sender } = options;
    const [slot] = places;
    return {
        recipientAddress: recipient.addressString,
        recipientName: recipient.contactName,
        recipientPhone: recipient.phone,
        senderAddress: sender.addressString,
        senderPhone: sender.phone,
        senderEmail: sender.email,
        weight: order.weight / 1e3,
        planId: order.tariffId,
        height: slot.height,
        length: slot.length,
        width: slot.width,
        senderAddressCoordinates: { lat: sender.lat, lon: sender.lng, qc_geo: 0, internalQcGeo: 1 },
        recipientAddressCoordinates: { lat: recipient.lat, lon: recipient.lng, qc_geo: 0, internalQcGeo: 1 },
    };
};

export const mapFormDataToDeliveryServiceOptions = (
    formData: DeliveryServiceFormData,
): DeepPartial<DeliveryServiceOptions> => {
    const { weight, width, height, length } = formData;
    return {
        order: {
            weight: 1e3 * weight,
            tariffId: formData.planId,
        },
        places: [
            {
                width,
                height,
                length,
                weight: 1e3 * weight,
                items: [
                    {
                        weight: 1e3 * weight,
                    },
                ],
            },
        ],
        recipient: {
            addressString: formData.recipientAddress,
            contactName: formData.recipientName,
            phone: formData.recipientPhone,
            lat: formData.recipientAddressCoordinates.lat,
            lng: formData.recipientAddressCoordinates.lon,
        },
        sender: {
            addressString: formData.senderAddress,
            phone: formData.senderPhone,
            email: formData.senderEmail,
            lat: formData.senderAddressCoordinates.lat,
            lng: formData.senderAddressCoordinates.lon,
        },
    };
};
