import { AddressDto } from '../../types';

export enum FeedActionType {
    SET_ADDRESS = 'feed/SET_ADDRESS',
    SET_ADDRESS_COMMENT = 'feed/SET_ADDRESS_COMMENT',
    SET_COORDINATES = 'feed/SET_COORDINATES',
    SET_STANDARD_ADDRESS_DATA = 'feed/SET_STANDARD_ADDRESS_DATA',
    SET_ADDRESS_CHANGED = 'feed/SET_ADDRESS_CHANGED',
    GEOLOCATION_SUCCESS = 'feed/GEOLOCATION_SUCCESS',
    SET_HOVERED_STORE_ID = 'feed/SET_HOVERED_STORE_ID',
    SET_SELECTED_STORE_ID = 'feed/SET_SELECTED_STORE_ID',
    OPEN_LOCATION = 'feed/OPEN_LOCATION',
    CLOSE_LOCATION = 'feed/CLOSE_LOCATION',
}

export interface FeedBaseAction {
    type: FeedActionType;
}

export interface FeedSetAddressAction extends FeedBaseAction {
    type: FeedActionType.SET_ADDRESS;
    payload: {
        address: string;
        comment: string;
        isUserInput: boolean;
    };
}

export interface FeedSetAddressCommentAction extends FeedBaseAction {
    type: FeedActionType.SET_ADDRESS_COMMENT;
    payload: {
        comment: string;
    };
}

export interface FeedSetCoordinatesAction extends FeedBaseAction {
    type: FeedActionType.SET_COORDINATES;
    payload: {
        lat: number;
        lon: number;
        qc_geo?: number;
    };
}

export interface FeedSetStandardAddressDataAction extends FeedBaseAction {
    type: FeedActionType.SET_STANDARD_ADDRESS_DATA;
    payload: AddressDto;
}

export interface FeedSetAddressChangedAction extends FeedBaseAction {
    type: FeedActionType.SET_ADDRESS_CHANGED;
}

export interface FeedLocationOpenAction extends FeedBaseAction {
    type: FeedActionType.OPEN_LOCATION;
}
export interface FeedLocationCloseAction extends FeedBaseAction {
    type: FeedActionType.CLOSE_LOCATION;
}

export interface FeedGeolocationSuccessAction extends FeedBaseAction {
    type: FeedActionType.GEOLOCATION_SUCCESS;
}

export interface FeedSetHoveredStoreIdAction extends FeedBaseAction {
    type: FeedActionType.SET_HOVERED_STORE_ID;
    payload: {
        storeId: string;
    };
}

export interface FeedSetSelectedStoreIdAction extends FeedBaseAction {
    type: FeedActionType.SET_SELECTED_STORE_ID;
    payload: {
        storeId: string;
    };
}

export type FeedAction =
    | FeedSetAddressAction
    | FeedSetCoordinatesAction
    | FeedSetStandardAddressDataAction
    | FeedSetAddressChangedAction
    | FeedSetAddressCommentAction
    | FeedGeolocationSuccessAction
    | FeedSetHoveredStoreIdAction
    | FeedLocationCloseAction
    | FeedLocationOpenAction
    | FeedSetSelectedStoreIdAction;

export const makeFeedSetAddressAction = (address: string, comment = '', isUserInput = false): FeedSetAddressAction => ({
    type: FeedActionType.SET_ADDRESS,
    payload: {
        address,
        comment,
        isUserInput,
    },
});

export const makeFeedSetAddressCommentAction = (comment = ''): FeedSetAddressCommentAction => ({
    type: FeedActionType.SET_ADDRESS_COMMENT,
    payload: {
        comment,
    },
});

export const makeFeedSetCoordinatesAction = (lat: number, lon: number, qc_geo?: number): FeedSetCoordinatesAction => ({
    type: FeedActionType.SET_COORDINATES,
    payload: {
        lat,
        lon,
        qc_geo,
    },
});

export const makeFeedSetStandardAddressDataAction = (data: AddressDto): FeedSetStandardAddressDataAction => ({
    type: FeedActionType.SET_STANDARD_ADDRESS_DATA,
    payload: data,
});

export const makeFeedSetAddressChangedAction = (): FeedSetAddressChangedAction => ({
    type: FeedActionType.SET_ADDRESS_CHANGED,
});

export const makeFeedLocationOpenedAction = (): FeedLocationOpenAction => ({
    type: FeedActionType.OPEN_LOCATION,
});

export const makeFeedLocationClosedAction = (): FeedLocationCloseAction => ({
    type: FeedActionType.CLOSE_LOCATION,
});

export const makeFeedGeolocationSuccessAction = (): FeedGeolocationSuccessAction => ({
    type: FeedActionType.GEOLOCATION_SUCCESS,
});

export const makeFeedSetHoveredStoreIdAction = (storeId: string): FeedSetHoveredStoreIdAction => ({
    type: FeedActionType.SET_HOVERED_STORE_ID,
    payload: {
        storeId,
    },
});

export const makeFeedSetSelectedStoreIdAction = (storeId: string): FeedSetSelectedStoreIdAction => ({
    type: FeedActionType.SET_SELECTED_STORE_ID,
    payload: {
        storeId,
    },
});
