const featureFlags = (process.env.NEXT_PUBLIC_FEATURE_FLAGS || '').split(',');

export const env = {
    get appUrl() {
        return process.env.APP_URL ?? '';
    },

    get yandexMetricsAppId() {
        return 69155056;
    },

    get instagramAccountId() {
        return process.env.NEXT_PUBLIC_INSTAGRAM_CLIENT_ID;
    },

    get useInstagramFeature() {
        return featureFlags.includes('instagram');
    },
};
