import { SubscriptionSlice } from './reducer';

export const subscriptionRateDialogTypeSelector = (state: SubscriptionSlice) => {
    return state.subscription.rateDialogType;
};

export const subscriptionPaymentStatusSelector = (state: SubscriptionSlice) => {
    return state.subscription.paymentStatus;
};

export const subscriptionPlansSelector = (state: SubscriptionSlice) => {
    return state.subscription.subscriptionPlans;
};

export const subscriptionCurrentSelector = (state: SubscriptionSlice) => {
    return state.subscription.currentSubscription;
};

export const subscriptionPaymentMethodSelector = (state: SubscriptionSlice) => {
    return state.subscription.paymentMethod;
};

export const subscriptionIntentSelector = (state: SubscriptionSlice) => {
    return state.subscription.paymentIntent;
};

export const subscriptionBannerSelector = (state: SubscriptionSlice) => {
    return state.subscription.closedBanner;
};
