import { captureException } from '@sentry/nextjs';
import { SagaIterator } from 'redux-saga';
import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { appConfig } from 'config/app';

import { activatedSaga, redirectSaga } from 'core/sagas/sagas';
import { hasDataChanged } from 'core/utils/utils';
import { findChangedOrder } from 'core/orders/orders';
import { getErrorForFieldOrGeneric } from 'core/form/form';

import {
    makeOrderFetchItemFailureAction,
    makeOrderFetchItemSuccessAction,
    makeOrderFetchListFailureAction,
    makeOrderFetchListSuccessAction,
    makeOrderFetchStatsFailureAction,
    makeOrderFetchStatsSuccessAction,
    makeOrderUpdateErrorMessageAction,
    OrderActionType,
    OrderChangeStatusAction,
    OrderFetchItemRequestAction,
    OrderListFilterStatus,
    OrderSendForApprovalAction,
} from './actions';
import { makeAppUIDisableAction, makeAppUIEnableAction } from '../app/actions';
import { authRoleSelector, authStoreCurrentStateSelector, authStoreIdSelector } from '../auth/selectors';
import { getRequestSaga, postRequestSaga, putRequestSaga } from '../rest/sagas';
import { orderCurrentItemSelector } from './selector';
import { makeSnackbarCreateAction, makeSnackbarErrorAction } from '../snackbar/actions';
import { SnackbarMessageType } from '../snackbar/reducer';
import { startDeliverySaga } from '../deliveryService/sagas';

import { Endpoint } from 'Endpoint';
import { RoutePath } from 'RoutePath';

import { OrderChangeStatusIntent, OrderStatus, StoreCurrentState, UserRole } from 'types';

const isServer = typeof window === 'undefined';

export function* orderSaga(): SagaIterator {
    yield takeEvery(OrderActionType.FETCH_ITEM_REQUEST, orderFetchItemSaga);
    yield takeEvery(OrderActionType.SEND_FOR_APPROVAL, orderSendForApprovalSaga);
    yield takeEvery(OrderActionType.CHANGE_STATUS, orderChangeStatusSaga);
    if (!isServer) {
        yield all([
            call(activatedSaga, orderFetchStatsSaga, OrderActionType.ACTIVATE, OrderActionType.DEACTIVATE),
            call(
                activatedSaga,
                newOrdersSaga,
                OrderActionType.NEW_ORDERS_ACTIVATE,
                OrderActionType.NEW_ORDERS_DEACTIVATE,
            ),
            call(
                activatedSaga,
                activeOrdersSaga,
                OrderActionType.ACTIVE_ORDERS_ACTIVATE,
                OrderActionType.ACTIVE_ORDERS_DEACTIVATE,
            ),
            call(
                activatedSaga,
                closedOrdersSaga,
                OrderActionType.CLOSED_ORDERS_ACTIVATE,
                OrderActionType.CLOSED_ORDERS_DEACTIVATE,
            ),
            call(watchOrdersSaga),
        ]);
    }
}

function* orderFetchStatsSaga(): SagaIterator {
    try {
        const storeId = yield select(authStoreIdSelector);
        const response: AxiosResponse = yield call(getRequestSaga, Endpoint.ORDERS_STATS, { storeId });
        const successAction = yield call(makeOrderFetchStatsSuccessAction, response.data);
        yield put(successAction);
    } catch (e) {
        captureException(e);
        const failureAction = yield call(makeOrderFetchStatsFailureAction);
        yield put(failureAction);
    }
}

function* orderFetchListForStatusSaga(status: OrderListFilterStatus): SagaIterator {
    try {
        const storeId = yield select(authStoreIdSelector);
        const response: AxiosResponse = yield call(getRequestSaga, Endpoint.ORDER_LIST, { storeId, status });
        const successAction = yield call(makeOrderFetchListSuccessAction, status, response.data);
        yield put(successAction);
    } catch (e) {
        captureException(e);
        const failureAction = yield call(makeOrderFetchListFailureAction);
        yield put(failureAction);
    }
}

function* orderFetchItemSaga(action: OrderFetchItemRequestAction): SagaIterator {
    try {
        yield put(makeOrderUpdateErrorMessageAction(''));
        const storeId = yield select(authStoreIdSelector);
        const { orderId } = action.payload;
        const { data } = yield call(getRequestSaga, Endpoint.ORDER_ITEM, { storeId, orderId });
        const r = yield call(getRequestSaga, `/api/users/${data.userId}` as any);
        const successAction = yield call(makeOrderFetchItemSuccessAction, { ...data, username: r.data.username });
        yield put(successAction);
    } catch (e) {
        captureException(e);
        const failureAction = yield call(makeOrderFetchItemFailureAction);
        yield put(failureAction);
    }
}

export function* updateOrderStatusSaga(
    orderId: string,
    orderStatus: OrderStatus,
    fetchForStatuses: OrderListFilterStatus[],
    redirectTo: RoutePath,
    userNotes?: string,
): SagaIterator {
    try {
        yield put(makeAppUIDisableAction());
        const storeId = yield select(authStoreIdSelector);
        const body = { orderStatus, userNotes };
        yield call(putRequestSaga, Endpoint.ORDER_ITEM_STATUS, { storeId, orderId }, body);
        for (const fetchForStatus of fetchForStatuses) {
            yield call(orderFetchListForStatusSaga, fetchForStatus);
        }
        const response: AxiosResponse = yield call(getRequestSaga, Endpoint.ORDERS_STATS, { storeId });
        yield put(makeOrderFetchStatsSuccessAction(response.data));
        yield call(redirectSaga, redirectTo);
    } finally {
        yield put(makeAppUIEnableAction());
    }
}

function* orderSendForApprovalSaga(action: OrderSendForApprovalAction): SagaIterator {
    try {
        yield put(makeOrderUpdateErrorMessageAction(''));
        yield put(makeAppUIDisableAction());
        const { quantities } = action.payload;
        const storeId = yield select(authStoreIdSelector);
        const order = yield select(orderCurrentItemSelector);
        const body = {
            ...order,
            orderedProducts: order.orderedProducts.map((product: any) => ({
                ...product,
                quantity: quantities[product.productId] ?? product.quantity,
            })),
        };
        yield call(postRequestSaga, Endpoint.ORDER_ITEM, { storeId, orderId: order.orderId }, body);
        yield call(orderFetchListForStatusSaga, OrderListFilterStatus.NEW);
        yield call(redirectSaga, RoutePath.ORDERS_NEW);
    } catch (e) {
        captureException(e);
        const error = getErrorForFieldOrGeneric(e, '');
        if (error) {
            yield put(makeOrderUpdateErrorMessageAction(error));
        }
    } finally {
        yield put(makeAppUIEnableAction());
    }
}

function* watchOrdersSaga(): SagaIterator {
    let previousData = null;
    while (true) {
        const userRole = yield select(authRoleSelector);
        const storeId = yield select(authStoreIdSelector);
        const storeCurrentState = yield select(authStoreCurrentStateSelector);
        if (userRole === UserRole.VENDOR && storeId && storeCurrentState === StoreCurrentState.FULL) {
            try {
                const storeId = yield select(authStoreIdSelector);
                const { data }: AxiosResponse = yield call(getRequestSaga, Endpoint.ORDER_LIST, {
                    storeId,
                    status: OrderListFilterStatus.NEW,
                });
                if (hasDataChanged(previousData, data)) {
                    if (previousData) {
                        const changedOrder = findChangedOrder(previousData, data);
                        if (changedOrder && changedOrder.orderStatus === OrderStatus.NEW) {
                            const { orderId, totalPrice } = changedOrder;
                            yield put(
                                makeSnackbarCreateAction(SnackbarMessageType.VENDOR_ORDER_NEW, {
                                    orderId,
                                    totalPrice,
                                }),
                            );
                        }
                    }
                    const successAction = yield call(makeOrderFetchListSuccessAction, OrderListFilterStatus.NEW, data);
                    yield put(successAction);
                    previousData = data;
                }
            } catch (e) {
                captureException(e);
                yield put(makeSnackbarErrorAction('orders:errors.checkout'));
            }
        }
        yield delay(appConfig.orderRefreshInterval);
    }
}

function* newOrdersSaga(): SagaIterator {
    yield call(orderFetchListForStatusSaga, OrderListFilterStatus.NEW);
}

function* activeOrdersSaga(): SagaIterator {
    while (true) {
        yield call(orderFetchListForStatusSaga, OrderListFilterStatus.ACTIVE);
        yield delay(appConfig.orderRefreshInterval);
    }
}

function* closedOrdersSaga(): SagaIterator {
    while (true) {
        yield call(orderFetchListForStatusSaga, OrderListFilterStatus.CLOSED);
        yield delay(appConfig.orderRefreshInterval);
    }
}

function* orderChangeStatusSaga(action: OrderChangeStatusAction): SagaIterator {
    const { orderId, intent } = action.payload;
    switch (intent) {
        case OrderChangeStatusIntent.ACCEPT_BY_VENDOR:
            yield call(
                updateOrderStatusSaga,
                orderId,
                OrderStatus.PREPARING,
                [OrderListFilterStatus.NEW, OrderListFilterStatus.ACTIVE],
                RoutePath.ORDERS_NEW,
            );
            break;
        case OrderChangeStatusIntent.DECLINE:
            yield call(
                updateOrderStatusSaga,
                orderId,
                OrderStatus.CLOSED,
                [OrderListFilterStatus.NEW, OrderListFilterStatus.CLOSED],
                RoutePath.ORDERS_NEW,
                'Declined by vendor',
            );
            break;
        case OrderChangeStatusIntent.FINISH_DELIVERY:
            yield call(
                updateOrderStatusSaga,
                orderId,
                OrderStatus.CLOSED,
                [OrderListFilterStatus.ACTIVE, OrderListFilterStatus.CLOSED],
                RoutePath.ORDERS_ACTIVE,
                'Delivered',
            );
            break;
        case OrderChangeStatusIntent.FINISH_PREPARING:
            yield call(startDeliverySaga, orderId);
            yield call(
                updateOrderStatusSaga,
                orderId,
                OrderStatus.DELIVERY,
                [OrderListFilterStatus.ACTIVE],
                RoutePath.ORDERS_ACTIVE,
            );
            break;
    }
}
