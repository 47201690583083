import { RegistrationAction, RegistrationActionType } from './actions';

import { DeliveryFormData } from 'forms/DeliveryForm/DeliveryForm';
import { PickUpFormData } from 'forms/PickUpForm/PickUpForm';
import { PaymentFormData } from 'forms/PaymentForm/PaymentFormComponent';

import { DeliveryProvider, RegistrationYandexCheckoutData, RegistrationYandexOLPAYData } from './types';
import {
    DeliveryType,
    PaidDeliveryType,
    PaymentType,
    RequestStatus,
    StoreDto,
    YandexCheckoutApplicationStatus,
} from 'types';
import { getLocalStorageItem, LocalStorageKey } from 'core/storage/storage';
import { Steps } from 'pages/Vendor/Widgets/StepsWidget/StepsWidget';

export enum RegistrationStage {
    STORE_DETAILS = 1,
    CATALOG = 2,
    SETTINGS = 3,
    // SUBSCRIPTION = 4,
    SUCCESS = 4,
}

export enum SubscriptionStatus {
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

export interface RegistrationState {
    stage: RegistrationStage;
    checklistJson: Steps;
    settings: {
        delivery: DeliveryFormData;
        pickup: PickUpFormData;
        paymentCash: PaymentFormData;
        paymentCard: PaymentFormData;
        paymentOnline: RegistrationYandexOLPAYData;
        yandexCheckout: RegistrationYandexCheckoutData;
        paymentComment: string;
        deliveryComment: string;
        specificDelivery: boolean;
    };
    subscription: {
        status: SubscriptionStatus;
    };
    providersList: DeliveryProvider[];
    providersListStatus: RequestStatus;
    errorMessage: string;
    welcomeDialogClosed: boolean;
}

export interface RegistrationSlice {
    registration: RegistrationState;
}

export function getRegistartionStateFromLS() {
    return {
        welcomeDialogClosed: getLocalStorageItem(LocalStorageKey.WELCOME_DIALOG_CLOSED),
    };
}

const initialRegistrationState: RegistrationState = {
    stage: 0,
    checklistJson: {
        sticker: false,
        online: false,
        social: false,
        visibility: false,
        freeShipping: false,
        feedBack: false,
    },
    settings: {
        delivery: {
            enabledFreeShipping: null,
            minOrderPrice: null,
            freeMaxDistance: null,
            enabledPayShipping: null,
            price: null,
            pricePerKm: null,
            payMaxDistance: null,
            payMaxDistancePerKm: null,
            enabledServiceDelivery: null,
            apiShipToken: '',
            deliveryProviderKey: null,
            pricingModel: PaidDeliveryType.STATIC,
            enabledSpecificDelivery: null,
        },
        pickup: {
            comment: null,
            enabled: null,
            hoursToStore: null,
        },
        paymentCash: {
            atDelivery: null,
            atStore: null,
        },
        paymentCard: {
            atDelivery: null,
            atStore: null,
        },
        paymentOnline: {
            enabled: false,
            shopId: null,
        },
        yandexCheckout: {
            isEnabled: null,
            email: null,
            inn: null,
            status: YandexCheckoutApplicationStatus.NONE,
            shopId: null,
        },
        paymentComment: null,
        deliveryComment: null,
        specificDelivery: false,
    },
    subscription: {
        status: SubscriptionStatus.PENDING,
    },
    providersListStatus: RequestStatus.NONE,
    providersList: [],
    errorMessage: '',
    welcomeDialogClosed: getLocalStorageItem(LocalStorageKey.WELCOME_DIALOG_CLOSED),
};

export const registrationReducer = (
    state = initialRegistrationState,
    action: RegistrationAction,
): RegistrationState => {
    switch (action.type) {
        case RegistrationActionType.SETTINGS_DELIVERY_SUBMIT:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    delivery: !action.payload.enabledSpecificDelivery
                        ? {
                              ...action.payload,
                          }
                        : {
                              enabledFreeShipping: null,
                              minOrderPrice: null,
                              freeMaxDistance: null,
                              enabledPayShipping: null,
                              price: null,
                              pricePerKm: null,
                              payMaxDistance: null,
                              payMaxDistancePerKm: null,
                              enabledServiceDelivery: null,
                              apiShipToken: '',
                              deliveryProviderKey: null,
                              pricingModel: PaidDeliveryType.STATIC,
                              enabledSpecificDelivery: true,
                          },
                    pickup: action.payload.enabledSpecificDelivery
                        ? {
                              comment: null,
                              enabled: null,
                              hoursToStore: null,
                          }
                        : state.settings.pickup,
                },
            };
        case RegistrationActionType.SETTINGS_PICKUP_SUBMIT:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    pickup: action.payload,
                },
            };
        case RegistrationActionType.SETTINGS_PAYMENT_CASH_SUBMIT:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    paymentCash: action.payload,
                },
            };
        case RegistrationActionType.SETTINGS_PAYMENT_CARD_SUBMIT:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    paymentCard: action.payload,
                },
            };
        case RegistrationActionType.SETTINGS_YANDEX_CHECKOUT_CARD_SUBMIT:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    yandexCheckout: action.payload,
                },
            };
        case RegistrationActionType.SET_STAGE:
            return {
                ...state,
                stage: action.payload.stage,
                checklistJson: action.payload.checklistJson,
            };
        case RegistrationActionType.SET_CHECKLIST:
            return {
                ...state,
                checklistJson: action.payload.checklistJson,
            };
        case RegistrationActionType.UPDATE_PAYMENT_STATUS:
            return {
                ...state,
                subscription: {
                    ...state.subscription,
                    status: action.payload.status,
                },
            };
        case RegistrationActionType.SETTINGS_INIT:
            const delivery = deliveryMapper(state, action.payload.store, action.payload.deliveryService);
            const onlinePaymentData = olpayPaymentMapper(action.payload.store, state.settings.paymentOnline);
            return {
                ...state,
                settings: {
                    ...state.settings,
                    pickup: pickupMapper(state, action.payload.store),
                    delivery,
                    // delivery: deliveryMapper(state, action.payload.store, delivery),
                    paymentCash: paymentMapper(
                        state,
                        action.payload.store,
                        PaymentType.CASH,
                        state.settings.paymentCash,
                    ),
                    paymentCard: paymentMapper(
                        state,
                        action.payload.store,
                        PaymentType.CARD,
                        state.settings.paymentCard,
                    ),
                    paymentOnline: onlinePaymentData,
                    yandexCheckout: {
                        ...action.payload.yaCheckoutApp,
                        shopId: onlinePaymentData?.shopId,
                        isEnabled: Boolean(action.payload.yaCheckoutApp.email),
                    },
                    paymentComment: action.payload.store.paymentComment,
                    deliveryComment: action.payload.store.deliveryComment,
                    specificDelivery: delivery.enabledSpecificDelivery,
                },
            };
        case RegistrationActionType.UPDATE_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
            };
        case RegistrationActionType.UPDATE_PROVIDER_LIST:
            return {
                ...state,
                providersList: action.payload.providers,
            };
        case RegistrationActionType.CLOSE_WELCOME_DIALOG:
            return {
                ...state,
                welcomeDialogClosed: true,
            };
        default:
            return state;
    }
};

const pickupMapper = (state: RegistrationState, storeDto: StoreDto): PickUpFormData => {
    const pickupDto = storeDto.storeDeliveries.find(value => value.type === DeliveryType.PICKUP);
    const pickupDataDto = pickupDto?.data?.find(() => true);
    return {
        enabled: pickupDto?.enabled,
        comment: pickupDataDto ? pickupDataDto.comment : state.settings.pickup.comment,
        hoursToStore: pickupDataDto ? pickupDataDto.hoursToStore : state.settings.pickup.hoursToStore,
    };
};

const deliveryMapper = (
    state: RegistrationState,
    storeDto: StoreDto,
    deliveryService: { apiKey: string; providers: DeliveryProvider[] },
): DeliveryFormData => {
    const storeDeliveryDto = storeDto.storeDeliveries.find(value => value.type === DeliveryType.STORE_DELIVERY);
    const serviceDeliveryDto = storeDto.storeDeliveries.find(value => value.type === DeliveryType.DELIVERY_SERVICE);
    const specificDeliveryDto = storeDto.storeDeliveries.find(value => value.type === DeliveryType.SPECIFIC_DELIVERY);
    const deliveryDto = serviceDeliveryDto ?? storeDeliveryDto ?? specificDeliveryDto;
    if (!deliveryDto) {
        return state.settings.delivery;
    }
    const freeDeliveryDataDto = deliveryDto.data.find(value => value.price === 0);
    const paidDeliveryDataDto = deliveryDto.data.find(value => value.price !== 0);
    return {
        ...state.settings.delivery,
        enabledFreeShipping: freeDeliveryDataDto && deliveryDto.enabled,
        minOrderPrice: freeDeliveryDataDto ? freeDeliveryDataDto.minOrderPrice : state.settings.delivery.minOrderPrice,
        freeMaxDistance: freeDeliveryDataDto
            ? freeDeliveryDataDto.maxDistance
            : state.settings.delivery.freeMaxDistance,
        enabledPayShipping: paidDeliveryDataDto && deliveryDto.enabled,
        price: paidDeliveryDataDto ? paidDeliveryDataDto.price : state.settings.delivery.price,
        pricingModel: paidDeliveryDataDto ? paidDeliveryDataDto.pricingModel : state.settings.delivery.pricingModel,
        payMaxDistance: paidDeliveryDataDto ? paidDeliveryDataDto.maxDistance : state.settings.delivery.payMaxDistance,
        enabledServiceDelivery: serviceDeliveryDto && serviceDeliveryDto.enabled,
        apiShipToken: deliveryService.apiKey,
        deliveryProviderKey: deliveryService?.providers && deliveryService.providers[0]?.key,
        enabledSpecificDelivery: specificDeliveryDto && specificDeliveryDto.enabled,
    };
};

const paymentMapper = (
    state: RegistrationState,
    storeDto: StoreDto,
    paymentType: PaymentType,
    statePayment: PaymentFormData,
): PaymentFormData => {
    const paymentDto = storeDto?.storePayments?.find(value => value.type === paymentType);
    if (paymentDto) {
        return {
            atStore: paymentDto.enabled && (paymentDto.data ? paymentDto.data.atStore : false),
            atDelivery: paymentDto.enabled && (paymentDto.data ? paymentDto.data.atDelivery : false),
        };
    } else {
        return statePayment;
    }
};

const olpayPaymentMapper = (
    storeDto: StoreDto,
    statePayment: RegistrationYandexOLPAYData,
): RegistrationYandexOLPAYData => {
    const paymentDto = storeDto?.storePayments?.find(value => value.type === PaymentType.OLPAY);
    if (paymentDto) {
        return {
            enabled: paymentDto.enabled,
            shopId: paymentDto.data ? paymentDto.data.shopId : null,
        };
    } else {
        return statePayment;
    }
};
