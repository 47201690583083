import {
    DeliveryProvider,
    RegistrationInitSettingsData,
    RegistrationStoreSettingSubmitRequest,
    RegistrationYandexCheckoutData,
} from './types';
import { DeliveryFormData } from 'forms/DeliveryForm/DeliveryForm';
import { PickUpFormData } from 'forms/PickUpForm/PickUpForm';
import { PaymentFormData } from 'forms/PaymentForm/PaymentFormComponent';
import { RegistrationStage, SubscriptionStatus } from './reducer';
import { Steps } from 'pages/Vendor/Widgets/StepsWidget/StepsWidget';

export enum RegistrationActionType {
    SUBMIT_SETTINGS = 'registration/SUBMIT_SETTING',
    SETTINGS_DELIVERY_SUBMIT = 'registration/SETTINGS_DELIVERY_SUBMIT',
    SETTINGS_PICKUP_SUBMIT = 'registration/SETTINGS_PICKUP_SUBMIT',
    SETTINGS_PAYMENT_CASH_SUBMIT = 'registration/SETTINGS_PAYMENT_CASH_SUBMIT',
    SETTINGS_PAYMENT_CARD_SUBMIT = 'registration/SETTINGS_PAYMENT_CARD_SUBMIT',
    SETTINGS_YANDEX_CHECKOUT_CARD_SUBMIT = 'registration/SETTINGS_YANDEX_CHECKOUT_CARD_SUBMIT',
    SETTINGS_YANDEX_CHECKOUT_DELETE = 'registration/SETTINGS_YANDEX_CHECKOUT_DELETE',
    SET_STAGE = 'registration/SET_STAGE',
    SET_CHECKLIST = 'registration/SET_CHECKLIST',
    UPDATE_PAYMENT_STATUS = 'registration/UPDATE_PAYMENT_STATUS',
    SETTINGS_INIT = 'registration/INIT',
    UPDATE_ERROR_MESSAGE = 'registration/UPDATE_ERROR_MESSAGE',
    CHANGE_API_SHIP_TOKEN = 'registration/CHANGE_API_SHIP_TOKEN',
    UPDATE_PROVIDER_LIST = 'registration/UPDATE_PROVIDER_LIST',
    FETCH_STORE_SETTINGS = 'registration/FETCH_STORE_SETTINGS',
    VENDOR_SILENT_REGISTRATION = 'registration/VENDOR_SILENT_REGISTRATION',
    CLOSE_WELCOME_DIALOG = 'registration/CLOSE_WELCOME_DIALOG',
    QUALIFY_STORE_AS_SIMPLE = 'registration/QUALIFY_STORE_AS_SIMPLE',
    FINISH = 'registration/FINISH',
}

export interface RegistrationBaseAction {
    type: RegistrationActionType;
}

export interface RegistrationInitSettingAction extends RegistrationBaseAction {
    type: RegistrationActionType.SETTINGS_INIT;
    payload: RegistrationInitSettingsData;
}

export interface RegistrationSubmitStoreSettingAction extends RegistrationBaseAction {
    type: RegistrationActionType.SUBMIT_SETTINGS;
    payload: RegistrationStoreSettingSubmitRequest;
}

export interface RegistrationSettingsDeliverySubmitAction extends RegistrationBaseAction {
    type: RegistrationActionType.SETTINGS_DELIVERY_SUBMIT;
    payload: DeliveryFormData;
}

export interface RegistrationSettingsPickUpSubmitAction extends RegistrationBaseAction {
    type: RegistrationActionType.SETTINGS_PICKUP_SUBMIT;
    payload: PickUpFormData;
}

export interface RegistrationSettingsPaymentCashSubmitAction extends RegistrationBaseAction {
    type: RegistrationActionType.SETTINGS_PAYMENT_CASH_SUBMIT;
    payload: PaymentFormData;
}

export interface RegistrationSettingsPaymentCardSubmitAction extends RegistrationBaseAction {
    type: RegistrationActionType.SETTINGS_PAYMENT_CARD_SUBMIT;
    payload: PaymentFormData;
}

export interface RegistrationSettingsYandexCheckoutCardSubmitAction extends RegistrationBaseAction {
    type: RegistrationActionType.SETTINGS_YANDEX_CHECKOUT_CARD_SUBMIT;
    payload: RegistrationYandexCheckoutData;
}

export interface RegistrationSettingsYandexCheckoutDeleteAction extends RegistrationBaseAction {
    type: RegistrationActionType.SETTINGS_YANDEX_CHECKOUT_DELETE;
}

export interface RegistrationSetStageAction extends RegistrationBaseAction {
    type: RegistrationActionType.SET_STAGE;
    payload: {
        stage: RegistrationStage;
        checklistJson?: Steps;
    };
}

export interface RegistrationSetChecklistAction extends RegistrationBaseAction {
    type: RegistrationActionType.SET_CHECKLIST;
    payload: {
        checklistJson: Steps;
    };
}

export interface RegistrationUpdatePaymentStatusAction extends RegistrationBaseAction {
    type: RegistrationActionType.UPDATE_PAYMENT_STATUS;
    payload: {
        status: SubscriptionStatus;
    };
}

export interface RegistrationUpdateErrorMessageAction extends RegistrationBaseAction {
    type: RegistrationActionType.UPDATE_ERROR_MESSAGE;
    payload: {
        errorMessage: string;
    };
}

export interface RegistrationChangeApiShipTokenAction extends RegistrationBaseAction {
    type: RegistrationActionType.CHANGE_API_SHIP_TOKEN;
    payload: {
        token: string;
    };
}

export interface RegistrationUpdateProviderListAction extends RegistrationBaseAction {
    type: RegistrationActionType.UPDATE_PROVIDER_LIST;
    payload: {
        providers: DeliveryProvider[];
    };
}

export interface RegistrationStoreSettingsFetchAction extends RegistrationBaseAction {
    type: RegistrationActionType.FETCH_STORE_SETTINGS;
}

export interface RegistrationVendorSilentAction extends RegistrationBaseAction {
    type: RegistrationActionType.VENDOR_SILENT_REGISTRATION;
}

export interface RegistrationCloseWelcomeDialogAction extends RegistrationBaseAction {
    type: RegistrationActionType.CLOSE_WELCOME_DIALOG;
}

export interface QualifyStoreAsSimpleStoreAction extends RegistrationBaseAction {
    type: RegistrationActionType.QUALIFY_STORE_AS_SIMPLE;
}

export interface RegistrationFinishAction extends RegistrationBaseAction {
    type: RegistrationActionType.FINISH;
}

export type RegistrationAction =
    | RegistrationSubmitStoreSettingAction
    | RegistrationSettingsDeliverySubmitAction
    | RegistrationSettingsPickUpSubmitAction
    | RegistrationSettingsPaymentCashSubmitAction
    | RegistrationSettingsPaymentCardSubmitAction
    | RegistrationSettingsYandexCheckoutCardSubmitAction
    | RegistrationSettingsYandexCheckoutDeleteAction
    | RegistrationSetStageAction
    | RegistrationUpdatePaymentStatusAction
    | RegistrationInitSettingAction
    | RegistrationUpdateErrorMessageAction
    | RegistrationChangeApiShipTokenAction
    | RegistrationUpdateProviderListAction
    | RegistrationStoreSettingsFetchAction
    | RegistrationVendorSilentAction
    | QualifyStoreAsSimpleStoreAction
    | RegistrationCloseWelcomeDialogAction
    | RegistrationFinishAction
    | RegistrationSetChecklistAction;

export const makeRegistrationSubmitStoreSettingsAction = (
    payload: RegistrationStoreSettingSubmitRequest,
): RegistrationSubmitStoreSettingAction => ({
    type: RegistrationActionType.SUBMIT_SETTINGS,
    payload,
});

export const makeRegistrationInitSettingsAction = (
    payload: RegistrationInitSettingsData,
): RegistrationInitSettingAction => ({
    type: RegistrationActionType.SETTINGS_INIT,
    payload,
});

export const makeRegistrationSettingsDeliverySubmitAction = (
    payload: DeliveryFormData,
): RegistrationSettingsDeliverySubmitAction => ({
    type: RegistrationActionType.SETTINGS_DELIVERY_SUBMIT,
    payload,
});

export const makeRegistrationSettingsPickUpSubmitAction = (
    payload: PickUpFormData,
): RegistrationSettingsPickUpSubmitAction => ({
    type: RegistrationActionType.SETTINGS_PICKUP_SUBMIT,
    payload,
});

export const makeRegistrationSettingsPaymentCashSubmitAction = (
    payload: PaymentFormData,
): RegistrationSettingsPaymentCashSubmitAction => ({
    type: RegistrationActionType.SETTINGS_PAYMENT_CASH_SUBMIT,
    payload,
});

export const makeRegistrationSettingsPaymentCardSubmitAction = (
    payload: PaymentFormData,
): RegistrationSettingsPaymentCardSubmitAction => ({
    type: RegistrationActionType.SETTINGS_PAYMENT_CARD_SUBMIT,
    payload,
});

export const makeRegistrationSettingsYandexCheckoutCardSubmitAction = (
    payload: RegistrationYandexCheckoutData,
): RegistrationSettingsYandexCheckoutCardSubmitAction => ({
    type: RegistrationActionType.SETTINGS_YANDEX_CHECKOUT_CARD_SUBMIT,
    payload,
});

export const makeRegistrationSettingsYandexCheckoutDeleteAction = (): RegistrationSettingsYandexCheckoutDeleteAction => ({
    type: RegistrationActionType.SETTINGS_YANDEX_CHECKOUT_DELETE,
});

export const makeRegistrationSetStageAction = (
    stage: RegistrationStage,
    checklistJson?: Steps,
): RegistrationSetStageAction => ({
    type: RegistrationActionType.SET_STAGE,
    payload: {
        stage,
        checklistJson,
    },
});

export const makeRegistrationSetChecklistAction = (checklistJson: Steps): RegistrationSetChecklistAction => ({
    type: RegistrationActionType.SET_CHECKLIST,
    payload: {
        checklistJson,
    },
});

export const qualifyStoreAsSimpleStoreAction = (): QualifyStoreAsSimpleStoreAction => ({
    type: RegistrationActionType.QUALIFY_STORE_AS_SIMPLE,
});

export const makeRegistrationUpdatePaymentStatusAction = (
    status: SubscriptionStatus,
): RegistrationUpdatePaymentStatusAction => ({
    type: RegistrationActionType.UPDATE_PAYMENT_STATUS,
    payload: {
        status,
    },
});

export const makeRegistrationUpdateErrorMessageAction = (
    errorMessage: string,
): RegistrationUpdateErrorMessageAction => ({
    type: RegistrationActionType.UPDATE_ERROR_MESSAGE,
    payload: {
        errorMessage,
    },
});

export const makeRegistrationChangeApiShipTokenAction = (token: string): RegistrationChangeApiShipTokenAction => ({
    type: RegistrationActionType.CHANGE_API_SHIP_TOKEN,
    payload: {
        token,
    },
});

export const makeRegistrationUpdateProviderListAction = (
    providers: DeliveryProvider[],
): RegistrationUpdateProviderListAction => ({
    type: RegistrationActionType.UPDATE_PROVIDER_LIST,
    payload: {
        providers,
    },
});

export const makeRegistrationStoreSettingsFetchAction = (): RegistrationStoreSettingsFetchAction => ({
    type: RegistrationActionType.FETCH_STORE_SETTINGS,
});

export const makeRegistrationVendorSilentAction = (): RegistrationVendorSilentAction => ({
    type: RegistrationActionType.VENDOR_SILENT_REGISTRATION,
});

export const RegistrationCloseWelcomeDialogAction = (): RegistrationCloseWelcomeDialogAction => ({
    type: RegistrationActionType.CLOSE_WELCOME_DIALOG,
});

export const makeRegistrationFinishAction = (): RegistrationFinishAction => ({
    type: RegistrationActionType.FINISH,
});
