import { CatalogProductSubmitRequest } from './types';
import { ProductDto } from 'types';

export enum CatalogActionType {
    ACTIVATE = 'catalog/ACTIVATE',
    DEACTIVATE = 'catalog/DEACTIVATE',
    SUBMIT_PRODUCT = 'catalog/SUBMIT_PRODUCT',
    SUBMIT_PRODUCT_SUCCESS = 'catalog/SUBMIT_PRODUCT_SUCCESS',
    FETCH_CATEGORIES_SUCCESS = 'catalog/FETCH_CATEGORIES_SUCCESS',
    SWITCH_CATEGORY = 'catalog/SWITCH_CATEGORY',
    FETCH_PRODUCTS_REQUEST = 'catalog/FETCH_PRODUCTS_REQUEST',
    FETCH_PRODUCTS_SUCCESS = 'catalog/FETCH_PRODUCTS_SUCCESS',
    FETCH_PRODUCT_SUCCESS = 'catalog/FETCH_PRODUCT_SUCCESS',
    DELETE_PRODUCT = 'catalog/DELETE_PRODUCT',
    CHANGE_CATEGORY_PRODUCTS_ORDER = 'catalog/CHANGE_CATEGORY_PRODUCTS_ORDER',
    CHANGE_CATEGORY_PRODUCTS_ORDER_SUCCESS = 'catalog/CHANGE_CATEGORY_PRODUCTS_ORDER_SUCCESS',
    CHANGE_CATEGORIES_ORDER = 'catalog/CHANGE_CATEGORIES_ORDER',
}

export interface CatalogBaseAction {
    type: CatalogActionType;
}

export interface CatalogSubmitProductAction extends CatalogBaseAction {
    type: CatalogActionType.SUBMIT_PRODUCT;
    payload: {
        product: CatalogProductSubmitRequest;
        categoryId: string;
        productId: string;
        isVendorRoute?: boolean;
    };
}

export interface CatalogSubmitProductSuccessAction extends CatalogBaseAction {
    type: CatalogActionType.SUBMIT_PRODUCT_SUCCESS;
}

export interface CatalogFetchCategoriesSuccessAction extends CatalogBaseAction {
    type: CatalogActionType.FETCH_CATEGORIES_SUCCESS;
    payload: any[];
}

export interface CatalogFetchProductsRequestAction extends CatalogBaseAction {
    type: CatalogActionType.FETCH_PRODUCTS_REQUEST;
}

export interface CatalogFetchProductsSuccessAction extends CatalogBaseAction {
    type: CatalogActionType.FETCH_PRODUCTS_SUCCESS;
    payload: {
        products: ProductDto[];
    };
}

export interface CatalogDeleteProductAction extends CatalogBaseAction {
    type: CatalogActionType.DELETE_PRODUCT;
    payload: {
        categoryId: string;
        productId: string;
        isVendor: boolean;
    };
}

export interface CatalogFetchProductSuccessAction extends CatalogBaseAction {
    type: CatalogActionType.FETCH_PRODUCT_SUCCESS;
    payload: {
        product: ProductDto;
    };
}

export interface CatalogChangeCategoryProductsOrderAction extends CatalogBaseAction {
    type: CatalogActionType.CHANGE_CATEGORY_PRODUCTS_ORDER;
    payload: {
        productId: string;
        targetProductId: string;
    };
}

export interface CatalogChangeCategoryProductsOrderSuccessAction extends CatalogBaseAction {
    type: CatalogActionType.CHANGE_CATEGORY_PRODUCTS_ORDER_SUCCESS;
    payload: {
        category: Record<string, ProductDto[]>;
    };
}

export interface CatalogChangeCategoriesOrderAction extends CatalogBaseAction {
    type: CatalogActionType.CHANGE_CATEGORIES_ORDER;
    payload: {
        categoryId: string;
        targetCategoryId: string;
    };
}

export type CatalogAction =
    | CatalogSubmitProductAction
    | CatalogSubmitProductSuccessAction
    | CatalogFetchCategoriesSuccessAction
    | CatalogFetchProductsRequestAction
    | CatalogFetchProductsSuccessAction
    | CatalogDeleteProductAction
    | CatalogFetchProductSuccessAction
    | CatalogChangeCategoryProductsOrderAction
    | CatalogChangeCategoryProductsOrderSuccessAction
    | CatalogChangeCategoriesOrderAction;

export const makeCatalogSubmitProductAction = (
    product: CatalogProductSubmitRequest,
    isVendorRoute?: boolean,
    categoryId: string = null,
    productId: string = null,
): CatalogSubmitProductAction => ({
    type: CatalogActionType.SUBMIT_PRODUCT,
    payload: {
        product,
        categoryId,
        productId,
        isVendorRoute,
    },
});

export const makeCatalogSubmitProductSuccessAction = (): CatalogSubmitProductSuccessAction => ({
    type: CatalogActionType.SUBMIT_PRODUCT_SUCCESS,
});

export const makeCatalogFetchCategoriesSuccessAction = (payload: any): CatalogFetchCategoriesSuccessAction => ({
    type: CatalogActionType.FETCH_CATEGORIES_SUCCESS,
    payload,
});

export const makeCatalogFetchProductsRequestAction = (): CatalogFetchProductsRequestAction => ({
    type: CatalogActionType.FETCH_PRODUCTS_REQUEST,
});

export const makeCatalogFetchProductsSuccessAction = (products: ProductDto[]): CatalogFetchProductsSuccessAction => ({
    type: CatalogActionType.FETCH_PRODUCTS_SUCCESS,
    payload: {
        products,
    },
});

export const makeCatalogFetchProductSuccessAction = (product: ProductDto): CatalogFetchProductSuccessAction => ({
    type: CatalogActionType.FETCH_PRODUCT_SUCCESS,
    payload: {
        product,
    },
});

export const makeCatalogDeleteProductAction = (
    categoryId: string,
    productId: string,
    isVendor: boolean,
): CatalogDeleteProductAction => ({
    type: CatalogActionType.DELETE_PRODUCT,
    payload: {
        categoryId,
        productId,
        isVendor,
    },
});

export const makeCatalogChangeCategoryProductsOrderAction = (
    productId: string,
    targetProductId: string,
): CatalogChangeCategoryProductsOrderAction => ({
    type: CatalogActionType.CHANGE_CATEGORY_PRODUCTS_ORDER,
    payload: {
        productId,
        targetProductId,
    },
});

export const makeCatalogChangeCategoryProductsOrderSuccessAction = (
    category: Record<string, ProductDto[]>,
): CatalogChangeCategoryProductsOrderSuccessAction => ({
    type: CatalogActionType.CHANGE_CATEGORY_PRODUCTS_ORDER_SUCCESS,
    payload: {
        category,
    },
});

export const makeCatalogChangeCategoriesOrderAction = (
    categoryId: string,
    targetCategoryId: string,
): CatalogChangeCategoriesOrderAction => ({
    type: CatalogActionType.CHANGE_CATEGORIES_ORDER,
    payload: {
        categoryId,
        targetCategoryId,
    },
});
