import { env } from 'env';

declare const window: {
    ym: (appId: number, event: 'reachGoal', goal: ReachedGoal) => void;
};

export enum ReachedGoal {
    ADD_TO_CART = 'vkorzinu',
    ORDER_REQUEST = 'zakazat',
    ORDER_SUCCESS = 'zakaz',
    CHECKOUT_SECOND_STEP = 'zashelvkorzinu2shag',
}

export const sendReachGoalMetrics = (goal: ReachedGoal) => {
    try {
        window.ym(env.yandexMetricsAppId, 'reachGoal', goal);
    } catch (error) {
        // If metrics are not sent - that's OK
    }
};
