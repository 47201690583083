import { createBrowserHistory, History } from 'history';

let history: History | null;

export const getBrowserHistory = () => {
    if (!history) {
        history = createBrowserHistory();
    }
    return history;
};
