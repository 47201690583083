import { OrderAction, OrderActionType, OrderListFilterStatus } from './actions';

import { OrderListResponse, OrderResponse, OrderStatsResponse } from './types';

export interface OrderState {
    lists: {
        [OrderListFilterStatus.NEW]: OrderListResponse;
        [OrderListFilterStatus.ACTIVE]: OrderListResponse;
        [OrderListFilterStatus.CLOSED]: OrderListResponse;
    };
    stats: OrderStatsResponse;
    currentItem: OrderResponse;
    errorMessage: string;
}

export interface OrderSlice {
    order: OrderState;
}

const initialOrderState: OrderState = {
    lists: {
        [OrderListFilterStatus.NEW]: [],
        [OrderListFilterStatus.ACTIVE]: [],
        [OrderListFilterStatus.CLOSED]: [],
    },
    stats: {
        newOrders: 0,
        activeOrders: 0,
        closedOrders: 0,
    },
    currentItem: null,
    errorMessage: '',
};

export const orderReducer = (state = initialOrderState, action: OrderAction): OrderState => {
    switch (action.type) {
        case OrderActionType.FETCH_LIST_SUCCESS:
            return {
                ...state,
                lists: {
                    ...state.lists,
                    [action.payload.status]: action.payload.response,
                },
            };
        case OrderActionType.FETCH_ITEM_SUCCESS:
            return {
                ...state,
                currentItem: action.payload.response,
            };
        case OrderActionType.FETCH_STATS_SUCCESS:
            return {
                ...state,
                stats: action.payload.response,
            };
        case OrderActionType.UPDATE_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
            };
        default:
            return state;
    }
};
