export class FormDataBuilder {
    private formData = new FormData();

    addFile(name: string, file: File): this {
        if (file) {
            this.formData.set(name, file);
        }
        return this;
    }

    addData<T>(name: string, data: T): this {
        if (data) {
            const blob = new Blob([JSON.stringify(data)], {
                type: 'application/json',
            });
            this.formData.set(name, blob);
        }
        return this;
    }

    build(): FormData {
        return this.formData;
    }
}
