import { StoreAction, StoreActionType } from './actions';

import {
    CategoryDto,
    PhoneLinkResponse,
    ProductDto,
    PromoDto,
    RequestStatus,
    StoreDto,
    SubscriptionResponse,
} from 'types';

export interface StoreState {
    storeStatus: RequestStatus;
    store: StoreDto;
    categories: CategoryDto[];
    products: Record<string, ProductDto[]>;
    promos: PromoDto[];
    subscription?: SubscriptionResponse;
    phone?: PhoneLinkResponse;
}

export interface StoreSlice {
    store: StoreState;
}

const storeInitialState: StoreState = {
    storeStatus: RequestStatus.NONE,
    store: null,
    products: {},
    categories: [],
    promos: [],
    subscription: null,
    phone: null,
};

export const storeReducer = (state = storeInitialState, action: StoreAction): StoreState => {
    switch (action.type) {
        case StoreActionType.FETCH_SUCCESS:
            const products: Record<string, ProductDto[]> = {};
            action.payload.products.forEach(product => {
                const {
                    category: { categoryId },
                } = product;
                if (!products[categoryId]) {
                    products[categoryId] = [];
                }
                products[categoryId].push(product);
            });
            return {
                ...state,
                storeStatus: RequestStatus.SUCCESS,
                store: action.payload.store,
                products,
                categories: action.payload.categories,
                promos: action.payload.promos,
            };
        case StoreActionType.FETCH_NEW_PRODUCTS_PAGE_SUCCESS: {
            const products: Record<string, ProductDto[]> = state.products;
            action.payload.newProducts.forEach(product => {
                const {
                    category: { categoryId },
                } = product;
                if (!products[categoryId]) {
                    products[categoryId] = [];
                }
                products[categoryId].push(product);
            });
            return {
                ...state,
                products,
            };
        }
        case StoreActionType.FETCH_ID_REQUEST:
            return {
                storeStatus: RequestStatus.PENDING,
                ...state,
            };
        case StoreActionType.FETCH_STORE_SUCCESS:
            return {
                ...state,
                storeStatus: RequestStatus.SUCCESS,
                store: action.payload.store,
            };
        case StoreActionType.FETCH_STORE_FAILURE:
            return {
                ...state,
                storeStatus: RequestStatus.FAILURE,
                store: null,
            };
        case StoreActionType.UPDATE_STORE_OWNER_INFO:
            return {
                ...state,
                store: {
                    ...state.store,
                    fakeOwner: action.payload.fakeOwner,
                },
            };
        case StoreActionType.UPDATE_STORE_SUBSCRIPTION_INFO:
            return {
                ...state,
                subscription: action.payload.subscription,
            };
        case StoreActionType.CREATE_LINK:
            return {
                ...state,
                phone: action.payload.phone,
            };
        case StoreActionType.CHANGE_MODERATED_STATUS_SUCCESS:
            return {
                ...state,
                store: {
                    ...state.store,
                    moderated: action.payload.moderated,
                },
            };
        default:
            return state;
    }
};
