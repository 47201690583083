import { UserRole } from 'types';

export enum SignupActionType {
    ACTIVATE = 'auth/ACTIVATE',
    DEACTIVATE = 'auth/DEACTIVATE',
    SUBMIT_USERNAME_REQUEST = 'auth/SUBMIT_USERNAME_REQUEST',
    SUBMIT_USERNAME_SUCCESS = 'auth/SUBMIT_USERNAME_SUCCESS',
    SUBMIT_USERNAME_FAILURE = 'auth/SUBMIT_USERNAME_FAILURE',
    SUBMIT_OTP_SUCCESS = 'auth/SUBMIT_OTP_SUCCESS',
    SUBMIT_OTP_FAILURE = 'auth/SUBMIT_OTP_FAILURE',
    UPDATE_TIMER = 'auth/UPDATE_TIMER',
    SAVE_METRICS = 'auth/SAVE_METRICS',
    SAVE_METRICS_SUCCESS = 'auth/SAVE_METRICS_SUCCESS',
    SHOW_REGISTRATION_FORM = 'auth/SHOW_REGISTRATION_FORM',
    RESET_SIGNUP_STAGE = 'auth/RESET_SIGNUP_STAGE',
}

export interface BaseSignupAction {
    type: SignupActionType;
}

export interface SignupSubmitUsernameRequestAction extends BaseSignupAction {
    type: SignupActionType.SUBMIT_USERNAME_REQUEST;
    payload: {
        username: string;
        password: string;
        captcha: string;
        correlationId?: string;
        utm_source?: string;
    };
}

export interface SignupSaveMetricsAction extends BaseSignupAction {
    type: SignupActionType.SAVE_METRICS;
    payload: {
        correlationId?: string;
        utm_source?: string;
    };
}

export interface SignupSaveMetricsSuccessAction extends BaseSignupAction {
    type: SignupActionType.SAVE_METRICS_SUCCESS;
    payload: {
        correlationId: string;
    };
}

export interface SignupSubmitUsernameSuccessAction extends BaseSignupAction {
    type: SignupActionType.SUBMIT_USERNAME_SUCCESS;
    payload: {
        username: string;
    };
}

export interface SignupSubmitUsernameFailureAction extends BaseSignupAction {
    type: SignupActionType.SUBMIT_USERNAME_FAILURE;
}

export interface SignupSubmitOTPSuccessAction extends BaseSignupAction {
    type: SignupActionType.SUBMIT_OTP_SUCCESS;
}

export interface SignupSubmitOTPFailureAction extends BaseSignupAction {
    type: SignupActionType.SUBMIT_OTP_FAILURE;
}

export interface SignupUpdateTimerAction extends BaseSignupAction {
    type: SignupActionType.UPDATE_TIMER;
    payload: {
        timeLeft: number;
    };
}

export interface SignupShowRegistrationFormAction extends BaseSignupAction {
    type: SignupActionType.SHOW_REGISTRATION_FORM;
}

export interface SignupResetStageAction extends BaseSignupAction {
    type: SignupActionType.RESET_SIGNUP_STAGE;
}

export type SignupAction =
    | SignupSubmitUsernameRequestAction
    | SignupSubmitUsernameSuccessAction
    | SignupSubmitUsernameFailureAction
    | SignupSubmitOTPSuccessAction
    | SignupSubmitOTPFailureAction
    | SignupUpdateTimerAction
    | SignupSaveMetricsAction
    | SignupSaveMetricsSuccessAction
    | SignupShowRegistrationFormAction
    | SignupResetStageAction;

export const makeSignupSubmitUsernameRequestAction = (
    username: string,
    password: string,
    captcha: string,
    role: UserRole,
    correlationId?: string,
    utm_source?: string,
): SignupSubmitUsernameRequestAction => ({
    type: SignupActionType.SUBMIT_USERNAME_REQUEST,
    payload: {
        username,
        password,
        captcha,
        correlationId,
        utm_source,
    },
});

export const makeSignupSubmitUsernameSuccessAction = (username: string): SignupSubmitUsernameSuccessAction => ({
    type: SignupActionType.SUBMIT_USERNAME_SUCCESS,
    payload: {
        username,
    },
});

export const makeSignupSubmitUsernameFailureAction = (): SignupSubmitUsernameFailureAction => ({
    type: SignupActionType.SUBMIT_USERNAME_FAILURE,
});

export const makeSignupSubmitOTPSuccessAction = (): SignupSubmitOTPSuccessAction => ({
    type: SignupActionType.SUBMIT_OTP_SUCCESS,
});

export const makeSignupSubmitOTPFailureAction = (): SignupSubmitOTPFailureAction => ({
    type: SignupActionType.SUBMIT_OTP_FAILURE,
});

export const makeSignupUpdateTimerAction = (timeLeft: number): SignupUpdateTimerAction => ({
    type: SignupActionType.UPDATE_TIMER,
    payload: {
        timeLeft,
    },
});

export const makeSignupSaveMetricsAction = (correlationId?: string, utm_source?: string): SignupSaveMetricsAction => ({
    type: SignupActionType.SAVE_METRICS,
    payload: {
        correlationId,
        utm_source,
    },
});

export const makeSignupSaveMetricsSuccessAction = (correlationId: string): SignupSaveMetricsSuccessAction => ({
    type: SignupActionType.SAVE_METRICS_SUCCESS,
    payload: {
        correlationId,
    },
});

export const makeSignupShowRegistrationFormAction = (): SignupShowRegistrationFormAction => ({
    type: SignupActionType.SHOW_REGISTRATION_FORM,
});

export const makeSignupResetStageAction = (): SignupResetStageAction => ({
    type: SignupActionType.RESET_SIGNUP_STAGE,
});
