import { SignupSlice } from './reducer';

export const signupStageSelector = (state: SignupSlice) => {
    return state.signup.stage;
};

export const timeLeftSelector = (state: SignupSlice) => {
    return state.signup.timeLeft;
};

export const phoneNumberSelector = (state: SignupSlice) => {
    return state.signup.phoneNumber;
};

export const correlationIdSelector = (state: SignupSlice) => {
    return state.signup.correlationId;
};
