import { RegistrationSlice } from './reducer';

export const registrationSettingsDeliveryInfoSelector = (state: RegistrationSlice) => {
    return state.registration.settings.delivery;
};

export const registrationSettingsPickUpInfoSelector = (state: RegistrationSlice) => {
    return state.registration.settings.pickup;
};

export const registrationSettingsPaymentCashSelector = (state: RegistrationSlice) => {
    return state.registration.settings.paymentCash;
};

export const registrationSettingsPaymentCardSelector = (state: RegistrationSlice) => {
    return state.registration.settings.paymentCard;
};

export const registrationSettingsPaymentOnlineSelector = (state: RegistrationSlice) => {
    return state.registration.settings.paymentOnline;
};

export const registrationSettingsYandexCheckoutSelector = (state: RegistrationSlice) => {
    return state.registration.settings.yandexCheckout;
};

export const registrationSettingsPaymentCommentSelector = (state: RegistrationSlice) => {
    return state.registration.settings.paymentComment;
};

export const registrationSettingsDeliveryCommentSelector = (state: RegistrationSlice) => {
    return state.registration.settings.deliveryComment;
};

export const registrationSettingsEnabledSpecificDeliverySelector = (state: RegistrationSlice) => {
    return state.registration.settings.delivery.enabledSpecificDelivery;
};

export const registrationStageSelector = (state: RegistrationSlice) => {
    return state.registration.stage;
};

export const registrationChecklistSelector = (state: RegistrationSlice) => {
    return state.registration.checklistJson;
};

export const registrationPaymentStatusSelector = (state: RegistrationSlice) => {
    return state.registration.subscription.status;
};

export const registrationErrorMessageSelector = (state: RegistrationSlice) => {
    return state.registration.errorMessage;
};

export const registrationDeliveryProvidersSelector = (state: RegistrationSlice) => {
    return state.registration.providersList;
};

export const registrationWelcomeDialogClosed = (state: RegistrationSlice) => {
    return state.registration.welcomeDialogClosed;
};
