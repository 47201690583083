import { captureException } from '@sentry/nextjs';
import Axios, { AxiosRequestConfig } from 'axios';

import { FormDataBuilder } from './FormDataBuilder';
import { getJwt } from '../auth/auth';

const getDefaultHeaders = () => {
    const jwt = getJwt();
    return jwt ? { Authorization: jwt } : {};
};

const getFilteredHeaders = (headers: Record<string, string> = {}) => {
    return Object.keys(headers)
        .filter(key => headers[key])
        .reduce((acc, key) => ({ ...acc, [key]: headers[key] }), []);
};

export const getRequest = (url: string, headers?: Record<string, string>) => {
    const config: AxiosRequestConfig = {
        headers: {
            ...getDefaultHeaders(),
            ...getFilteredHeaders(headers),
        },
    };
    return Axios.get(url, config).catch(error => {
        captureException(error);
        throw error;
    });
};

export const postRequest = <T>(url: string, body: T, headers?: Record<string, string>) => {
    const config: AxiosRequestConfig = {
        headers: {
            ...getDefaultHeaders(),
            ...getFilteredHeaders(headers),
        },
    };
    return Axios.post(url, body, config).catch(error => {
        captureException(error);
        throw error;
    });
};

export const postMultipartRequest = <T>(url: string, formData: FormData, headers: Record<string, string>) => {
    const config: AxiosRequestConfig = {
        headers: {
            'content-type': 'multipart/form-data',
            ...getDefaultHeaders(),
            ...getFilteredHeaders(headers),
        },
    };
    return Axios.post(url, formData, config).catch(error => {
        captureException(error);
        throw error;
    });
};

export const putRequest = <T>(url: string, body: T, headers?: Record<string, string>) => {
    const config: AxiosRequestConfig = {
        headers: {
            ...getDefaultHeaders(),
            ...getFilteredHeaders(headers),
        },
    };
    return Axios.put(url, body, config).catch(error => {
        captureException(error);
        throw error;
    });
};

export const deleteRequest = <T>(url: string, body: T, headers?: Record<string, string>) => {
    const config: AxiosRequestConfig = {
        headers: {
            ...getDefaultHeaders(),
            ...getFilteredHeaders(headers),
        },
    };
    return Axios.delete(url, { ...config, data: body }).catch(error => {
        captureException(error);
        throw error;
    });
};

export const makeFormDataBuilder = (): FormDataBuilder => {
    return new FormDataBuilder();
};
