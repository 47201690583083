import { DeliveryServiceSlice } from './reducer';

export const isDeliveryServiceFormShownSelector = (state: DeliveryServiceSlice) => {
    return state.deliveryService.showDeliveryDetailsForm;
};

export const deliveryServicePlansSelector = (state: DeliveryServiceSlice) => {
    return state.deliveryService.deliveryPlans;
};

export const deliveryServiceOptionsSelector = (state: DeliveryServiceSlice) => {
    return state.deliveryService.deliveryServiceOptions;
};

export const deliveryServiceFormTabSelector = (state: DeliveryServiceSlice) => {
    return state.deliveryService.tab;
};

export const deliveryServiceWeightSelector = (state: DeliveryServiceSlice) => {
    return state.deliveryService.weight;
};
