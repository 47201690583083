import { captureException } from '@sentry/nextjs';
import { activatedSaga, redirectSaga } from 'core/sagas/sagas';
import { authStoreIdSelector } from '../auth/selectors';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
    deleteRequestSaga,
    formPostRequestSaga,
    formPutRequestSaga,
    getRequestSaga,
    handleFormErrorSaga,
    postRequestSaga,
} from '../rest/sagas';
import { Endpoint } from 'Endpoint';
import { Form } from './../../forms/types';
import { makeAppUIDisableAction, makeAppUIEnableAction } from './../app/actions';
import {
    makePromoFetchEntitySuccessAction,
    makePromosFetchSuccessAction,
    makePromoSubmitSuccessAction,
    PromoActionType,
    PromoDeleteRequestAction,
    PromoFetchRequestAction,
    PromoSubmitAction,
    PromoToggleActivePromoStateAction,
} from './actions';
import { PromoDiscountType } from 'types';
import { RoutePath } from 'RoutePath';
import { SagaIterator } from 'redux-saga';
import { makeSnackbarErrorAction } from '../snackbar/actions';

export function* promoSaga(): SagaIterator {
    yield takeEvery(PromoActionType.SUBMIT_PROMO, promoSubmitActionSaga);
    yield takeEvery(PromoActionType.TOGGLE_PROMO_ACTIVE_STATE, promoToggleActiveStateActionSaga);
    yield takeEvery(PromoActionType.DELETE_PROMO_REQUEST, promoDeleteRequestActionSaga);
    yield takeEvery(PromoActionType.FETCH_PROMO_REQUEST, promoFetchEntityActionSaga);
    yield call(activatedSaga, promoActivatedSaga, PromoActionType.ACTIVATE, PromoActionType.DEACTIVATE);
}

function* promoFetchDataSaga(storeId: string): SagaIterator {
    try {
        const {
            data: { promos },
        } = yield call(getRequestSaga, Endpoint.VENDOR_PROMOS, { storeId });
        yield put(makePromosFetchSuccessAction(promos));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('vendor:errors.promos'));
    }
}

function* promoActivatedSaga(): SagaIterator {
    const storeId = yield select(authStoreIdSelector);
    yield call(promoFetchDataSaga, storeId);
}

function* promoToggleActiveStateActionSaga(action: PromoToggleActivePromoStateAction): SagaIterator {
    try {
        const { promoId, active } = action.payload;
        const storeId = yield select(authStoreIdSelector);
        yield call(postRequestSaga, Endpoint.VENDOR_PROMO, { storeId, promoId }, {}, {}, { active });
        yield call(promoFetchDataSaga, storeId);
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('vendor:errors.promosToggle'));
    }
}

function* promoDeleteRequestActionSaga(action: PromoDeleteRequestAction): SagaIterator {
    try {
        const { promoId } = action.payload;
        const storeId = yield select(authStoreIdSelector);
        yield call(deleteRequestSaga, Endpoint.VENDOR_PROMO, { storeId, promoId });
        yield call(promoFetchDataSaga, storeId);
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('vendor:errors.promosDelete'));
    }
}

function* promoFetchEntityActionSaga(action: PromoFetchRequestAction): SagaIterator {
    try {
        const storeId = yield select(authStoreIdSelector);
        const { promoId } = action.payload;
        const { data } = yield call(getRequestSaga, Endpoint.VENDOR_PROMO, { storeId, promoId });
        yield put(makePromoFetchEntitySuccessAction(data));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('vendor:errors.promos'));
    }
}

function* promoSubmitActionSaga(action: PromoSubmitAction): SagaIterator {
    try {
        const { promo, promoId } = action.payload;
        const storeId = yield select(authStoreIdSelector);
        yield put(makeAppUIDisableAction());
        const { discount, discountItem, minOrderPrice, discountedItemId, discountType, deliveryType, ...rest } = promo;

        const body = {
            ...rest,
            conditions: {
                minOrderPrice: Number(minOrderPrice),
                deliveryType,
            },
            results: [
                {
                    discountType,
                    discount,
                    discountedItemId: discountType === PromoDiscountType.ORDER ? null : discountedItemId,
                },
            ],
        };

        if (promoId) {
            yield call(formPutRequestSaga, Form.PROMO_FORM, Endpoint.VENDOR_PROMO, { storeId, promoId }, body);
        } else {
            yield call(formPostRequestSaga, Form.PROMO_FORM, Endpoint.VENDOR_PROMOS, { storeId }, body);
        }
        yield call(redirectSaga, RoutePath.VENDOR_PROMO);
        const {
            data: { promos },
        } = yield call(getRequestSaga, Endpoint.VENDOR_PROMOS, { storeId });
        yield put(makePromosFetchSuccessAction(promos));
        yield put(makePromoSubmitSuccessAction());
    } catch (e) {
        captureException(e);
        yield call(handleFormErrorSaga, Form.PROMO_FORM, e);
    } finally {
        yield put(makeAppUIEnableAction());
    }
}
