import { captureException } from '@sentry/nextjs';
import { Form } from '../../forms/types';
import { SagaIterator } from 'redux-saga';
import { call, put, select, take, takeEvery } from 'redux-saga/effects';
import merge from 'lodash/merge';

import { authStoreIdSelector } from 'state/auth/selectors';
import { getRequestSaga, handleFormErrorSaga, postRequestSaga } from 'state/rest/sagas';
import { orderCurrentItemSelector } from 'state/order/selector';
import {
    DeliveryServiceActionType,
    DeliveryServicePrepareExternalDeliveryAction,
    DeliveryServiceUpdateDeliveryPlanListAction,
    makeDeliveryServiceChangeTabAction,
    makeDeliveryServiceHideDeliveryDetailsFormAction,
    makeDeliveryServiceInitExternalDeliveryFormDataAction,
    makeDeliveryServiceShowDeliveryDetailsFormAction,
    makeDeliveryServiceUpdateDeliveryPlanListFailureAction,
    makeDeliveryServiceUpdateDeliveryPlanListSuccessAction,
} from './actions';
import { deliveryServiceOptionsSelector, deliveryServiceWeightSelector } from './selector';

import { mapFormDataToDeliveryServiceOptions } from './utils';

import { Endpoint } from 'Endpoint';

import { DeliveryType, UnitType } from 'types';
import { DeliveryServiceFormTab } from './types';
import { updateSyncErrors } from 'redux-form';
import { makeSnackbarErrorAction } from '../snackbar/actions';

export function* deliveryServiceSaga(): SagaIterator {
    yield takeEvery(DeliveryServiceActionType.UPDATE_DELIVERY_PLAN_LIST, deliveryServiceUpdateDeliveryPlanListSaga);
    yield takeEvery(DeliveryServiceActionType.PREPARE_EXTERNAL_DELIVERY, deliveryServicePrepareExternalDeliverySaga);
}

export function* startDeliverySaga(orderId: string): SagaIterator {
    const storeId = yield select(authStoreIdSelector);
    const { data } = yield call(getRequestSaga, Endpoint.ORDER_ITEM, { storeId, orderId });
    const { deliveryType } = data.deliveryInfo;
    if (deliveryType === DeliveryType.DELIVERY_SERVICE) {
        yield put(makeDeliveryServiceShowDeliveryDetailsFormAction());
        while (true) {
            const action = yield take(DeliveryServiceActionType.SUBMIT_DELIVERY_DETAILS_FORM);
            const { formData } = action.payload;
            try {
                const options = yield select(deliveryServiceOptionsSelector);
                const updatedOptions = mapFormDataToDeliveryServiceOptions(formData);
                const body = merge({}, options, updatedOptions);
                yield call(postRequestSaga, Endpoint.STORE_DELIVERY_EXTERNAL_SEND, { storeId, orderId }, body);
                yield put(makeDeliveryServiceHideDeliveryDetailsFormAction());
                break;
            } catch (e) {
                const form = Form.DELIVERY_DETAILS_FORM;
                yield call(handleFormErrorSaga, form, e);
                if (e.isAxiosError && e.response) {
                    const { data, status } = e.response;
                    if (status === 400 && data && data.errors) {
                        // processing errors
                        //show first error
                        const controlNameWithError = Object.keys(data.errors)[0];
                        if (controlNameWithError) {
                            yield put(updateSyncErrors(form, {}, data.errors[controlNameWithError][0].message));
                        }
                    }
                }
            }
        }
    }
}

function* deliveryServiceUpdateDeliveryPlanListSaga(action: DeliveryServiceUpdateDeliveryPlanListAction): SagaIterator {
    const { weight } = action.payload;
    const storeId = yield select(authStoreIdSelector);
    try {
        const { data: plans } = yield call(getRequestSaga, Endpoint.STORE_DELIVERY_PLANS, { storeId, weight });
        yield put(makeDeliveryServiceUpdateDeliveryPlanListSuccessAction(plans, weight));
        yield put(makeDeliveryServiceChangeTabAction(DeliveryServiceFormTab.PLAN));
    } catch (e) {
        captureException(e);
        yield put(makeDeliveryServiceUpdateDeliveryPlanListFailureAction());
        yield put(makeSnackbarErrorAction('Введите корректные данные для доставки'));
    }
}

function* deliveryServicePrepareExternalDeliverySaga(
    action: DeliveryServicePrepareExternalDeliveryAction,
): SagaIterator {
    const storeId = yield select(authStoreIdSelector);
    const { orderId } = yield select(orderCurrentItemSelector);
    const weight = yield select(deliveryServiceWeightSelector);
    const { planId } = action.payload;
    try {
        const response = yield call(
            postRequestSaga,
            Endpoint.STORE_DELIVERY_EXTERNAL,
            { storeId, orderId, tariffId: planId, unitType: UnitType.KG, totalWeight: weight },
            {},
        );
        yield put(makeDeliveryServiceInitExternalDeliveryFormDataAction(response.data));
        yield put(makeDeliveryServiceChangeTabAction(DeliveryServiceFormTab.DETAILS));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('Введите корректные данные для доставки'));
    }
}
