import { captureException } from '@sentry/nextjs';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { DateTime } from 'luxon';

import { makeFormDataBuilder } from 'core/axios/axios';
import { daysOfWeek } from 'core/date/date';
import { activatedSaga, watchCityChangeSaga } from 'core/sagas/sagas';
import { splitOnce } from 'core/utils/utils';

import { makeAuthUpdateStoreIdAction } from '../auth/actions';
import { StoreDetailsActionType, StoreDetailsSubmitAction } from './actions';
import { makeAppUIDisableAction, makeAppUIEnableAction } from '../app/actions';
import { formPostMultipartRequestSaga, formPostRequestSaga, handleFormErrorSaga } from '../rest/sagas';
import { makeRegistrationSetStageAction } from '../registration/actions';
import { RegistrationStage } from '../registration/reducer';

import { Endpoint } from 'Endpoint';

import { Form } from 'forms/types';
import { CroppedArea, ImageDto, ImageType, StoreDto, VendorContactType } from 'types';

export function* storeDetailsSaga(): SagaIterator {
    yield takeEvery(StoreDetailsActionType.SUBMIT, storeDetailsSubmitSaga);
    yield call(
        activatedSaga,
        storeDetailsActivatedSaga,
        StoreDetailsActionType.ACTIVATE,
        StoreDetailsActionType.DEACTIVATE,
    );
}

export const convertTime = (t: DateTime): [number, number] => {
    return [t.hour, t.minute];
};

export function* storeDetailsSubmitImageSaga(file: File, crop: CroppedArea, type = ImageType.STORE): SagaIterator {
    const formData = makeFormDataBuilder()
        .addFile('file', file)
        .addData('imageData', { defaultImage: true, type })
        .addData('resizeData', crop)
        .build();
    const { data } = yield call(formPostMultipartRequestSaga, Form.STORE_DETAILS_FORM, Endpoint.IMAGE, {}, formData);
    return data;
}

function* storeDetailsSubmitSaga(action: StoreDetailsSubmitAction): SagaIterator {
    try {
        const {
            address,
            image,
            coverImage,
            coordinates,
            imageCrop,
            coverImageCrop,
            standardAddress,
            ...rest
        } = action.payload;
        yield put(makeAppUIDisableAction());
        let imageDto: ImageDto;
        let coverImageDto: ImageDto;
        if (image) {
            // @ts-ignore
            const imageId = yield call(storeDetailsSubmitImageSaga, image, imageCrop);
            imageDto = {
                imageId,
                type: ImageType.STORE,
                defaultImage: true,
            };
        }
        if (coverImage) {
            // @ts-ignore
            const imageId = yield call(storeDetailsSubmitImageSaga, coverImage, coverImageCrop, ImageType.STORE_COVER);
            coverImageDto = {
                imageId,
                type: ImageType.STORE_COVER,
                defaultImage: false,
            };
        }
        const [city, streetAddress] = splitOnce(address, ', ');
        const addressToSave = standardAddress
            ? {
                  ...standardAddress,
                  geoCoordinates: {
                      latitude: coordinates.lat,
                      longitude: coordinates.lon,
                  },
              }
            : {
                  geoCoordinates: {
                      latitude: coordinates.lat,
                      longitude: coordinates.lon,
                  },
                  city: {
                      name: standardAddress.city?.name ?? city ?? address,
                  },
                  street: {
                      name: standardAddress.street?.name ?? streetAddress ?? address,
                  },
                  formattedAddress: address,
              };
        const body = {
            address: addressToSave,
            contacts: [
                {
                    defaultContact: true,
                    type: VendorContactType.PHONE,
                    value: rest.phone,
                },
                {
                    type: VendorContactType.FB,
                    value: rest.facebook,
                },
                {
                    type: VendorContactType.INSTA,
                    value: rest.instagram,
                },
                {
                    type: VendorContactType.SITE,
                    value: rest.website,
                },
            ].filter(item => item.value),
            image: imageDto,
            coverImage: coverImageDto,
            name: rest.name,
            storeCategory: rest.storeCategory,
            customUrl: rest.customUrl,
            tags: rest.tags.map(name => ({ name })),
            schedule: Object.keys(rest.workingHours).map(k => {
                const day = rest.workingHours[+k];
                return {
                    dayOfWeek: daysOfWeek[+k],
                    opens: day.roundTheClockEnabled ? [0, 0] : convertTime(day.workingHoursFrom),
                    closes: day.roundTheClockEnabled ? [23, 59] : convertTime(day.workingHoursTo),
                    timeBreak: rest.workingHours[+k].breakEnabled
                        ? { start: convertTime(day.breakFrom), end: convertTime(day.breakTo) }
                        : null,
                };
            }),
        };
        const { data }: { data: StoreDto } = yield call(
            formPostRequestSaga,
            Form.STORE_DETAILS_FORM,
            Endpoint.REGISTRATION_STORE_DETAILS,
            {},
            body,
        );
        yield put(
            makeAuthUpdateStoreIdAction(
                data.storeId,
                rest.storeCategory,
                null,
                rest.customUrl,
                null,
                data.address.isoCode,
            ),
        );
        yield put(makeRegistrationSetStageAction(RegistrationStage.CATALOG));
    } catch (e) {
        captureException(e);
        yield call(handleFormErrorSaga, Form.STORE_DETAILS_FORM, e);
    } finally {
        yield put(makeAppUIEnableAction());
    }
}

function* storeDetailsActivatedSaga(): SagaIterator {
    yield all([call(geolocationSaga), call(watchCityChangeSaga, Form.STORE_DETAILS_FORM)]);
}

function* geolocationSaga(): SagaIterator {
    try {
        // const response = yield call(getRequestSaga, Endpoint.GEOLOCATION);
        // yield call(updateCityCoordinatesSaga, Form.STORE_DETAILS_FORM, response.data);
    } catch (e) {
        // captureException(e);
    }
}
