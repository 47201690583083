import { captureException } from '@sentry/nextjs';
import { SagaIterator } from 'redux-saga';
import { call, delay, put, select, take, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { getErrorForFieldOrGeneric } from 'core/form/form';
import { redirectSaga } from 'core/sagas/sagas';
import { LocalStorageKey, setLocalStorageItem } from 'core/storage/storage';

import {
    deleteRequestSaga,
    formPostRequestSaga,
    getRequestSaga,
    handleFormErrorSaga,
    postRequestSaga,
} from 'state/rest/sagas';
import { authStoreIdSelector } from 'state/auth/selectors';
import { setUserData } from 'state/auth/sagas';
import { AuthActionType } from 'state/auth/actions';
import { correlationIdSelector } from 'state/signup/selectors';
import { makeAppUIDisableAction, makeAppUIEnableAction } from 'state/app/actions';

import {
    makeRegistrationInitSettingsAction,
    makeRegistrationSetStageAction,
    makeRegistrationUpdateErrorMessageAction,
    makeRegistrationUpdatePaymentStatusAction,
    makeRegistrationUpdateProviderListAction,
    RegistrationActionType,
    RegistrationChangeApiShipTokenAction,
    RegistrationCloseWelcomeDialogAction,
    RegistrationSetChecklistAction,
    RegistrationSetStageAction,
    RegistrationSettingsDeliverySubmitAction,
    RegistrationSettingsPaymentCardSubmitAction,
    RegistrationSettingsPaymentCashSubmitAction,
    RegistrationSettingsPickUpSubmitAction,
    RegistrationSettingsYandexCheckoutCardSubmitAction,
    RegistrationSubmitStoreSettingAction,
} from './actions';
import { RegistrationStage, SubscriptionStatus } from './reducer';
import { registrationStageSelector } from './selector';

import { Endpoint } from 'Endpoint';
import { RoutePath } from 'RoutePath';

import { RegistrationSettingRequest } from './types';
import { Form } from 'forms/types';
import { PaidDeliveryType, UserRole, YandexCheckoutApplicationStatus } from 'types';
import { subscriptionFetchSaga } from 'state/subscription/sagas';
import { updateSyncErrors } from 'redux-form';
import { getJwt } from '../../core/auth/auth';
import { makeSnackbarErrorAction } from '../snackbar/actions';
import { PriceForUnit } from 'components/PriceForUnit/PriceForUnit';

export function* registrationSaga(): SagaIterator {
    yield takeEvery(RegistrationActionType.SUBMIT_SETTINGS, registrationSubmitSettingSaga);
    yield takeEvery(RegistrationActionType.SETTINGS_PAYMENT_CARD_SUBMIT, registrationSubmitCardPaymentSettingSaga);
    yield takeEvery(RegistrationActionType.SETTINGS_PAYMENT_CASH_SUBMIT, registrationSubmitCashPaymentSettingSaga);
    yield takeEvery(RegistrationActionType.SETTINGS_PICKUP_SUBMIT, registrationSubmitPickUpSettingSaga);
    yield takeEvery(RegistrationActionType.SETTINGS_DELIVERY_SUBMIT, registrationSubmitDeliverySettingSaga);
    yield takeEvery(
        RegistrationActionType.SETTINGS_YANDEX_CHECKOUT_CARD_SUBMIT,
        registrationSubmitYandexCheckoutSettingSaga,
    );
    yield takeEvery(
        RegistrationActionType.SETTINGS_YANDEX_CHECKOUT_DELETE,
        registrationDeleteYandexCheckoutRequestSaga,
    );
    yield takeEvery(RegistrationActionType.SET_STAGE, setStageSaga);
    yield takeEvery(RegistrationActionType.SET_STAGE, registrationInitSettingSaga);
    yield takeEvery(RegistrationActionType.SET_CHECKLIST, setChecklistSaga);
    yield takeEvery(RegistrationActionType.FETCH_STORE_SETTINGS, registrationFetchSettingSaga);
    yield takeEvery(RegistrationActionType.CHANGE_API_SHIP_TOKEN, changeApiShipTokenSaga);
    yield takeEvery(RegistrationActionType.VENDOR_SILENT_REGISTRATION, vendorSilentRegistration);
    yield takeEvery(RegistrationActionType.QUALIFY_STORE_AS_SIMPLE, qualifyStoreAsSimpleStore);
    yield takeEvery(RegistrationActionType.FINISH, registrationFinishSaga);
    yield call(registrationInitSettingSaga);
}

function* registrationInitSettingSaga(): SagaIterator {
    const jwt = getJwt();
    if (jwt) {
        return;
    }
    let storeId = yield select(authStoreIdSelector);
    if (!storeId) {
        yield take(AuthActionType.UPDATE_STORE_ID);
        storeId = yield select(authStoreIdSelector);
    }
    const stage = yield select(registrationStageSelector);
    if (stage !== RegistrationStage.SETTINGS && stage !== 0) {
        return;
    }
    try {
        yield call(fetchStoreSettings, storeId);
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('onboarding:errors.registration'));
    }
}

function* registrationFetchSettingSaga(): SagaIterator {
    try {
        const storeId = yield select(authStoreIdSelector);
        yield call(fetchStoreSettings, storeId);
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('onboarding:errors.registration'));
    }
}

function* fetchStoreSettings(storeId: string): SagaIterator {
    const store = yield call(getRequestSaga, Endpoint.STORE, { storeId });
    const yaCheckoutApp = yield call(getRequestSaga, Endpoint.REGISTRATION_YANDEX_CHECKOUT, { storeId });
    const deliveryService = yield call(getRequestSaga, Endpoint.STORE_DELIVERY_SERVICE, { storeId });
    // const options = yield call(getRequestSaga, Endpoint.REGISTRATION_SETTINGS, { storeId });
    // console.log(options);
    const { apiKey } = deliveryService.data;
    if (apiKey) {
        yield call(fetchDeliveryProvidersListSaga, apiKey);
    }
    yield put(
        makeRegistrationInitSettingsAction({
            store: store.data,
            yaCheckoutApp: yaCheckoutApp.data,
            deliveryService: deliveryService.data,
        }),
    );
}

function* registrationSubmitSettingSaga(action: RegistrationSubmitStoreSettingAction): SagaIterator {
    try {
        const storeId = yield select(authStoreIdSelector);
        const {
            pickUpFormValues,
            deliveryFormValues,
            cardPayment,
            cashPayment,
            paymentComment,
            deliveryComment,
            enabledSpecificDelivery,
        } = action.payload;

        const body: RegistrationSettingRequest = {
            cashPayment,
            cardPayment,
            pickup: pickUpFormValues,
            deliveryService: deliveryFormValues
                ? {
                      apiKey: deliveryFormValues.apiShipToken,
                      providers: [{ key: deliveryFormValues.deliveryProviderKey }],
                  }
                : undefined,
            specificDelivery: {
                enabled: enabledSpecificDelivery,
            },
            freeDelivery: deliveryFormValues
                ? {
                      enabled: deliveryFormValues.enabledFreeShipping,
                      maxDistance: deliveryFormValues.freeMaxDistance,
                      minOrderPrice: deliveryFormValues.minOrderPrice,
                  }
                : undefined,
            paidDelivery: deliveryFormValues
                ? {
                      enabled: deliveryFormValues.enabledPayShipping,
                      maxDistance: deliveryFormValues.payMaxDistance,
                      price: deliveryFormValues.price,
                  }
                : undefined,
            paymentComment,
            deliveryComment,
        };
        yield call(postRequestSaga, Endpoint.REGISTRATION_SETTINGS, { storeId }, body);
        const stage = yield select(registrationStageSelector);
        if (stage) {
            yield put(makeRegistrationSetStageAction(RegistrationStage.SUCCESS));
        } else {
            yield call(redirectSaga, RoutePath.VENDOR_DASHBOARD);
        }
    } catch (e) {
        captureException(e);
        const error = getErrorForFieldOrGeneric(e, '');
        if (error) {
            yield put(makeRegistrationUpdateErrorMessageAction(error));
        }
    }
}

function* redirectToSettingsSaga(): SagaIterator {
    const onboardingStep = yield select(registrationStageSelector);
    const to = onboardingStep ? RoutePath.REGISTRATION_SETTINGS : RoutePath.VENDOR_MENU_STORE_INFO;
    yield call(redirectSaga, to);
}

function* registrationSubmitPickUpSettingSaga(action: RegistrationSettingsPickUpSubmitAction): SagaIterator {
    try {
        const storeId = yield select(authStoreIdSelector);
        const pickup = action.payload;

        yield call(postRequestSaga, Endpoint.REGISTRATION_SETTINGS, { storeId }, { pickup });
        yield call(redirectToSettingsSaga);
        yield put(makeRegistrationUpdateErrorMessageAction(''));
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('onboarding:errors.pickUp'));
    }
}

function* registrationSubmitCashPaymentSettingSaga(action: RegistrationSettingsPaymentCashSubmitAction): SagaIterator {
    try {
        const storeId = yield select(authStoreIdSelector);
        const cashPayment = action.payload;

        yield call(postRequestSaga, Endpoint.REGISTRATION_SETTINGS, { storeId }, { cashPayment });
        yield call(redirectToSettingsSaga);
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('onboarding:errors.cash'));
    }
}

function* registrationSubmitCardPaymentSettingSaga(action: RegistrationSettingsPaymentCardSubmitAction): SagaIterator {
    try {
        const storeId = yield select(authStoreIdSelector);
        const cardPayment = { ...action.payload, paymentComment: '' };

        yield call(postRequestSaga, Endpoint.REGISTRATION_SETTINGS, { storeId }, { cardPayment });
        yield call(redirectToSettingsSaga);
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('onboarding:errors.card'));
    }
}

function* registrationSubmitDeliverySettingSaga(action: RegistrationSettingsDeliverySubmitAction): SagaIterator {
    try {
        const storeId = yield select(authStoreIdSelector);
        const deliveryFormValues = action.payload;
        const body = !deliveryFormValues.enabledSpecificDelivery
            ? {
                  freeDelivery: {
                      enabled: deliveryFormValues.enabledFreeShipping,
                      maxDistance: deliveryFormValues.freeMaxDistance,
                      minOrderPrice: deliveryFormValues.minOrderPrice,
                  },
                  paidDelivery: {
                      enabled: deliveryFormValues.enabledPayShipping,
                      maxDistance:
                          PaidDeliveryType.PER_KM === deliveryFormValues.pricingModel
                              ? deliveryFormValues.payMaxDistancePerKm
                              : deliveryFormValues.payMaxDistance,
                      price:
                          PaidDeliveryType.PER_KM === deliveryFormValues.pricingModel
                              ? deliveryFormValues.pricePerKm
                              : deliveryFormValues.price,
                      pricingModel: deliveryFormValues.pricingModel,
                  },
                  deliveryService: deliveryFormValues.enabledServiceDelivery
                      ? {
                            apiKey: deliveryFormValues.apiShipToken,
                            providers: [{ key: deliveryFormValues.deliveryProviderKey }],
                        }
                      : undefined,
                  specificDelivery: {
                      enabled: false,
                  },
                  deliveryComment: '',
              }
            : {
                  freeDelivery: {
                      enabled: false,
                  },
                  paidDelivery: {
                      enabled: false,
                  },
                  specificDelivery: {
                      enabled: true,
                  },
                  deliveryComment: '',
              };
        yield call(postRequestSaga, Endpoint.REGISTRATION_SETTINGS, { storeId }, body);
        yield call(redirectToSettingsSaga);
        yield put(makeRegistrationUpdateErrorMessageAction(''));
    } catch (e) {
        captureException(e);
        const form = Form.REGISTRATION_DELIVERY_FORM;
        yield call(handleFormErrorSaga, form, e);
        if (e.isAxiosError && e.response) {
            const { data, status } = e.response;
            if (status === 400 && data && data.errors) {
                // processing errors
                //show first error
                const controlNameWithError = Object.keys(data.errors)[0];
                if (controlNameWithError) {
                    yield put(updateSyncErrors(form, {}, data.errors[controlNameWithError][0].message));
                }
            }
        }
    }
}

function* registrationSubmitYandexCheckoutSettingSaga(
    action: RegistrationSettingsYandexCheckoutCardSubmitAction,
): SagaIterator {
    const storeId = yield select(authStoreIdSelector);

    try {
        if (action.payload.status !== YandexCheckoutApplicationStatus.PROCESSED) {
            const body = {
                inn: action.payload.inn,
                email: action.payload.email,
            };
            yield call(
                formPostRequestSaga,
                Form.YANDEX_CHECKOUT_SETUP_FORM,
                Endpoint.REGISTRATION_YANDEX_CHECKOUT,
                { storeId },
                body,
            );
        } else {
            //requst status = PROCESSED, so we can enable/disable YooKassa payment method
            const body = {
                onlinePayment: {
                    enabled: action.payload.isEnabled,
                    ...(action.payload.isEnabled ? { shopId: action.payload.shopId } : {}),
                },
            };
            yield call(
                formPostRequestSaga,
                Form.YANDEX_CHECKOUT_SETUP_FORM,
                Endpoint.REGISTRATION_SETTINGS,
                { storeId },
                body,
            );
        }
        yield call(redirectToSettingsSaga);
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('onboarding:errors.yandex'));
    }
}

function* registrationDeleteYandexCheckoutRequestSaga(): SagaIterator {
    const storeId = yield select(authStoreIdSelector);
    try {
        yield call(deleteRequestSaga, Endpoint.REGISTRATION_YANDEX_CHECKOUT, { storeId });
        yield call(redirectToSettingsSaga);
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('onboarding:errors.yandexDelete'));
    }
}

function* setStageSaga(action: RegistrationSetStageAction): SagaIterator {
    try {
        const { stage } = action.payload;
        yield call(postRequestSaga, Endpoint.ONBOARDING_STEP, {}, { onboardingStep: stage });

        // See ANY-914:
        // if (stage === RegistrationStage.SUCCESS) {
        //     yield call(checkPayment);
        // }
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('onboarding:errors.error'));
    }
}

function* setChecklistSaga(action: RegistrationSetChecklistAction): SagaIterator {
    try {
        const { checklistJson } = action.payload;
        const convertedChecklistJson = btoa(JSON.stringify(checklistJson));
        yield call(
            postRequestSaga,
            Endpoint.ONBOARDING_STEP,
            {},
            { onboardingStep: 0, checklistJson: convertedChecklistJson },
        );
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('onboarding:errors.error'));
    }
}

function* qualifyStoreAsSimpleStore(): SagaIterator {
    try {
        const storeId = yield select(authStoreIdSelector);
        yield call(postRequestSaga, Endpoint.QUALIFY_STORE_AS_SIMPLE, { storeId });
    } catch (e) {
        captureException(e);
        yield put(makeSnackbarErrorAction('onboarding:errors.error'));
    }
}

function* checkPayment(): SagaIterator {
    while (true) {
        try {
            yield put(makeRegistrationUpdatePaymentStatusAction(SubscriptionStatus.PENDING));
            const { data } = yield call(getRequestSaga, Endpoint.REGISTRATION_CHECK_PAYMENT);
            if (data.cancellation_details) {
                const { party, reason } = data.cancellation_details;
                if (party === 'merchant' && reason === 'canceled_by_merchant') {
                    yield put(makeRegistrationUpdatePaymentStatusAction(SubscriptionStatus.SUCCESS));
                    break;
                }
            }
            if (data.status === 'waiting_for_capture' || data.status === 'pending') {
                yield delay(2e3);
            } else {
                yield put(makeRegistrationUpdatePaymentStatusAction(SubscriptionStatus.FAILURE));
                break;
            }
        } catch (e) {
            captureException(e);
            yield put(makeRegistrationUpdatePaymentStatusAction(SubscriptionStatus.FAILURE));
            break;
        }
    }
}

function* changeApiShipTokenSaga(action: RegistrationChangeApiShipTokenAction): SagaIterator {
    const { token } = action.payload;
    if (token) {
        yield call(fetchDeliveryProvidersListSaga, token);
    }
}

function* fetchDeliveryProvidersListSaga(token: string): SagaIterator {
    try {
        const storeId = yield select(authStoreIdSelector);
        const providers = yield call(getRequestSaga, Endpoint.STORE_DELIVERY_PROVIDERS, { storeId, token });
        yield put(makeRegistrationUpdateProviderListAction(providers.data));
    } catch (e) {
        captureException(e);
        yield put(makeRegistrationUpdateProviderListAction([]));
    }
}

function* vendorSilentRegistration(): SagaIterator {
    try {
        const jwt = getJwt();
        if (!jwt) {
            const correlationId = yield select(correlationIdSelector);
            const response: AxiosResponse = yield call(
                postRequestSaga,
                Endpoint.VENDOR_FAKE_USER,
                {},
                {},
                {},
                { correlationId },
            );
            const role = UserRole.VENDOR;
            const username = 'fake';
            yield call(setUserData, response.headers.authorization, username, role);
        }
        setLocalStorageItem(LocalStorageKey.WELCOME_DIALOG_CLOSED, true);
        yield put(RegistrationCloseWelcomeDialogAction());
    } catch (e) {
        console.log(e);
    }
}

function* registrationFinishSaga(): SagaIterator {
    try {
        yield put(makeAppUIDisableAction());
        const storeId = yield select(authStoreIdSelector);
        yield call(postRequestSaga, Endpoint.VENDOR_SUBSCRIPTION_TRIAL, { storeId });
        yield call(subscriptionFetchSaga);
        yield put(makeRegistrationSetStageAction(0));
        yield put(makeAppUIEnableAction());
    } catch (e) {
        yield put(makeSnackbarErrorAction('onboarding:errors.registrationFinish'));
    }
}
