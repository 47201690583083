import React, { ElementType, FC, useMemo } from 'react';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import css from './Text.module.css';

export enum TextTypography {
    TITLE_STRONG,
    TITLE_STRONG_CHANGE,
    TITLE_LIGHT,
    DISPLAY_STRONG,
    DISPLAY_STRONG_CHANGE,
    DISPLAY_CHANGE,
    TITLE,
    TITLE_CHANGE,
    TITLE_ACCENT,
    TITLE_ACCENT_CHANGE,
    SUBTITLE_ACCENT,
    LEAD_LIGHT,
    LEAD_STRONG,
    LEAD_ACCENT,
    MENU,
    PARA,
    PARA_CHANGE,
    PARA_ACCENT,
    PARA_LIGHT,
    PARA_STRONG,
    LEAD_STRONG_CHANGE,
    PARA_LIGHT_CHANGE,
    DISPLAY_LIGHT_CHANGE,
    BODY,
    BODY_CHANGE,
    BODY_LIGHT,
    BODY_ACCENT,
    BODY_ACCENT_WHITE,
    BODY_HEADING,
    CAPTION,
    CAPTION_ACCENT,
    NOTE,
    NOTE_ACCENT,

    HUGE_BOLD,
    HUGE_TITLE_BOLD_CHANGE,
    TITLE_ACCENT_BOLD,
    TEXT_ACCENT_SEMIBOLD,
    TEXT_ACCENT_MEDIUM_CHANGE,
    TEXT_BODY_REGULAR_CHANGE,
}

export interface TextProps {
    typography: TextTypography;
    // Optional:
    onClick?: () => void;
    className?: string;
    shouldCollapseText?: boolean;
    component?: 'span' | 'p' | 'div';
    itemProp?: string;
    content?: string;
    color?: string;
}

const components: Record<string, ElementType> = {
    [TextTypography.TITLE_STRONG]: 'h2',
    [TextTypography.TITLE_STRONG_CHANGE]: 'h1',
    [TextTypography.TITLE_LIGHT]: 'h2',
    [TextTypography.DISPLAY_STRONG]: 'h1',
    [TextTypography.DISPLAY_STRONG_CHANGE]: 'h2',
    [TextTypography.DISPLAY_CHANGE]: 'h1',
    [TextTypography.TITLE]: 'h2',
    [TextTypography.TITLE_CHANGE]: 'h2',
    [TextTypography.TITLE_ACCENT]: 'h2',
    [TextTypography.TITLE_ACCENT_CHANGE]: 'h2',
    [TextTypography.SUBTITLE_ACCENT]: 'h3',
    [TextTypography.LEAD_LIGHT]: 'h4',
    [TextTypography.LEAD_STRONG]: 'h4',
    [TextTypography.LEAD_STRONG_CHANGE]: 'h4',
    [TextTypography.LEAD_ACCENT]: 'h4',
    [TextTypography.MENU]: 'h2',
    [TextTypography.PARA]: 'span',
    [TextTypography.PARA_CHANGE]: 'span',
    [TextTypography.PARA_ACCENT]: 'span',
    [TextTypography.PARA_LIGHT]: 'span',
    [TextTypography.PARA_LIGHT_CHANGE]: 'span',
    [TextTypography.PARA_STRONG]: 'span',
    [TextTypography.DISPLAY_LIGHT_CHANGE]: 'p',
    [TextTypography.BODY]: 'p',
    [TextTypography.BODY_CHANGE]: 'p',
    [TextTypography.BODY_LIGHT]: 'p',
    [TextTypography.BODY_ACCENT]: 'b',
    [TextTypography.BODY_HEADING]: 'h3',
    [TextTypography.MENU]: 'h2',
    [TextTypography.NOTE]: 'span',
    [TextTypography.CAPTION]: 'span',
    [TextTypography.CAPTION_ACCENT]: 'span',
    [TextTypography.NOTE_ACCENT]: 'span',

    [TextTypography.HUGE_TITLE_BOLD_CHANGE]: 'h1',
    [TextTypography.TITLE_ACCENT_BOLD]: 'h1',
    [TextTypography.TEXT_ACCENT_SEMIBOLD]: 'h2',
    [TextTypography.TEXT_ACCENT_MEDIUM_CHANGE]: 'h3',
    [TextTypography.TEXT_BODY_REGULAR_CHANGE]: 'h4',
};

export const Text: FC<TextProps> = ({
    className,
    component,
    typography,
    shouldCollapseText = false,
    onClick,
    children,
    itemProp,
    content,
}) => {
    return (
        <Typography
            onClick={onClick}
            className={classNames(
                shouldCollapseText && css.should_collapse_text,
                css[TextTypography[typography]?.toLowerCase()],
                className,
            )}
            component={component ?? components[typography]}
            itemProp={itemProp}
            content={content}
        >
            {children}
        </Typography>
    );
};
