import { OnboardingScreen, SellerFormData, StoreFormData } from 'pages/Onboarding/types';

export enum OnboardingActionType {
    SET_SCREEN = '[onboarding] INIT',
    BACK = '[onboarding] BACK',
    SAVE_SELLER_FORM_DATA = '[onboarding] SAVE_SELLER_FORM_DATA',
    SUBMIT_SELLER_FORM_REQUEST = '[onboarding] SUBMIT_SELLER_FORM_REQUEST',
    FETCH_STORE = '[onboarding] FETCH_STORE',
    FETCH_STORE_SUCCESS = '[onboarding] FETCH_STORE_SUCCESS',
    SAVE_STORE_FORM_DATA = '[onboarding] SAVE_STORE_FORM_DATA',
    SUBMIT_STORE_FORM_REQUEST = '[onboarding] SUBMIT_REQUEST',
    FINISH = '[onboarding] FINISH',
    SET_PASSWORD = '[onboarding] SET_PASSWORD',
    SKIP_PASSWORD = '[onboarding] SKIP_PASSWORD',
    INIT_FAKE_STORE = '[onboarding] INIT_FAKE_STORE',
}

export interface OnboardingBaseAction {
    type: OnboardingActionType;
}

export interface OnboardingInitAction extends OnboardingBaseAction {
    type: OnboardingActionType.SET_SCREEN;
    payload: {
        onboardingScreen: OnboardingScreen;
    };
}

export interface OnboardingBackAction extends OnboardingBaseAction {
    type: OnboardingActionType.BACK;
}

export interface OnboardingSaveSellerFormDataAction extends OnboardingBaseAction {
    type: OnboardingActionType.SAVE_SELLER_FORM_DATA;
    payload: SellerFormData;
}

export interface OnboardingSubmitSellerFormRequestAction extends OnboardingBaseAction {
    type: OnboardingActionType.SUBMIT_SELLER_FORM_REQUEST;
    payload: {
        jwt: string;
        phoneNumber: string;
        userAlreadyExists?: boolean;
    };
}

export interface OnboardingFetchStoreAction extends OnboardingBaseAction {
    type: OnboardingActionType.FETCH_STORE;
}

export interface OnboardingFetchStoreSuccessAction extends OnboardingBaseAction {
    type: OnboardingActionType.FETCH_STORE_SUCCESS;
    payload: StoreFormData;
}

export interface OnboardingSaveStoreFormDataAction extends OnboardingBaseAction {
    type: OnboardingActionType.SAVE_STORE_FORM_DATA;
    meta: {
        skipPhoneVerification: boolean;
    };
    payload: StoreFormData;
}

export interface OnboardingSubmitStoreFormRequestAction extends OnboardingBaseAction {
    type: OnboardingActionType.SUBMIT_STORE_FORM_REQUEST;
}

export interface OnboardingFinishAction extends OnboardingBaseAction {
    type: OnboardingActionType.FINISH;
}

export interface OnboardingSetPasswordAction extends OnboardingBaseAction {
    type: OnboardingActionType.SET_PASSWORD;
    payload: {
        password: string;
    };
}

export interface OnboardingSkipPasswordAction extends OnboardingBaseAction {
    type: OnboardingActionType.SKIP_PASSWORD;
}

export interface OnboardingInitFakeStoreAction extends OnboardingBaseAction {
    type: OnboardingActionType.INIT_FAKE_STORE;
}

export type OnboardingAction =
    | OnboardingInitAction
    | OnboardingBackAction
    | OnboardingSaveSellerFormDataAction
    | OnboardingSubmitSellerFormRequestAction
    | OnboardingFetchStoreAction
    | OnboardingFetchStoreSuccessAction
    | OnboardingSaveStoreFormDataAction
    | OnboardingSubmitStoreFormRequestAction
    | OnboardingFinishAction
    | OnboardingSetPasswordAction
    | OnboardingSkipPasswordAction
    | OnboardingInitFakeStoreAction;

export const makeOnboardingSetScreenAction = (onboardingScreen: OnboardingScreen): OnboardingInitAction => ({
    type: OnboardingActionType.SET_SCREEN,
    payload: {
        onboardingScreen,
    },
});

export const makeOnboardingBackAction = (): OnboardingBackAction => ({
    type: OnboardingActionType.BACK,
});

export const makeOnboardingSaveSellerFormDataAction = (
    payload: SellerFormData,
): OnboardingSaveSellerFormDataAction => ({
    type: OnboardingActionType.SAVE_SELLER_FORM_DATA,
    payload,
});

export const makeOnboardingSubmitSellerFormRequestAction = (
    jwt: string,
    phoneNumber: string,
    userAlreadyExists?: boolean,
): OnboardingSubmitSellerFormRequestAction => ({
    type: OnboardingActionType.SUBMIT_SELLER_FORM_REQUEST,
    payload: {
        jwt,
        phoneNumber,
        userAlreadyExists,
    },
});

export const makeOnboardingFetchStoreAction = (): OnboardingFetchStoreAction => ({
    type: OnboardingActionType.FETCH_STORE,
});

export const makeOnboardingFetchStoreSuccessAction = (payload: StoreFormData): OnboardingFetchStoreSuccessAction => ({
    type: OnboardingActionType.FETCH_STORE_SUCCESS,
    payload,
});

export const makeOnboardingSaveStoreFormDataAction = (
    payload: StoreFormData,
    skipPhoneVerification: boolean,
): OnboardingSaveStoreFormDataAction => ({
    type: OnboardingActionType.SAVE_STORE_FORM_DATA,
    meta: {
        skipPhoneVerification,
    },
    payload,
});

export const makeOnboardingSubmitRequestAction = (): OnboardingSubmitStoreFormRequestAction => ({
    type: OnboardingActionType.SUBMIT_STORE_FORM_REQUEST,
});

export const makeOnboardingFinishAction = (): OnboardingFinishAction => ({
    type: OnboardingActionType.FINISH,
});

export const makeOnboardingSetPasswordAction = (password: string): OnboardingSetPasswordAction => ({
    type: OnboardingActionType.SET_PASSWORD,
    payload: {
        password,
    },
});

export const makeOnboardingSkipPasswordAction = (): OnboardingSkipPasswordAction => ({
    type: OnboardingActionType.SKIP_PASSWORD,
});

export const makeOnboardingInitFakeStoreAction = (): OnboardingInitFakeStoreAction => ({
    type: OnboardingActionType.INIT_FAKE_STORE,
});
