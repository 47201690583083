import { SignupAction, SignupActionType } from './actions';

export enum SignupStage {
    SUBMIT,
    CONFIRM,
    FINISHED,
    OFFER,
}

export interface SignupState {
    stage: SignupStage;
    timeLeft: number;
    phoneNumber: string;
    correlationId: string;
}

export interface SignupSlice {
    signup: SignupState;
}

const initialState: SignupState = {
    stage: SignupStage.OFFER,
    timeLeft: null,
    phoneNumber: null,
    correlationId: null,
};

export const signupReducer = (state = initialState, action: SignupAction): SignupState => {
    switch (action.type) {
        case SignupActionType.SUBMIT_USERNAME_SUCCESS:
            return {
                ...state,
                stage: SignupStage.CONFIRM,
                phoneNumber: action.payload.username,
            };
        case SignupActionType.SUBMIT_OTP_SUCCESS:
            return {
                ...state,
                stage: SignupStage.FINISHED,
            };
        case SignupActionType.UPDATE_TIMER:
            return {
                ...state,
                timeLeft: Math.max(0, action.payload.timeLeft),
            };
        case SignupActionType.SAVE_METRICS_SUCCESS:
            return {
                ...state,
                correlationId: action.payload.correlationId,
            };
        case SignupActionType.SHOW_REGISTRATION_FORM:
            return {
                ...state,
                stage: SignupStage.SUBMIT,
            };
        case SignupActionType.RESET_SIGNUP_STAGE:
            return {
                ...state,
                stage: SignupStage.OFFER,
            };
        default:
            return state;
    }
};
