import { applyMiddleware, combineReducers, createStore, Middleware, StoreEnhancer } from 'redux';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { reducer as formReducer } from 'redux-form';
import { History } from 'history';

import { getBrowserHistory } from 'browserHistory';

import { appReducer } from './app/reducer';
import { authReducer, getAuthStateFromLS } from './auth/reducer';
import { catalogReducer } from './catalog/reducer';
import { checkoutReducer } from './checkout/reducer';
import { deliveryServiceReducer } from './deliveryService/reducer';
import { feedReducer, getFeedStateFromLS } from './feed/reducer';
import { onboardingReducer } from './onboarding/reducer';
import { orderReducer } from './order/reducer';
import { productReducer } from './product/reducer';
import { getRegistartionStateFromLS, registrationReducer } from './registration/reducer';
import { signupReducer } from './signup/reducer';
import { snackbarReducer } from './snackbar/reducer';
import { storeDetailsReducer } from './storeDetails/reducer';
import { storeReducer } from './store/reducer';
import { getSubscriptionStateFromLS, subscriptionReducer } from './subscription/reducer';
import { userMenuReducer } from './userMenu/reducer';
import { vendorMenuReducer } from './vendorMenu/reducer';

import { appSaga } from './app/sagas';
import { authSaga } from './auth/sagas';
import { catalogSaga } from './catalog/sagas';
import { checkoutSaga } from './checkout/sagas';
import { deliveryServiceSaga } from './deliveryService/sagas';
import { feedSaga } from './feed/sagas';
import { onboardingSaga } from './onboarding/sagas';
import { orderSaga } from './order/sagas';
import { previewSaga } from './preview/sagas';
import { productSaga } from './product/sagas';
import { registrationSaga } from './registration/sagas';
import { signupSaga } from './signup/sagas';
import { snackbarSaga } from './snackbar/sagas';
import { storeSaga } from './store/sagas';
import { storeDetailsSaga } from './storeDetails/sagas';
import { subscriptionSaga } from './subscription/sagas';
import { userMenuSaga } from './userMenu/sagas';
import { promoReducer } from './promo/reducer';
import { promoSaga } from './promo/sagas';
import { adminSaga } from './adminReport/sagas';
import { reportReducer } from './adminReport/reducer';

const rootReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    catalog: catalogReducer,
    promo: promoReducer,
    checkout: checkoutReducer,
    deliveryService: deliveryServiceReducer,
    feed: feedReducer,
    form: formReducer,
    onboarding: onboardingReducer,
    order: orderReducer,
    product: productReducer,
    registration: registrationReducer,
    signup: signupReducer,
    snackbar: snackbarReducer,
    store: storeReducer,
    storeDetails: storeDetailsReducer,
    subscription: subscriptionReducer,
    userMenu: userMenuReducer,
    vendorMenu: vendorMenuReducer,
    adminReport: reportReducer,
});

function* rootSaga() {
    yield all([
        appSaga(),
        authSaga(),
        catalogSaga(),
        checkoutSaga(),
        deliveryServiceSaga(),
        feedSaga(),
        onboardingSaga(),
        orderSaga(),
        previewSaga(),
        productSaga(),
        registrationSaga(),
        signupSaga(),
        snackbarSaga(),
        storeSaga(),
        storeDetailsSaga(),
        subscriptionSaga(),
        userMenuSaga(),
        promoSaga(),
        adminSaga(),
    ]);
}

const mainReducer = (state: any = {}, action: any) => {
    return action.type === HYDRATE
        ? //@ts-ignore
          {
              ...action.payload,
              feed: { ...action.payload.feed, ...getFeedStateFromLS() },
              auth: { ...action.payload.auth, ...getAuthStateFromLS() },
              registration: { ...action.payload.registration, ...getRegistartionStateFromLS() },
              subscription: { ...action.payload.subscription, ...getSubscriptionStateFromLS() },
          }
        : rootReducer(state, action);
};

const bindMiddleware = (middleware: Middleware[]): StoreEnhancer => {
    if (process.env.NODE_ENV !== 'production') {
        const { composeWithDevTools } = require('redux-devtools-extension');
        return composeWithDevTools(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};

export const makeStore = (history?: History) => {
    const sagaMiddleware = createSagaMiddleware({
        context: {
            history: history || {},
        },
    });

    const store = createStore(mainReducer, bindMiddleware([sagaMiddleware]));
    //@ts-ignore
    store.sagaTask = sagaMiddleware.run(rootSaga);

    return store;
};

const isServer = typeof window === 'undefined';
//@ts-ignore
export const wrapper = createWrapper(() => makeStore(isServer ? undefined : getBrowserHistory()), { debug: false });
