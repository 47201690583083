import { DeliveryPlan, DeliveryServiceFormTab, DeliveryServiceOptions, SubmitDeliveryDetailsFormData } from './types';

export enum DeliveryServiceActionType {
    CHANGE_TAB = 'deliveryService/CHANGE_TAB',
    SHOW_DELIVERY_DETAILS_FORM = 'deliveryService/SHOW_DELIVERY_DETAILS_FORM',
    HIDE_DELIVERY_DETAILS_FORM = 'deliveryService/HIDE_DELIVERY_DETAILS_FORM',
    SUBMIT_DELIVERY_DETAILS_FORM = 'deliveryService/SUBMIT_DELIVERY_DETAILS_FORM',
    UPDATE_DELIVERY_PLAN_LIST = 'deliveryService/UPDATE_DELIVERY_PLAN_LIST',
    UPDATE_DELIVERY_PLAN_LIST_SUCCESS = 'deliveryService/UPDATE_DELIVERY_PLAN_LIST_SUCCESS',
    UPDATE_DELIVERY_PLAN_LIST_FAILURE = 'deliveryService/UPDATE_DELIVERY_PLAN_LIST_FAILURE',
    PREPARE_EXTERNAL_DELIVERY = 'deliveryService/PREPARE_EXTERNAL_DELIVERY',
    INIT_EXTERNAL_DELIVERY_FORM_DATA = 'deliveryService/INIT_EXTERNAL_DELIVERY_FORM_DATA',
}

export interface DeliveryServiceBaseAction {
    type: DeliveryServiceActionType;
}

export interface DeliveryServiceChangeTabAction extends DeliveryServiceBaseAction {
    type: DeliveryServiceActionType.CHANGE_TAB;
    payload: {
        tab: DeliveryServiceFormTab;
    };
}

export interface DeliveryServiceShowDeliveryDetailsFormAction extends DeliveryServiceBaseAction {
    type: DeliveryServiceActionType.SHOW_DELIVERY_DETAILS_FORM;
}

export interface DeliveryServiceHideDeliveryDetailsFormAction extends DeliveryServiceBaseAction {
    type: DeliveryServiceActionType.HIDE_DELIVERY_DETAILS_FORM;
}

export interface DeliveryServiceSubmitDeliveryDetailsFormAction extends DeliveryServiceBaseAction {
    type: DeliveryServiceActionType.SUBMIT_DELIVERY_DETAILS_FORM;
    payload: {
        formData: SubmitDeliveryDetailsFormData;
    };
}

export interface DeliveryServiceUpdateDeliveryPlanListAction extends DeliveryServiceBaseAction {
    type: DeliveryServiceActionType.UPDATE_DELIVERY_PLAN_LIST;
    payload: {
        weight: number;
    };
}

export interface DeliveryServiceUpdateDeliveryPlanListSuccessAction extends DeliveryServiceBaseAction {
    type: DeliveryServiceActionType.UPDATE_DELIVERY_PLAN_LIST_SUCCESS;
    payload: {
        plans: DeliveryPlan[];
        weight: number;
    };
}

export interface DeliveryServiceUpdateDeliveryPlanListFailureAction extends DeliveryServiceBaseAction {
    type: DeliveryServiceActionType.UPDATE_DELIVERY_PLAN_LIST_FAILURE;
}

export interface DeliveryServicePrepareExternalDeliveryAction extends DeliveryServiceBaseAction {
    type: DeliveryServiceActionType.PREPARE_EXTERNAL_DELIVERY;
    payload: {
        planId: number;
    };
}

export interface DeliveryServiceInitExternalDeliveryFormDataAction extends DeliveryServiceBaseAction {
    type: DeliveryServiceActionType.INIT_EXTERNAL_DELIVERY_FORM_DATA;
    payload: {
        options: DeliveryServiceOptions;
    };
}

export type DeliveryServiceAction =
    | DeliveryServiceChangeTabAction
    | DeliveryServiceShowDeliveryDetailsFormAction
    | DeliveryServiceHideDeliveryDetailsFormAction
    | DeliveryServiceSubmitDeliveryDetailsFormAction
    | DeliveryServiceUpdateDeliveryPlanListAction
    | DeliveryServiceUpdateDeliveryPlanListSuccessAction
    | DeliveryServiceUpdateDeliveryPlanListFailureAction
    | DeliveryServicePrepareExternalDeliveryAction
    | DeliveryServiceInitExternalDeliveryFormDataAction;

export const makeDeliveryServiceChangeTabAction = (tab: DeliveryServiceFormTab): DeliveryServiceChangeTabAction => ({
    type: DeliveryServiceActionType.CHANGE_TAB,
    payload: {
        tab,
    },
});

export const makeDeliveryServiceShowDeliveryDetailsFormAction = (): DeliveryServiceShowDeliveryDetailsFormAction => ({
    type: DeliveryServiceActionType.SHOW_DELIVERY_DETAILS_FORM,
});

export const makeDeliveryServiceHideDeliveryDetailsFormAction = (): DeliveryServiceHideDeliveryDetailsFormAction => ({
    type: DeliveryServiceActionType.HIDE_DELIVERY_DETAILS_FORM,
});

export const makeDeliveryServiceSubmitDeliveryDetailsFormAction = (
    formData: SubmitDeliveryDetailsFormData,
): DeliveryServiceSubmitDeliveryDetailsFormAction => ({
    type: DeliveryServiceActionType.SUBMIT_DELIVERY_DETAILS_FORM,
    payload: {
        formData,
    },
});

export const makeDeliveryServiceUpdateDeliveryPlanListAction = (
    weight: number,
): DeliveryServiceUpdateDeliveryPlanListAction => ({
    type: DeliveryServiceActionType.UPDATE_DELIVERY_PLAN_LIST,
    payload: {
        weight,
    },
});

export const makeDeliveryServiceUpdateDeliveryPlanListSuccessAction = (
    plans: DeliveryPlan[],
    weight: number,
): DeliveryServiceUpdateDeliveryPlanListSuccessAction => ({
    type: DeliveryServiceActionType.UPDATE_DELIVERY_PLAN_LIST_SUCCESS,
    payload: {
        plans,
        weight,
    },
});

export const makeDeliveryServiceUpdateDeliveryPlanListFailureAction = (): DeliveryServiceUpdateDeliveryPlanListFailureAction => ({
    type: DeliveryServiceActionType.UPDATE_DELIVERY_PLAN_LIST_FAILURE,
});

export const makeDeliveryServicePrepareExternalDelivery = (
    planId: number,
): DeliveryServicePrepareExternalDeliveryAction => ({
    type: DeliveryServiceActionType.PREPARE_EXTERNAL_DELIVERY,
    payload: {
        planId,
    },
});

export const makeDeliveryServiceInitExternalDeliveryFormDataAction = (
    options: DeliveryServiceOptions,
): DeliveryServiceInitExternalDeliveryFormDataAction => ({
    type: DeliveryServiceActionType.INIT_EXTERNAL_DELIVERY_FORM_DATA,
    payload: {
        options,
    },
});
