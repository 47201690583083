import { UserMenuAction, UserMenuActionType } from './actions';
import { ProfileFormData } from '../../forms/ProfileForm/ProfileForm';
import { OrderListResponse, OrderResponse } from '../order/types';
import { StoreDetailsSubmitRequest } from '../storeDetails/types';
import { AddressListItemData } from './types';
import { AddressFormData } from '../../forms/AddressForm/AddressForm';
import { StoreNotificationDto } from 'types';

export enum UserMenuSelectedItem {
    MENU,
    PROFILE,
    ORDERS,
    ORDER,
    SUBSCRIPTION,
    ADDRESSES,
    ADDRESS,
}

export interface UserMenuState {
    showMenu: boolean;
    routing: {
        selectedItem: UserMenuSelectedItem;
        params: Record<string, string>;
    };
    profile: ProfileFormData;
    storeInfo: StoreDetailsSubmitRequest;
    storeNotificationSettings: StoreNotificationDto[];
    orders: OrderListResponse;
    order: OrderResponse;
    addresses: AddressListItemData[];
    address: AddressFormData;
}

export interface UserMenuSlice {
    userMenu: UserMenuState;
}

const DEFAULT_ADDRESS_STATE = {
    title: '',
    address: '',
    coordinates: { lat: null as number, lon: null as number },
    comment: '',
    isDefault: false,
};

const initialUserMenuState: UserMenuState = {
    showMenu: false,
    routing: {
        selectedItem: UserMenuSelectedItem.MENU,
        params: {},
    },
    profile: {
        name: null,
        email: null,
        birthDate: null,
    },
    storeInfo: null,
    storeNotificationSettings: null,
    orders: [],
    order: null,
    addresses: [],
    address: DEFAULT_ADDRESS_STATE,
};

export const userMenuReducer = (state = initialUserMenuState, action: UserMenuAction): UserMenuState => {
    switch (action.type) {
        case UserMenuActionType.SHOW:
            return {
                ...state,
                showMenu: true,
            };
        case UserMenuActionType.HIDE:
            return {
                ...state,
                showMenu: false,
            };
        case UserMenuActionType.SUBMIT_PROFILE:
            return {
                ...state,
                profile: action.payload,
            };
        case UserMenuActionType.FETCH_PROFILE_SUCCESS:
            return {
                ...state,
                profile: action.payload,
            };
        case UserMenuActionType.FETCH_ORDERS_SUCCESS:
            return {
                ...state,
                orders: action.payload.orders,
            };
        case UserMenuActionType.FETCH_ORDER_SUCCESS:
            return {
                ...state,
                order: action.payload.order,
            };
        case UserMenuActionType.FETCH_ADDRESSES_SUCCESS:
            return {
                ...state,
                addresses: action.payload.addresses,
            };
        case UserMenuActionType.FETCH_ADDRESS_SUCCESS:
            return {
                ...state,
                address: action.payload.address,
            };
        case UserMenuActionType.NAVIGATE:
            return {
                ...state,
                showMenu: true,
                routing: {
                    selectedItem: action.payload.selectedItem,
                    params: action.payload.params,
                },
                address: DEFAULT_ADDRESS_STATE,
            };
        case UserMenuActionType.FETCH_STORE_INFO_SUCCESS:
            return {
                ...state,
                storeInfo: action.payload.storeInfo,
            };
        case UserMenuActionType.FETCH_STORE_NOTIFICATION_SETTINGS_SUCCESS:
            return {
                ...state,
                storeNotificationSettings: action.payload.storeNotifications,
            };
        case UserMenuActionType.CLEAR_STORE_INFO:
            return {
                ...state,
                storeInfo: null,
            };
        default:
            return state;
    }
};
