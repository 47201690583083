import { DeliveryServiceAction, DeliveryServiceActionType } from './actions';

import { DeliveryPlan, DeliveryServiceFormTab, DeliveryServiceOptions } from './types';

export interface DeliveryServiceState {
    showDeliveryDetailsForm: boolean;
    deliveryPlans: DeliveryPlan[];
    deliveryServiceOptions: DeliveryServiceOptions;
    tab: DeliveryServiceFormTab;
    weight: number;
}

export interface DeliveryServiceSlice {
    deliveryService: DeliveryServiceState;
}

const initialDeliveryServiceState: DeliveryServiceState = {
    showDeliveryDetailsForm: false,
    deliveryPlans: [],
    deliveryServiceOptions: null,
    tab: DeliveryServiceFormTab.WEIGHT,
    weight: null,
};

export const deliveryServiceReducer = (
    state = initialDeliveryServiceState,
    action: DeliveryServiceAction,
): DeliveryServiceState => {
    switch (action.type) {
        case DeliveryServiceActionType.SHOW_DELIVERY_DETAILS_FORM:
            return {
                ...state,
                showDeliveryDetailsForm: true,
            };
        case DeliveryServiceActionType.HIDE_DELIVERY_DETAILS_FORM:
            return {
                ...state,
                showDeliveryDetailsForm: false,
            };
        case DeliveryServiceActionType.UPDATE_DELIVERY_PLAN_LIST_SUCCESS:
            return {
                ...state,
                deliveryPlans: action.payload.plans,
                weight: action.payload.weight,
            };
        case DeliveryServiceActionType.UPDATE_DELIVERY_PLAN_LIST_FAILURE:
            return {
                ...state,
                deliveryPlans: [],
            };
        case DeliveryServiceActionType.INIT_EXTERNAL_DELIVERY_FORM_DATA:
            return {
                ...state,
                deliveryServiceOptions: action.payload.options,
            };
        case DeliveryServiceActionType.CHANGE_TAB:
            return {
                ...state,
                tab: action.payload.tab,
            };
        default:
            return state;
    }
};
