import { UserMenuSlice } from './reducer';

export const userMenuProfileSelector = (state: UserMenuSlice) => {
    return state.userMenu.profile;
};

export const userMenuOrdersSelector = (state: UserMenuSlice) => {
    return state.userMenu.orders;
};

export const userMenuOrderSelector = (state: UserMenuSlice) => {
    return state.userMenu.order;
};

export const userMenuRoutingSelectedItemSelector = (state: UserMenuSlice) => {
    return state.userMenu.routing.selectedItem;
};

export const userMenuRoutingParamsSelector = (state: UserMenuSlice) => {
    return state.userMenu.routing.params;
};

export const userMenuIsShownSelector = (state: UserMenuSlice) => {
    return state.userMenu.showMenu;
};

export const userStoreInfoSelector = (state: UserMenuSlice) => {
    return state.userMenu.storeInfo;
};

export const userAddressesStoreSelector = (state: UserMenuSlice) => {
    return state.userMenu.addresses;
};

export const userStoreNotificationSettings = (state: UserMenuSlice) => {
    return state.userMenu.storeNotificationSettings;
};
