import { AppAction, AppActionType } from './actions';

export enum AppInitStatus {
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

export interface AppState {
    initStatus: AppInitStatus;
    isUIEnabled: boolean;
    errorSelling: string;
}

export interface AppSlice {
    app: AppState;
}

export const initialAppState: AppState = {
    initStatus: AppInitStatus.PENDING,
    isUIEnabled: true,
    errorSelling: null,
};

export const appReducer = (state = initialAppState, action: AppAction): AppState => {
    switch (action.type) {
        case AppActionType.UI_ENABLE:
            return {
                ...state,
                isUIEnabled: true,
            };
        case AppActionType.UI_DISABLE:
            return {
                ...state,
                isUIEnabled: false,
            };
        case AppActionType.INIT_SUCCESS:
            return {
                ...state,
                initStatus: AppInitStatus.SUCCESS,
            };
        case AppActionType.INIT_FAILURE:
            return {
                ...state,
                initStatus: AppInitStatus.FAILURE,
            };
        case AppActionType.INIT_SELLING_FAILURE:
            return {
                ...state,
                errorSelling: action.payload.errorText,
            };
        default:
            return state;
    }
};
