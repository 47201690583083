import React, { useState, Fragment, FC } from 'react';

import { useMounted } from 'core/hooks/useMounted';

declare const window: {
    __GOOGLE_MAPS_LOADED__: Promise<boolean>;
};

export const waitForGoogleMaps = async () => {
    await window.__GOOGLE_MAPS_LOADED__;
};

export const GoogleMapsLoadedObserver: FC = ({ children }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useMounted(() => {
        window.__GOOGLE_MAPS_LOADED__.then(() => setIsLoaded(true));
    });

    return <Fragment>{isLoaded ? children : null}</Fragment>;
};
